import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";
import Button from "../Button";
import "./PrintBatch.scss";
import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";
import { sendPrintBatchRequestAction } from "../../redux/actions/manager.dashboard";

const PrintBatch = ({
  isUpdateQuantityInProgress,
  toggleUpdateBatchModal,
  sendPrintBatchRequestAction,
  selectedBatchItem
}) => {
  const invokePrintAPI = () => {
    sendPrintBatchRequestAction({ batchId: selectedBatchItem.batchNo });
  };

  return (
    <div className="print-batch">
      <div className="print-batch__inner-section">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 p-0">
              <div className="d-flex align-items-center  justify-content-center gap-4 w-100 h-100">
                <Button
                  label="Confirm Print"
                  color="green"
                  height="80px"
                  onClick={invokePrintAPI}
                  disabled={isUpdateQuantityInProgress}
                  isLoading={isUpdateQuantityInProgress}
                />
                <Button
                  label="Cancel"
                  color="red"
                  height="80px"
                  onClick={() =>
                    toggleUpdateBatchModal({ open: false, batchItem: {} })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PrintBatch.defaultProps = {
  isUpdateQuantityInProgress: false,
  toggleUpdateBatchModal: noop,
  sendPrintBatchRequestAction: noop,
  selectedBatchItem: {}
};

PrintBatch.propTypes = {
  toggleUpdateBatchModal: PropTypes.func,
  isUpdateQuantityInProgress: PropTypes.bool,
  sendPrintBatchRequestAction: PropTypes.func,
  selectedBatchItem: PropTypes.object
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  toggleUpdateBatchModal: toggleUpdateBatchModal,
  sendPrintBatchRequestAction: sendPrintBatchRequestAction
};
export default connect(mapStateToProps, mapDispatchToProps)(PrintBatch);
