import { createSelector } from "reselect";

const managerForecastAccuracyState = (state) => state.managerForecatAccuracy;

/**
 * FORECAST ACCURACY DATA : GET
 */
export const managerForecastAccuracySelector = createSelector(
  managerForecastAccuracyState,
  (FA) => FA.managerForecastAccuracy
);

/**
 * IS FORECAST ACCURACY LOADING
 */
export const isManagerForecastAccuracyLoadingSelector = createSelector(
  managerForecastAccuracyState,
  (FA) => FA.loadingManagerForecastAccuracy
);
