import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import NumberBlocks from "../NumberBlocks";
import Button from "../Button";

import { toggleAddBatchModalAction } from "../../redux/actions/dahsboard";
import { getDetailsForAddBatchSelector } from "../../redux/selectors/dashboard";
import { addBatchAction } from "../../redux/actions/addBatch";
import { isAddingBatchSelector } from "../../redux/selectors/addBatch";

import { BUTTON_LABELS } from "../../configs/constants/labels";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./AddBatchModal.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "680px"
  }
};

const UpdateBatchModal = ({
  openAddBatchModal,
  toggleAddBatchModalAction,
  detailsForAddBatch,
  addBatchAction,
  isAddingBatch
}) => {
  const [quantity, setQantity] = useState("");
  const [batchSize] = useState(detailsForAddBatch.batchSize);
  const [numpadValue, setNumpadValue] = useState("0");
  const [showKeyboard, setShowKeyboard] = useState(false);

  const { CONFIRM_COOK, CANCEL } = BUTTON_LABELS;

  const addBatch = () => {
    addBatchAction({ batchId: detailsForAddBatch.itemId, quantity });
  };

  useEffect(() => {
    if (Number(numpadValue)) {
      setQantity(batchSize * Number(numpadValue));
    }
  }, [numpadValue]);

  const updateQuantityByKeyboard = (value) => {
    if (!isNaN(value)) {
      setQantity(quantity + value);
    } else {
      if (value === "{bksp}") {
        if (quantity) {
          const quantitySliced = quantity.toString().slice(0, -1);
          setQantity(Number(quantitySliced));
        }
      }
    }
  };

  return (
    <Modal
      isOpen={openAddBatchModal}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={() => toggleAddBatchModalAction({ open: false })}
    >
      <header className="add-batch-modal__header">
        <span>
          COOK ITEM - {detailsForAddBatch.description} [
          {detailsForAddBatch.itemId}]
        </span>
      </header>
      <main className="add-batch-modal__content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <header className="add-batch-modal__sub-header">
                <div>
                  <span>Batch size: {batchSize}</span>
                </div>
                <div>
                  <span>Select number of batches:</span>
                </div>
              </header>
              <section>
                <NumberBlocks
                  numpadValue={numpadValue}
                  setNumpadValue={setNumpadValue}
                />
              </section>
            </div>
            <div className="col-md-6">
              <header className="add-batch-modal__sub-header">
                <div>Total Pieces:</div>
                <div>&nbsp;</div>
              </header>
              <section className="py-2">
                <div>
                  <input
                    placeholder={0}
                    type="number"
                    className="add-batch-modal__input"
                    value={quantity}
                    onChange={(event) => setQantity(event.target.value)}
                    onFocus={() => setShowKeyboard(true)}
                    // onBlur={() => setShowKeyboard(false)}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-around">
                  <Button
                    label={CONFIRM_COOK}
                    color="green"
                    height="80px"
                    onClick={addBatch}
                    disabled={!quantity || isAddingBatch}
                    isLoading={isAddingBatch}
                  />
                  <Button
                    label={CANCEL}
                    color="red"
                    height="80px"
                    disabled={isAddingBatch}
                    onClick={() =>
                      toggleAddBatchModalAction({ open: false, batchItem: {} })
                    }
                  />
                </div>
              </section>
            </div>
          </div>
          {showKeyboard && (
            <div className="row mt-3">
              <div className="container">
                <Keyboard
                  layoutName={"default"}
                  value={quantity}
                  onKeyPress={(input) => updateQuantityByKeyboard(input)}
                />
              </div>
            </div>
          )}
        </div>
      </main>
    </Modal>
  );
};

UpdateBatchModal.defaultProps = {
  openAddBatchModal: false,
  toggleAddBatchModalAction: noop,
  detailsForAddBatch: {},
  addBatchAction: noop,
  isAddingBatch: false
};

UpdateBatchModal.propTypes = {
  openAddBatchModal: PropTypes.bool,
  toggleAddBatchModalAction: PropTypes.func,
  detailsForAddBatch: PropTypes.object,
  addBatchAction: PropTypes.func,
  isAddingBatch: PropTypes.bool
};

const mapStateToProps = (state) => ({
  detailsForAddBatch: getDetailsForAddBatchSelector(state),
  isAddingBatch: isAddingBatchSelector(state)
});

export default connect(mapStateToProps, {
  toggleAddBatchModalAction: toggleAddBatchModalAction,
  addBatchAction: addBatchAction
})(UpdateBatchModal);
