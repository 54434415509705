import { groupBy } from "lodash";
import { createSelector } from "reselect";

const managerForecastState = (state) => state.managerForecast;

export const isLoadingManagerForecastSelector = createSelector(
  managerForecastState,
  (MFS) => MFS.isLoadingManagerForecast
);

export const getManagerForecastSelector = createSelector(
  managerForecastState,
  (FS) => {
    const data = FS.managerForecastData;

    let newData = Object.values(groupBy(data, "dayPeriod"));

    newData = newData.map((data) => {
      const formedData = [];
      let temp = [];

      data.forEach((obj, index) => {
        const date = new Date(obj.createDate);
        if (date.getMinutes() === 0) {
          temp.push(obj);
          formedData.push(temp);
          temp = [];
        } else {
          temp.push(obj);
          if (index + 1 === data.length) {
            formedData.push(temp);
            temp = [];
          }
        }
      });
      return formedData;
    });
    return newData;
  }
);

export const getManagerForecastTableTotalRowSelector = createSelector(
  managerForecastState,
  (FS) => {
    const data = FS.managerForecastData;
    const forcastedSale = data.reduce((a, b) => {
      return a + b.forcastedSale;
    }, 0);

    const lastYearSale = data.reduce((a, b) => {
      return a + b.lastYearSale;
    }, 0);

    const managerEdit = data.reduce((a, b) => {
      return a + b.managerEdit;
    }, 0);

    const dayTotals = {
      ...data[0],
      lastYearSale,
      forcastedSale,
      managerEdit: Math.round(managerEdit),
      parentLabel: "Day Totals",
      dayPeriod: "Day Totals"
    };
    return dayTotals;
  }
);

export const getManagerForecastDateSelector = createSelector(
  managerForecastState,
  (FS) => FS.managerForecastDate
);

export const getManagerForecastSelectedStoreSelector = createSelector(
  managerForecastState,
  (FS) => FS.selectedStore
);
