import { createSelector } from "reselect";

const updateBatchState = (state) => state.updateBatch;

/**
 * LOADER
 * Loading state of API: Confirm complete loading time
 */
export const isConfirmCompleteInProgress = createSelector(
  updateBatchState,
  (UB) => UB.confirmingComplete
);
