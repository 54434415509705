import React from "react";
import Loader from "react-loader-spinner";

import "./PageLoader.scss";

const PageLoader = () => {
  return (
    <div id="spinner" className="show page-loader">
      <Loader
        type="BallTriangle"
        color="#F5F5F5"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      />
    </div>
  );
};

export default PageLoader;
