import React, { useEffect, useState } from "react";
import { BiMenu } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import PropTypes from "prop-types";

import DEVYANI_BRAND_LOGO from "../../assets/images/devyani_logo.svg";

import { getUserRole, parseJwt } from "../../utils/helpers";

import "./Navbar.scss";

const Navbar = ({ toggleSidebasAction }) => {
  const [storeCode, setStoreCode] = useState("");

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("user"));
    setStoreCode(user.StoreCode);
  }, []);

  const history = useHistory();
  return (
    <div className="navbar">
      <div className="navbar__left-section">
        <span
          className="navbar__brand pointer"
          onClick={() =>
            history.push(getUserRole() === "Store" ? "/manager-dashboard" : "/")
          }
        >
          <img src={DEVYANI_BRAND_LOGO} height="54px" />
          <span style={{ marginLeft: "16px", fontSize: "24px" }}>
            {storeCode.includes(",") ? null : storeCode}
          </span>
        </span>
      </div>
      <div className="navbar__center-section">
        <span>Digital MP&C</span>
      </div>
      <div className="navbar__right-section">
        {/* <span
          className="navbar__right-section__report-icon-container"
          onClick={() => history.push("/forecast-evaluator")}
        >
          <BiBarChart />
        </span> */}
        <span
          className="navbar__right-section__report-icon-container"
          onClick={() => toggleSidebasAction()}
        >
          <BiMenu />
        </span>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  toggleSidebasAction: noop
};

Navbar.propTypes = {
  toggleSidebasAction: PropTypes.func
};

export default Navbar;
