import React from "react";
import PropTypes from "prop-types";
import { BiLoaderCircle } from "react-icons/bi";
import { noop } from "lodash";

import "./Button.scss";

const Button = ({ label, color, height, onClick, disabled, isLoading }) => {
  return (
    <button
      disabled={disabled}
      className={`button btn button--${color}`}
      style={{ height }}
      onClick={onClick}
    >
      {isLoading ? <BiLoaderCircle /> : label}
    </button>
  );
};

Button.defaultProps = {
  label: "",
  color: "green",
  height: "40px",
  onClick: noop,
  disabled: false,
  isLoading: false
};

Button.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Button;
