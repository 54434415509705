import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";

import Button from "../Button";

import { updateQuantityAction } from "../../redux/actions/updateBatch";
import { isUpdateQuantityInProgress } from "../../redux/selectors/updateQuantity";
import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";

import "./CorrectQty.scss";

const CorrectQty = ({
  selectedBatchItem,
  updateQuantityAction,
  isUpdateQuantityInProgress,
  toggleUpdateBatchModal
}) => {
  const [quantity, setQantity] = useState("");

  const udpateQuantity = () => {
    updateQuantityAction({
      batchId: selectedBatchItem.batchNo,
      data: Number(quantity)
    });
  };

  return (
    <div className="correct-qty">
      <div className="correct-qty__inner-section">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div>
                <header>Enter Qty Cooked in Batch:</header>
                <input
                  placeholder={selectedBatchItem.batchQty}
                  type="number"
                  className="correct-qty__input"
                  value={quantity}
                  onChange={(event) => setQantity(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 p-0 correct-qty__right-section">
              <div className="correct-qty__right-section--first-child d-flex align-items-center h-100">
                <div className="d-flex justify-content-between w-100">
                  <Button
                    label="Confirm Correct"
                    color="green"
                    height="80px"
                    onClick={udpateQuantity}
                    disabled={isUpdateQuantityInProgress}
                    isLoading={isUpdateQuantityInProgress}
                  />
                  <Button
                    label="Cancel"
                    color="red"
                    height="80px"
                    onClick={() =>
                      toggleUpdateBatchModal({ open: false, batchItem: {} })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CorrectQty.defaultProps = {
  selectedBatchItem: {},
  updateQuantityAction: noop,
  isUpdateQuantityInProgress: false,
  batchItemtoggleUpdateBatchModal: noop
};

CorrectQty.propTypes = {
  selectedBatchItem: PropTypes.object,
  updateQuantityAction: PropTypes.func,
  isUpdateQuantityInProgress: PropTypes.bool,
  toggleUpdateBatchModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  isUpdateQuantityInProgress: isUpdateQuantityInProgress(state)
});

export default connect(mapStateToProps, {
  updateQuantityAction: updateQuantityAction,
  toggleUpdateBatchModal: toggleUpdateBatchModal
})(CorrectQty);
