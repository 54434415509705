import {
  GET_MRD_ITEMS_REQUEST,
  GET_MRD_ITEMS_FAILED,
  GET_MRD_ITEMS_SUCCESS
} from "../types/mrdItems";

const initialState = {
  mrdItems: [],
  loadingMrdItems: false
};

export const mrdItemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MRD_ITEMS_REQUEST:
      return {
        ...state,
        mrdItems: [],
        loadingMrdItems: true
      };
    case GET_MRD_ITEMS_SUCCESS:
      return {
        ...state,
        mrdItems: payload,
        loadingMrdItems: false
      };
    case GET_MRD_ITEMS_FAILED:
      return {
        ...state,
        mrdItems: [],
        loadingMrdItems: false
      };
    default:
      return state;
  }
};
