import React from "react";
import PropTypes from "prop-types";
import { GiRadioTower } from "react-icons/gi";

import "./Time.scss";
import { getTime } from "../../utils/helpers";

const Time = ({ salesTo, currentTime, isSignalRConnected }) => {
  const trendTime = new Date(salesTo);

  return (
    <div className="time">
      <span
        className="time__container"
        data-toggle="tooltip"
        data-placement="left"
        title="Signal R"
      >
        <GiRadioTower color={isSignalRConnected ? "green" : "red"} />
      </span>
      <span className="time__container">Latest txn time (Sales Sync): {getTime(trendTime)}</span>
      <span className="time__container">System time: {currentTime}</span>
    </div>
  );
};

Time.defaultProps = {
  salesTo: "00:00",
  currentTime: "00:00",
  isSignalRConnected: false
};

Time.propTypes = {
  salesTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  currentTime: PropTypes.string,
  isSignalRConnected: PropTypes.bool
};

export default Time;
