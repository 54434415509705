import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import { getMrdItemsAPI, printMrdItemApi } from "../../api/mrdItems";
import {
  GET_MRD_ITEMS_FAILED,
  GET_MRD_ITEMS_REQUEST,
  GET_MRD_ITEMS_SUCCESS,
  SEND_MRD_ITEM_PRINT_REQUEST
} from "../types/mrdItems";

function* watchMrdItemsSaga() {
  yield takeLatest(GET_MRD_ITEMS_REQUEST, function* mrdItemsSaga({ payload }) {
    try {
      const data = yield call(getMrdItemsAPI);
      if (data) {
        yield put({ type: GET_MRD_ITEMS_SUCCESS, payload: data });
      }
    } catch (e) {
      yield put({ type: GET_MRD_ITEMS_FAILED });
      toast.error("Something went wrong while fetching Mrd items");
    }
  });
  yield takeLatest(
    SEND_MRD_ITEM_PRINT_REQUEST,
    function* printMrdItem({ payload }) {
      try {
        yield call(printMrdItemApi, payload);
        toast.success("Print initiated successfully");
      } catch (e) {
        console.error(e);
        toast.error("Error while printing");
      }
    }
  );
}

export default function* mrdItemsSaga() {
  yield call(watchMrdItemsSaga);
}
