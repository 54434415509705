import { map } from "lodash";
import axiosInstance from "../configs/axios";

const GET_BATCH_API_ENDPOINT = "/api/Batch/GetActiveBatch";
const GET_WASTE_REASON_ENDPOINT = "/api/WasteReason/ListWasteReason";
const GET_ACTIVE_ITEMS_API_ENDPOINT = "/api/Item/GetActiveItems";
const GET_ITEM_DROP_QTY_API_ENDPOINT = "/api/Item/GetItemDropQty";
const GET_SALES_TREND_API_ENDPOINT = "/api/Sales/GetSalesTrend";
const GET_LAST_TX_TIME_API_ENDPOINT = "/api/Sales/LastTxTime";

export function ActiveItem(item) {
  this.itemId = item.itemId;
  this.description = item.description;
  this.imageName = item.imagePath;
  this.data = [];
  this.batchSize = Number(item.batchSize);
  this.toDrop = 0;
}

export const getActiveItemsApi = () => {
  return axiosInstance
    .get(GET_ACTIVE_ITEMS_API_ENDPOINT)
    .then(({ data }) => data.map((item) => new ActiveItem(item)));
};

export function Batch(batch) {
  this.batchNo = batch.batchID;
  this.itemId = batch.itemID;
  this.businessDay = batch.businessDay;
  this.batchQty = batch.batchQty;
  this.batchSize = Number(batch.batchSize);
  this.startTime = batch.startTime;
  this.cookedTime = batch.cookedTime;
  this.holdEndTime = batch.holdEndTime;
  this.description = batch.description;
  this.expiredTime = batch.expiryTime;
  this.completed = false;
  this.imageName = batch.imageName;
}

export const getBatchAPI = () => {
  return axiosInstance
    .get(GET_BATCH_API_ENDPOINT)
    .then(({ data }) => map(data, (batch) => new Batch(batch)));
};

function WasteReason(reason) {
  this.label = reason.message;
  this.value = reason.id;
}

export const getWasteReasonAPI = () => {
  return axiosInstance
    .get(GET_WASTE_REASON_ENDPOINT)
    .then(({ data }) => map(data, (item) => new WasteReason(item)));
};

function ItemDropQty(item) {
  this.itemQty = item.itemQty;
  this.itemId = item.itemId;
}

export const getItemDropQtyAPI = () => {
  return axiosInstance
    .get(GET_ITEM_DROP_QTY_API_ENDPOINT)
    .then(({ data }) => map(data, (item) => new ItemDropQty(item)));
};

export const getSalesTrendAPI = () => {
  return axiosInstance
    .get(GET_SALES_TREND_API_ENDPOINT)
    .then(({ data }) => data);
};


export const getInitialSalesDateAPI = () => {
  return axiosInstance
  .get(GET_LAST_TX_TIME_API_ENDPOINT)
  .then(({ data }) => data);
};
