import {
  GET_MANAGER_FORECAST_FAILURE,
  GET_MANAGER_FORECAST_REQUEST,
  GET_MANAGER_FORECAST_SUCCESS,
  UPDATE_MANAGER_FORECAST_DATE,
  UPDATE_MANAGER_FORECAST_SELECTED_STORE
} from "../types/manager.forecast";

const initialState = {
  isLoadingManagerForecast: false,
  managerForecastData: [],
  managerForecastDate: new Date(),
  selectedStore: null
};

export const managerForecastReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_MANAGER_FORECAST_REQUEST:
      return {
        ...state,
        isLoadingManagerForecast: true,
        managerForecastData: []
      };
    case GET_MANAGER_FORECAST_SUCCESS:
      return {
        ...state,
        isLoadingManagerForecast: false,
        managerForecastData: payload
      };
    case GET_MANAGER_FORECAST_FAILURE:
      return {
        ...state,
        isLoadingManagerForecast: false,
        managerForecastData: []
      };
    case UPDATE_MANAGER_FORECAST_DATE:
      return { ...state, managerForecastDate: payload };
    case UPDATE_MANAGER_FORECAST_SELECTED_STORE:
      return { ...state, selectedStore: payload };
    default:
      return state;
  }
};
