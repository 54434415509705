import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import TableRowInput from "../../components/TableRowInput";
import PercentTableRowInput from "../../components/PercentTableRowInput";
import PercentToggle from "../../components/PercentToggle/PercentToggle";

import {
  getSalesTableDataAction,
  updateCurrentSaleAction
} from "../../redux/actions/forecast";
import { getSalesTableDataSelector } from "../../redux/selectors/forecast";

const GraphTable = ({
  getSalesTable,
  salesTableData,
  updateCurrentSale,
  selectedDate
}) => {
  const [selectedMode, setSelectedMode] = useState("rs");

  useEffect(() => {
    getSalesTable();
  }, [selectedDate]);

  return (
    <div className="d-flex justify-content-center align-items-center mt-2">
      <table
        className="table table-striped table-bordered forecast-evaluator__table-container"
        style={{ fontSize: "14px" }}
      >
        <thead>
          <tr>
            <th style={{ width: "25%" }}>FORECAST FILTER</th>
            <th style={{ width: "25%" }}>LAST WEEK</th>
            <th style={{ width: "25%" }}>SYSTEM FORECAST</th>
            <th style={{ width: "25%" }}>
              <div className="d-flex justify-content-between">
                <div>MANAGER FORECAST</div>
                <PercentToggle
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {salesTableData.map((item) => (
            <tr key={item.salesType}>
              <td >{item.salesType}</td>
              <td style={{textAlign: "right"}}>Rs. {item.lastYearSale}</td>
              <td style={{textAlign: "right"}}>Rs. {item.currentForcast}</td>
              <td style={{textAlign: "right"}}>
                {selectedMode === "%" ? (
                  <PercentTableRowInput
                    defaultValue={item.managerAdjusted}
                    updateCurrentSale={updateCurrentSale}
                    createDate={item.createDate}
                    salesType={item.salesType}
                    disabled={selectedMode === "%" && !item.currentForcast}
                  />
                ) : (
                  <TableRowInput
                    defaultValue={`Rs. ${item.managerAdjusted}`}
                    updateCurrentSale={updateCurrentSale}
                    createDate={item.createDate}
                    salesType={item.salesType}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

GraphTable.defaultProps = {
  getSalesTable: noop,
  salesTableData: [],
  updateCurrentSale: noop,
  selectedDate: new Date()
};

GraphTable.propTypes = {
  getSalesTable: PropTypes.func,
  salesTableData: PropTypes.array,
  updateCurrentSale: PropTypes.func,
  selectedDate: PropTypes.object
};

const mapStateToProps = (state) => ({
  salesTableData: getSalesTableDataSelector(state)
});

export default connect(mapStateToProps, {
  getSalesTable: getSalesTableDataAction,
  updateCurrentSale: updateCurrentSaleAction
})(GraphTable);
