import {
  GET_ACTIVE_INACTIVE_STORES_REQUEST,
  GET_AVAILABLE_STORE_CODES_REQUEST,
  GET_STORES_CURRENT_SALES_REQUEST,
  GET_STORES_LAST_TX_REQUEST,
  GET_STORE_SALES_HISTORY_REQUEST,
  GET_TOTAL_STORE_SALES_REQUEST,
  SEND_PRINT_BATCH_REQUEST
} from "../types/manager.dashboard";

export const getActiveInactiveStoreAction = (payload) => ({
  type: GET_ACTIVE_INACTIVE_STORES_REQUEST,
  payload: payload
});

export const getStoreSalesHistoryAction = () => ({
  type: GET_STORE_SALES_HISTORY_REQUEST,
  payload: null
});

export const getStoreLastTxAction = () => ({
  type: GET_STORES_LAST_TX_REQUEST,
  payload: null
});

export const getStoreCurrentSalesAction = (payload) => ({
  type: GET_STORES_CURRENT_SALES_REQUEST,
  payload
});

export const sendPrintBatchRequestAction = (payload) => ({
  type: SEND_PRINT_BATCH_REQUEST,
  payload
});

export const getAvailableStoreCodesAction = () => ({
  type: GET_AVAILABLE_STORE_CODES_REQUEST
});

export const getTotalStoreSalesAction = (payload) => ({
  type: GET_TOTAL_STORE_SALES_REQUEST,
  payload: payload
});
