import React from "react";
import Navbar from "../../components/Navbar";
import SearchBox from "../../components/SearchBox";
import ReportsTable from "../../containers/ReportsTable";

import "./SummaryReport.scss";

const SummaryReport = () => {
  return (
    <div className="summary-report">
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SearchBox />
            <div className="summary-report__left-section-header mt-2 d-flex align-items-center">
              Totals
            </div>
            <div className="summary-report__left-section-items d-flex flex-column mt-2">
              <span>1 Piece HC</span>
              <span>1 Piece Chick Strips</span>
              <span>1 Piece Hot Wings</span>
              <span>Popcorn Reg</span>
              <span>Smoky Red 1PC</span>
              <span>Veg Zinger</span>
            </div>
          </div>
          <div className="col-md-10">
            <ReportsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryReport;
