import { combineReducers } from "redux";

import { dashboardReducer } from "./reducers/dashboard";
import { updateBatchReducer } from "./reducers/updateBatch";
import { addBatchReducer } from "./reducers/addBatch";
import { forecastReducer } from "./reducers/forecast";
import { storeSummaryReducer } from "./reducers/storeSummary";
import { forecastAccuracyReducer } from "./reducers/forecastAccuracy";
import { managerDashboardReducer } from "./reducers/manager.dashboard";
import { managerForecastReducer } from "./reducers/manager.forecast";
import { managerStoreSummaryReducer } from "./reducers/manager.storeSummary";
import { managerForecastAccuracyReducer } from "./reducers/manager.forecastAccuracy";
import { mrdItemsReducer } from "./reducers/mrdItems";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  updateBatch: updateBatchReducer,
  addBatch: addBatchReducer,
  forecast: forecastReducer,
  storeSummary: storeSummaryReducer,
  mrdItems: mrdItemsReducer,
  forecaseAccuracy: forecastAccuracyReducer,
  managerDashboard: managerDashboardReducer,
  managerForecast: managerForecastReducer,
  managerStoreSummary: managerStoreSummaryReducer,
  managerForecatAccuracy: managerForecastAccuracyReducer
});

export default rootReducer;
