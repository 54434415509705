import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_MANAGER_FORECAST_FAILURE,
  GET_MANAGER_FORECAST_REQUEST,
  GET_MANAGER_FORECAST_SUCCESS
} from "../types/manager.forecast";
import { getManagerForecastApi } from "../../api/manager.forecast";

function* watchManagerForecastSaga() {
  yield takeLatest(
    GET_MANAGER_FORECAST_REQUEST,
    function* getManagerForecast({ payload }) {
      try {
        const data = yield call(getManagerForecastApi, payload);
        if (data) {
          yield put({ type: GET_MANAGER_FORECAST_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_MANAGER_FORECAST_FAILURE });
        toast.error("Something went wrong while fetching graph data");
      }
    }
  );
}

export default function* managerForecastSaga() {
  yield call(watchManagerForecastSaga);
}
