import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import Button from "../Button";

import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";
import { updateExpiryTimeAction } from "../../redux/actions/updateBatch";
import { isUpdateExpiryTimeInProgress } from "../../redux/selectors/updateExpiryTime";

import { getNumberInTwoDigit, validateTime } from "../../utils/helpers";

import "./CorrectTime.scss";

const CorrectTime = ({
  selectedBatchItem,
  toggleUpdateBatchModal,
  updateExpiryTimeAction,
  isUpdateExpiryTimeInProgress
}) => {
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const expiryDate = new Date(selectedBatchItem.expiredTime);
    setHour(expiryDate.getHours());
    setMinute(expiryDate.getMinutes());
  }, []);

  const changeHour = (event) => {
    let { value } = event.target;
    if (value === "") {
      setHour("");
    } else {
      setHour(validateTime(value, 24));
    }
  };

  const changeMinute = (event) => {
    let { value } = event.target;
    if (value === "") {
      setMinute("");
    } else {
      setMinute(validateTime(value, 60));
    }
  };

  const saveExpiryTime = () => {
    const expiryTime = new Date();
    expiryTime.setHours(hour);
    expiryTime.setMinutes(minute);
    expiryTime.setSeconds(0);

    // Validation
    // Time Validation
    setErrorMessage("");
    if (
      expiryTime.getTime() < new Date(selectedBatchItem.cookedTime).getTime()
    ) {
      // check if this time less than start time
      setErrorMessage("Expiry time must be after Cooked Time.");
      return;
    } else if (
      // check if this time should not exceed cooked time
      expiryTime.getTime() > new Date(selectedBatchItem.expiredTime).getTime()
    ) {
      setErrorMessage("Expiry time must be on or before max expiry time.");
      return;
    }

    const year = expiryTime.getFullYear();
    const month = getNumberInTwoDigit(expiryTime.getMonth() + 1);
    const date = getNumberInTwoDigit(expiryTime.getDate());
    const hours = getNumberInTwoDigit(expiryTime.getHours());
    const minutes = getNumberInTwoDigit(expiryTime.getMinutes());
    const seconds = getNumberInTwoDigit(expiryTime.getSeconds());

    updateExpiryTimeAction({
      batchId: selectedBatchItem.batchNo,
      data: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    });
  };

  return (
    <div className="correct-time">
      <div className="correct-time__inner-section">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div>
                <header>Enter Expiry Time:</header>
                <div className="d-flex mt-3 align-items-center justify-content-center">
                  <input
                    type="number"
                    className="correct-time__input"
                    value={hour}
                    onChange={changeHour}
                    onFocus={() => setHour("")}
                  />{" "}
                  <span className="mx-2">:</span>{" "}
                  <input
                    type="number"
                    className="correct-time__input"
                    value={minute}
                    onChange={changeMinute}
                    onFocus={() => setMinute("")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 correct-time__right-section">
              <div className="correct-time__right-section--first-child d-flex align-items-center mt-5">
                <div className="d-flex justify-content-between w-100">
                  <Button
                    label="Confirm Time"
                    color="green"
                    height="80px"
                    onClick={saveExpiryTime}
                    disabled={isUpdateExpiryTimeInProgress}
                    isLoading={isUpdateExpiryTimeInProgress}
                  />
                  <Button
                    label="Cancel"
                    color="red"
                    height="80px"
                    disabled={isUpdateExpiryTimeInProgress}
                    onClick={() =>
                      toggleUpdateBatchModal({ open: false, batchItem: {} })
                    }
                  />
                </div>
              </div>
              <div className="correct-time__error-container">
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CorrectTime.defaultProps = {
  selectedBatchItem: {},
  toggleUpdateBatchModal: noop,
  updateExpiryTimeAction: noop,
  isUpdateExpiryTimeInProgress: false
};

CorrectTime.propTypes = {
  selectedBatchItem: PropTypes.object,
  toggleUpdateBatchModal: PropTypes.func,
  updateExpiryTimeAction: PropTypes.func,
  isUpdateExpiryTimeInProgress: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isUpdateExpiryTimeInProgress: isUpdateExpiryTimeInProgress(state)
});

export default connect(mapStateToProps, {
  toggleUpdateBatchModal: toggleUpdateBatchModal,
  updateExpiryTimeAction: updateExpiryTimeAction
})(CorrectTime);
