import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import ImagePlaceholder from "../../assets/images/image-placeholder.svg";

import "./BatchTileWithImage.scss";
import { API_BASE_URL } from "../../configs/constants/constants";

const BatchTileWithImage = ({
  toggleAddBatchModalAction,
  itemDetails,
  ImageUrl,
  holdCount,
  batchSize,
  toDrop
}) => {
  return (
    <div
      className="batch-tile-with-image d-flex flex-column flex-items-between"
      onClick={() =>
        toggleAddBatchModalAction({
          open: true,
          batchDetailsToAdd: { ...itemDetails, batchSize }
        })
      }
    >
      <section className="d-flex">
        <img
          src={ImageUrl ? `${API_BASE_URL}/${ImageUrl}` : ImagePlaceholder}
          className="batch-tile-with-image__img"
        />
        <span className="batch-tile-with-image__count-container">
          <span className="batch-tile-with-image__count-container--title mt-1">
            To Drop
          </span>
          <span>{toDrop ? toDrop : 0}</span>
          <span className="batch-tile-with-image__count-container--title mt-1">
            Holding (In Hand)
          </span>
          <span>{(holdCount === null || holdCount < 0) ? 0  :holdCount}</span>
        </span>
      </section>
    </div>
  );
};

BatchTileWithImage.defaultProps = {
  toggleAddBatchModalAction: noop,
  itemDetails: {},
  ImageUrl: "",
  holdCount: 0,
  batchSize: 0,
  toDrop: 0
};

BatchTileWithImage.propTypes = {
  toggleAddBatchModalAction: PropTypes.func,
  itemDetails: PropTypes.object,
  ImageUrl: PropTypes.string,
  holdCount: PropTypes.number,
  batchSize: PropTypes.number,
  toDrop: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default BatchTileWithImage;
