import { toast } from "react-toastify";
import { call, takeLatest, put, select } from "redux-saga/effects";
import { addBatchAPI } from "../../api/addBatch";
import { getActiveItemsSelector } from "../selectors/dashboard";
import {
  ADD_BATCH_FAILURE,
  ADD_BATCH_REQUEST,
  ADD_BATCH_SUCCESS
} from "../types/addBatch";
import {
  GET_ACTIVE_ITEMS_SUCCESS,
  OPEN_ADD_BATCH_MODAL
} from "../types/dashboard";

function* watchAddBatchSaga() {
  /**
   * ADD BATCH SAGA
   */
  yield takeLatest(ADD_BATCH_REQUEST, function* addBatchSaga({ payload }) {
    try {
      const data = yield call(addBatchAPI, payload);
      if (data) {
        yield put({ type: ADD_BATCH_SUCCESS });
        yield put({ type: OPEN_ADD_BATCH_MODAL, payload: { open: false } });

        // Update to drop data in redux
        const activeItems = yield select(getActiveItemsSelector);
        const indexOfActiveItem = activeItems.findIndex(
          (obj) => obj.itemId === payload.batchId
        );

        const latestQty =
          activeItems[indexOfActiveItem].toDrop - payload.quantity;

        activeItems[indexOfActiveItem].toDrop = latestQty > 0 ? latestQty : 0;
        yield put({ type: GET_ACTIVE_ITEMS_SUCCESS, payload: activeItems });
      }
    } catch (e) {
      yield put({ type: ADD_BATCH_FAILURE });
      toast.error("Somthing went wrong while adding batch");
    }
  });
}

export default function* addBatchSaga() {
  yield call(watchAddBatchSaga);
}
