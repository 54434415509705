import axiosInstance from "../configs/axios";

const LOGIN_API_ENDPOINT = "/api/Account/signin";
const SIGNUP_API_ENDPOINT = "/api/Account/signup";

export const loginUserApi = (payload) => {
  return axiosInstance
    .post(LOGIN_API_ENDPOINT, payload)
    .then(({ data }) => data);
};

export const signupUserApi = (payload) => {
  return axiosInstance
    .post(SIGNUP_API_ENDPOINT, payload)
    .then(({ data }) => data);
};
