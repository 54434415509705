import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";

import Item from "../Item";

import { updateCollapseMenuAction } from "../../redux/actions/dahsboard";
import { getActiveCollapseMenu } from "../../redux/selectors/dashboard";

import "./ItemsColumn.scss";

const ItemsColumn = ({
  index,
  batchArr,
  connection,
  activeMenu,
  updateCollapseMenuAction,
  activeItemsChunks
}) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(index === activeMenu);
  }, [activeMenu]);

  return (
    <div className="items-column d-flex">
      <div className={collapsed ? "pt-3" : "items-column--hide"}>
        {batchArr.map((batch, index) => (
          <Item
            key={index}
            batch={batch}
            connection={connection}
            activeItemsChunks={activeItemsChunks}
          />
        ))}
      </div>
      <div
        className="items-column__collapse-btn d-flex align-items-center"
        onClick={() => updateCollapseMenuAction(index)}
      >
        {collapsed ? (
          <FaArrowAltCircleRight color="white" />
        ) : (
          <FaArrowAltCircleLeft color="white" />
        )}
      </div>
    </div>
  );
};

ItemsColumn.defaultProps = {
  batchArr: [],
  connection: {},
  activeMenu: 0,
  updateCollapseMenuAction: noop,
  activeItemsChunks: []
};

ItemsColumn.propTypes = {
  batchArr: PropTypes.array,
  connection: PropTypes.object,
  activeMenu: PropTypes.number,
  index: PropTypes.number.isRequired,
  updateCollapseMenuAction: PropTypes.func,
  activeItemsChunks: PropTypes.array
};

const mapStateToProps = (state) => ({
  activeMenu: getActiveCollapseMenu(state)
});

export default connect(mapStateToProps, {
  updateCollapseMenuAction: updateCollapseMenuAction
})(ItemsColumn);
