import React from "react";

import "./Legends.scss";

const Legends = () => {
  return (
    <div className="legends">
      <span className="legends__legend-block">
        <span className="legends__indicator legends__indicator--blue" />
        Dropped
      </span>
      <span className="legends__legend-block">
        <span className="legends__indicator legends__indicator--orange" />{" "}
        In Hand
      </span>
      <span className="legends__legend-block">
        <span className="legends__indicator legends__indicator--red" /> Near
        Expiring Soon
      </span>
      <span className="legends__legend-block">
        <span className="legends__indicator legends__indicator--darkred" />{" "}
        Past Shelf-life
      </span>
      <span className="legends__legend-block">
        <span className="legends__indicator legends__indicator--gray" />{" "}
        Completed
      </span>
    </div>
  );
};

export default Legends;
