import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import { getStoreSummaryAPI } from "../../api/storeSummary";
import {
  GET_STORE_SUMMARY_FAILED,
  GET_STORE_SUMMARY_REQUEST,
  GET_STORE_SUMMARY_SUCCESS
} from "../types/storeSummary";

function* watchStoreSummarySaga() {
  /**
   * GET STORE SUMMARY
   */
  yield takeLatest(
    GET_STORE_SUMMARY_REQUEST,
    function* storeSummarySaga({ payload }) {
      try {
        const data = yield call(getStoreSummaryAPI, payload);
        if (data) {
          yield put({ type: GET_STORE_SUMMARY_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_STORE_SUMMARY_FAILED });
        toast.error("Somthing went wrong while adding batch");
      }
    }
  );
}

export default function* storeSummarySaga() {
  yield call(watchStoreSummarySaga);
}
