import {
  UPDATE_CONFIRMED_COOKED_TIME_REQUEST,
  UPDATE_CONFIRMED_COOKED_TIME_SUCCESS,
  UPDATE_EXPIRY_TIME_REQUEST,
  UPDATE_EXPIRY_TIME_SUCCESS,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_QUANTITY_SUCCESS,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  UPDATE_WASTE_QUANTITY_REQUEST,
  UPDATE_WASTE_QUANTITY_SUCCESS,
  CONFIRM_COMPLETE_REQUEST,
  CONFIRM_COMPLETE_SUCCESS,
  UPDATE_CONFIRMED_COOKED_TIME_FAILURE,
  UPDATE_EXPIRY_TIME_FAILURE,
  UPDATE_QUANTITY_FAILURE,
  UPDATE_WASTE_QUANTITY_FAILURE,
  DELETE_BATCH_FAILURE,
  CONFIRM_COMPLETE_FAILURE
} from "../types/updateBatch";

const initialState = {
  updatingCookedTime: false,
  updatingQuantity: false,
  updatingExpiryTime: false,
  deleteingBatch: false,
  updatingWasteQuantity: false,
  confirmingComplete: false
};

export const updateBatchReducer = (state = initialState, { type }) => {
  switch (type) {
    case UPDATE_CONFIRMED_COOKED_TIME_REQUEST:
      return {
        ...state,
        updatingCookedTime: true
      };
    case UPDATE_CONFIRMED_COOKED_TIME_SUCCESS:
      return {
        ...state,
        updatingCookedTime: false
      };
    case UPDATE_CONFIRMED_COOKED_TIME_FAILURE:
      return {
        ...state,
        updatingCookedTime: false
      };
    case UPDATE_QUANTITY_REQUEST:
      return {
        ...state,
        updatingQuantity: true
      };
    case UPDATE_QUANTITY_SUCCESS:
      return {
        ...state,
        updatingQuantity: false
      };
    case UPDATE_QUANTITY_FAILURE:
      return {
        ...state,
        updatingQuantity: false
      };
    case UPDATE_WASTE_QUANTITY_REQUEST:
      return {
        ...state,
        updatingWasteQuantity: true
      };
    case UPDATE_WASTE_QUANTITY_SUCCESS:
      return {
        ...state,
        updatingWasteQuantity: false
      };
    case UPDATE_WASTE_QUANTITY_FAILURE:
      return {
        ...state,
        updatingWasteQuantity: false
      };
    case UPDATE_EXPIRY_TIME_REQUEST:
      return {
        ...state,
        updatingExpiryTime: true
      };
    case UPDATE_EXPIRY_TIME_SUCCESS:
      return {
        ...state,
        updatingExpiryTime: false
      };
    case UPDATE_EXPIRY_TIME_FAILURE:
      return {
        ...state,
        updatingExpiryTime: false
      };
    case DELETE_BATCH_REQUEST:
      return {
        ...state,
        deleteingBatch: true
      };
    case DELETE_BATCH_SUCCESS:
      return {
        ...state,
        deleteingBatch: false
      };
    case DELETE_BATCH_FAILURE:
      return {
        ...state,
        deleteingBatch: false
      };
    case CONFIRM_COMPLETE_REQUEST:
      return {
        ...state,
        confirmingComplete: true
      };
    case CONFIRM_COMPLETE_SUCCESS:
      return {
        ...state,
        confirmingComplete: false
      };
    case CONFIRM_COMPLETE_FAILURE:
      return {
        ...state,
        confirmingComplete: false
      };
    default:
      return state;
  }
};
