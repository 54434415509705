import { createSelector } from "reselect";

const managerStoreSummaryState = (state) => state.managerStoreSummary;

/**
 * STORE SUMMARY DATA : GET
 */
export const managerStoreSummarySelector = createSelector(
    managerStoreSummaryState,
  (SS) => SS.managerStoreSummary
);

/**
 * IS STORE SUMMARY LOADING
 */
export const isManagerStoreSummaryLoadingSelector = createSelector(
    managerStoreSummaryState,
  (SS) => SS.loadingManagerStoreSummary
);
