import { createSelector } from "reselect";

const updateBatchState = (state) => state.updateBatch;
const dashboardState = (state) => state.dashboard;

/**
 * LOADER
 * Loading state of API: Update waste quantity
 */
export const isUpdateWasteQuantityInProgress = createSelector(
  updateBatchState,
  (UB) => UB.updatingWasteQuantity
);

export const getWasteReasonSelector = createSelector(
  dashboardState,
  (DS) => DS.wasteReasons
);
