import axiosInstance from "../configs/axios";

const ADD_BATCH_API_ENDPOINT = "/api/Batch/CreateBatch";

export const addBatchAPI = (payload) => {
  const { batchId, quantity } = payload;
  return axiosInstance
    .post(`${ADD_BATCH_API_ENDPOINT}`, {
      itemId: batchId.toString(),
      itemQty: Number(quantity)
    })
    .then(({ data }) => data);
};
