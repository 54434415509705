import {
  ADD_BATCH_FAILURE,
  ADD_BATCH_REQUEST,
  ADD_BATCH_SUCCESS
} from "../types/addBatch";

const initialState = {
  addingBatch: false
};

export const addBatchReducer = (state = initialState, { type }) => {
  switch (type) {
    case ADD_BATCH_REQUEST:
      return {
        ...state,
        addingBatch: true
      };
    case ADD_BATCH_SUCCESS:
      return {
        ...state,
        addingBatch: false
      };
    case ADD_BATCH_FAILURE:
      return {
        ...state,
        addingBatch: false
      };
    default:
      return state;
  }
};
