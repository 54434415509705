import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import { noop } from "lodash";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";
import { getStoreSummaryAction } from "../../redux/actions/storeSummary";
import {
  isStoreSummaryLoadingSelector,
  storeSummarySelector
} from "../../redux/selectors/stioreSummary";
import PageLoader from "../../components/PageLoader/PageLoader";

import "./StoreSummary.scss";

const StoreSummary = ({
  isSidebarOpen,
  toggleSidebasAction,
  getStoreSummary,
  isStoreSummaryLoading,
  storeSummary
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getStoreSummary(selectedDate);
  }, [selectedDate]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  return (
    <div>
      {isStoreSummaryLoading && <PageLoader />}
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      <div className="d-flex align-items-center mt-3 store-summary__date-picker">
        <span
          className="store-summary__date-picker-arrows"
          onClick={goToPrevDate}
        >
          <BsCaretLeftFill />
        </span>
        <DatePicker
          className="form-control forecast-header__date-picker"
          selected={selectedDate}
          onChange={(update) => {
            setSelectedDate(update);
          }}
          onChangeRaw={(e) => e.preventDefault()}
          maxDate={new Date().addDays(10)}
        />
        <span
          className="store-summary__date-picker-arrows"
          onClick={goToNextDate}
        >
          <BsCaretRightFill />
        </span>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <table
          className="table table-striped table-bordered store-summary__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead>
            <tr>
              <th>Item Id</th>
              <th>Item Name</th>
              <th>Production Unit</th>
              <th>Actual Forecast</th>
              <th>Manager Forecast</th>
              <th>Cooked </th>
              <th>Wasted(declared)</th>
              <th>% Wasted (declared)</th>
              <th>Sold</th>
              <th>Underproduced / Overproduced</th>
              <th>% Underproduced / Overproduced</th>
              <th>% Sold Fresh / Produced</th>
              <th>Sold Fresh</th>
              <th>% Fresh / Sold</th>
              <th>% Produced / Forecast</th>
            </tr>
          </thead>
          <tbody>
            {storeSummary.length ? (
              storeSummary.map((item, index) => (
                <tr key={index}>
                  <td>{item.itemId}</td>
                  <td>{item.itemName}</td>
                  <td>{item.productionUnit}</td>
                  <td style={{ textAlign: "right" }}>{item.forcast}</td>
                  <td style={{ textAlign: "right" }}>{item.managerForcast}</td>
                  <td style={{ textAlign: "right" }}>{item.cooked}</td>
                  <td style={{ textAlign: "right" }}>{item.wasted} </td>
                  <td style={{ textAlign: "right" }}>{item.perWasted} %</td>
                  <td style={{ textAlign: "right" }}>{item.sold}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.underOverProduce}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.perUnderOverProduce} %
                  </td>
                  <td style={{ textAlign: "right" }}>{item.perSold} %</td>
                  <td style={{ textAlign: "right" }}>{item.soldFresh}</td>
                  <td style={{ textAlign: "right" }}>{item.perSoldFresh} %</td>
                  <td style={{ textAlign: "right" }}>{item.perProduced} %</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={15} align="center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

StoreSummary.defaultProps = {
  toggleSidebasAction: noop,
  isSidebarOpen: false,
  getStoreSummary: noop,
  isStoreSummaryLoading: false,
  storeSummary: []
};

StoreSummary.propTypes = {
  toggleSidebasAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getStoreSummary: PropTypes.func,
  isStoreSummaryLoading: PropTypes.bool,
  storeSummary: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isStoreSummaryLoading: isStoreSummaryLoadingSelector(state),
  storeSummary: storeSummarySelector(state)
});

const mapDispatchToProps = {
  toggleSidebasAction: toggleSidebasAction,
  getStoreSummary: getStoreSummaryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreSummary);
