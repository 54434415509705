import { groupBy } from "lodash";
import { createSelector } from "reselect";

const forecastState = (state) => state.forecast;

export const getGraphDataSelector = createSelector(
  forecastState,
  (FS) => FS.graphData
);

// Old
export const getGraphDataForTableSelector = createSelector(
  forecastState,
  (FS) => {
    const data = FS.graphData;
    const keys = [];
    const newData = [];
    data.forEach((obj) => {
      if (!keys.includes(obj.dayPeriod)) {
        keys.push(obj.dayPeriod);
        const filterObjs = data.filter(
          (filterObj) => filterObj.dayPeriod === obj.dayPeriod
        );

        const forcastedSale = filterObjs.reduce((a, b) => {
          return a + b.forcastedSale;
        }, 0);

        const lastYearSale = filterObjs.reduce((a, b) => {
          return a + b.lastYearSale;
        }, 0);

        const managerEdit = filterObjs.reduce((a, b) => {
          return a + b.managerEdit;
        }, 0);

        const newObj = {
          ...obj,
          forcastedSale,
          lastYearSale,
          managerEdit,
          parentLabel: obj.dayPeriod,
          type: "parent"
        };

        newData.push(newObj);
        newData.push(obj);
      } else {
        newData.push(obj);
      }
    });

    // DAY TOTALS ROW CREATION [START]

    const forcastedSale = data.reduce((a, b) => {
      return a + b.forcastedSale;
    }, 0);

    const lastYearSale = data.reduce((a, b) => {
      return a + b.lastYearSale;
    }, 0);

    const managerEdit = data.reduce((a, b) => {
      return a + b.managerEdit;
    }, 0);

    const dayTotals = {
      ...newData[0],
      lastYearSale,
      forcastedSale,
      managerEdit,
      parentLabel: "Day Totals",
      dayPeriod: "Day Totals"
    };
    newData.unshift(dayTotals);
    // DAY TOTALS ROW CREATION [END]

    return newData;
  }
);

// New Data
export const getGraphDataForTableCollapseSelector = createSelector(
  forecastState,
  (FS) => {
    const data = FS.graphData;

    let newData = Object.values(groupBy(data, "dayPeriod"));

    newData = newData.map((data) => {
      const formedData = [];
      let temp = [];

      data.forEach((obj, index) => {
        const date = new Date(obj.createDate);
        if (date.getMinutes() === 0) {
          temp.push(obj);
          formedData.push(temp);
          temp = [];
        } else {
          temp.push(obj);
          if (index + 1 === data.length) {
            formedData.push(temp);
            temp = [];
          }
        }
      });
      return formedData;
    });
    return newData;
  }
);

export const getGraphTableViewTotalRowData = createSelector(
  forecastState,
  (FS) => {
    const data = FS.graphData;
    const forcastedSale = data.reduce((a, b) => {
      return a + b.forcastedSale;
    }, 0);

    const lastYearSale = data.reduce((a, b) => {
      return a + b.lastYearSale;
    }, 0);

    const managerEdit = data.reduce((a, b) => {
      return a + b.managerEdit;
    }, 0);

    const dayTotals = {
      ...data[0],
      lastYearSale,
      forcastedSale,
      managerEdit: Math.round(managerEdit),
      parentLabel: "Day Totals",
      dayPeriod: "Day Totals"
    };
    return dayTotals;
  }
);

export const getSalesTableDataSelector = createSelector(
  forecastState,
  (FS) => FS.tableData
);

export const getHistoricalDataSelector = createSelector(
  forecastState,
  (FS) => FS.historicalData
);

export const getIsHistoricalModalOpen = createSelector(
  forecastState,
  (FS) => FS.openHistoricalModal
);

export const isGraphDataLoadingSelector = createSelector(
  forecastState,
  (FS) => FS.isLoadingGraphData
);

export const isTableDataLoadingSelectr = createSelector(
  forecastState,
  (FS) => FS.isLoadingTableData
);

export const isHistoricalDataLoadingSelector = createSelector(
  forecastState,
  (FS) => FS.isLoadingHistoricalData
);

export const getForecastDateSelector = createSelector(
  forecastState,
  (FS) => FS.forecastDate
);
