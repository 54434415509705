import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import "./NumberBlocks.scss";

const NumberBlocks = ({ setNumpadValue }) => {
  return (
    <div className="number-blocks w-100">
      <div className="d-flex justify-content-between">
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(1)}
        >
          1
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(2)}
        >
          2
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(3)}
        >
          3
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(4)}
        >
          4
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(5)}
        >
          5
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(6)}
        >
          6
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(7)}
        >
          7
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(8)}
        >
          8
        </span>
      </div>
      <div className="d-flex justify-content-between">
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(9)}
        >
          9
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(10)}
        >
          10
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(11)}
        >
          11
        </span>
        <span
          className="number-blocks__block"
          onClick={() => setNumpadValue(12)}
        >
          12
        </span>
      </div>
    </div>
  );
};

NumberBlocks.defaultProps = {
  setNumpadValue: noop
};

NumberBlocks.propTypes = {
  setNumpadValue: PropTypes.func
};

export default NumberBlocks;
