import { call, takeLatest, put } from "redux-saga/effects";
import { toast } from "react-toastify";

import { loginUserApi, signupUserApi } from "../../api/login";
import {
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_REQUEST
} from "../types/auth";
import { parseJwt } from "../../utils/helpers";
import { USER_ROLE } from "../../configs/constants/constants";

const getUserType = (token) => {
  const { Role } = parseJwt(token);
  const roleObj = USER_ROLE.filter((obj) => obj.role === Role);
  return roleObj[0];
};

function* watchAuthSaga() {
  yield takeLatest(USER_LOGIN_REQUEST, function* login({ payload }) {
    try {
      const history = payload.history;
      const data = yield call(loginUserApi, {
        userName: payload.userName,
        password: payload.password
      });
      const tokenObj = data.value;
      localStorage.setItem("user", tokenObj);

      // check type of user to redirect
      const userType = getUserType(tokenObj);

      if (!userType) {
        toast.error(
          "Access Denied: Your role is not authorized to log in. Please contact the administrator if you believe this is in error."
        );
      } else {
        history.push(userType.initialPage);
      }
    } catch (e) {
      yield put({ type: USER_LOGIN_FAILURE });
      toast.error(
        e?.response?.data?.value || "Username or Password is incorrect."
      );
    }
  });

  yield takeLatest(USER_SIGNUP_REQUEST, function* signup({ payload }) {
    try {
      const history = payload.history;
      yield call(signupUserApi, {
        userName: payload.userName,
        password: payload.password
      });
      history.push("/login");
    } catch (e) {
      yield put({ type: USER_SIGNUP_FAILURE });
      toast.error(e?.response?.data?.value || "Something went wrong");
    }
  });
}

export default function* authSaga() {
  yield call(watchAuthSaga);
}
