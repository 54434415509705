import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import Sidebar from "react-sidebar";

import Navbar from "../../components/Navbar";
import Time from "../../components/Time";
import Legends from "../../components/Legends";
import AddBatchModal from "../../components/AddBatchModal";
import PageLoader from "../../components/PageLoader/PageLoader";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import ItemsHorizontalCollapse from "../../containers/ItemsHorizontalCollpase";
import UpdateBatchModal from "../../containers/UpdateBatchModal/UpdateBatchModal";
import { Batch, getInitialSalesDateAPI } from "../../api/dashboard";
import {
  getActiveItemsSelector,
  getOpenAddBatchModalSelector,
  getOpenUpdateBatchModal,
  getSignalRConnectionSelector,
  getTrendTimeSelector,
  isBatchLoadingSelector,
  isSidebarOpenSelector,
  isSignalRConnectionSelector
} from "../../redux/selectors/dashboard";
import {
  getActiveItemsAction,
  getWasteReasonAction,
  removeBatchFromStoreAction,
  saveTrendDataAction,
  saveTrendTimeAction,
  toggleSidebasAction,
  updateBatchAction,
  updateNewBatchDataAction,
  updateToDropFromSignalRAction,
  updateQtyInBatchAction,
  getSalesTrendAction
} from "../../redux/actions/dahsboard";

import {
  getAccessTokenFromLocalStorage,
  getLastRundedFifteenMinute,
  getTime,
  parseJwt
} from "../../utils/helpers";

import "./Dashboard.scss";
import { toast } from "react-toastify";
import { useSignalRCustomHook } from "../../hooks/signalR.hook";

const Dashboard = ({
  openAddBatchModal,
  openUpdateBatchModal,
  updateNewBatchDataAction,
  saveTrendDataAction,
  removeBatchFromStoreAction,
  getWasteReasonAction,
  updateBatchAction,
  trendTime,
  saveTrendTimeAction,
  isBatchLoading,
  getActiveItemsAction,
  updateToDropFromSignalRAction,
  toggleSidebasAction,
  isSidebarOpen,
  updateQtyInBatchAction,
  getSalesTrendAction,
  signalRConnection,
  isSignalRConnected
}) => {
  const [currentTime, setCurrentTime] = useState("00:00");

  const { initializeSignalR, stopSignalR } = useSignalRCustomHook();

  useEffect(() => {
    // SignalR initialization
    initializeSignalR(signalRConnection);

    const interval = setInterval(() => {
      setCurrentTime(getTime(new Date()));
    }, 1000);

    /**
     * GET BATCH DATA ACTION CALL
     */
    getActiveItemsAction();

    getWasteReasonAction();

    getSalesTrendAction();

    // SET INITIAL TIME FROM API CALL
    getInitialSalesDateAPI()
      .then((data) => saveTrendTimeAction(data.value))
      .catch((e) => {
        console.error(e);
      });

    return () => {
      clearInterval(interval);
      stopSignalR(signalRConnection);
    };
  }, []);

  useEffect(() => {
    if (signalRConnection?.connectionState === "Connected") {
      signalRConnection.on("NEW_BATCH", (data) => {
        updateNewBatchDataAction(new Batch(data));
      });

      signalRConnection.on("SALES_TREND", (data) => {
        saveTrendDataAction(data);
        const trendTime = getLastRundedFifteenMinute();
        saveTrendTimeAction(trendTime);
      });

      signalRConnection.on("DELETE_BATCH", (data) => {
        removeBatchFromStoreAction({
          itemId: data.itemID,
          batchNo: data.batchID
        });
      });

      signalRConnection.on("UPDATE_BATCH", (data) => {
        updateBatchAction(new Batch(data));
      });
      signalRConnection.on("UPDATE_SALES", () => {
        toast.success(`Sales update successfully.`);
      });

      signalRConnection.on("UPDATE_SALES", () => {
        toast.success(`Sales update successfully.`);
      });

      signalRConnection.on("Sales", (data) => {
        data.forEach((salesObj) => {
          updateQtyInBatchAction(salesObj);
          if (parseInt(salesObj.batchID) !== 0) {
            toast.success(
              `Item ${salesObj.itemId} with quantity ${salesObj.batchQty} has been sold at ${salesObj.datetime}`
            );
          }
          saveTrendTimeAction(new Date());
        });
      });

      signalRConnection.on("AUTH_TOKEN", (message) => {
        localStorage.setItem("user", message);
      });

      signalRConnection.on("TO_DROP", (data) => {
        updateToDropFromSignalRAction({
          itemId: data.itemID,
          batchQty: data.batchQty
        });
      });

      const userToken = getAccessTokenFromLocalStorage();
      const parsedToken = parseJwt(userToken);
      signalRConnection.invoke("AddTokenRequest", {
        StoreId: parsedToken.StoreCode,
        UserId: parsedToken.sid
      });
    }

    return () => {
      stopSignalR(signalRConnection);
    };
  }, [signalRConnection]);

  return (
    <div className="dashboard">
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      <main className="dashboard__main">
        <div className="dashboard__main__time-section">
          <Legends />
          <Time
            salesTo={trendTime}
            currentTime={currentTime}
            isSignalRConnected={isSignalRConnected}
          />
        </div>
        <ItemsHorizontalCollapse connection={signalRConnection} />
      </main>

      {/* Update Batch Modal */}
      {openUpdateBatchModal && (
        <UpdateBatchModal openUpdateBatchModal={openUpdateBatchModal} />
      )}
      {/* Add Batch Modal */}
      {openAddBatchModal && (
        <AddBatchModal openAddBatchModal={openAddBatchModal} />
      )}
      {isBatchLoading && <PageLoader />}
    </div>
  );
};

Dashboard.defaultProps = {
  openAddBatchModal: false,
  openUpdateBatchModal: false,
  updateNewBatchDataAction: noop,
  saveTrendDataAction: noop,
  removeBatchFromStoreAction: noop,
  getWasteReasonAction: noop,
  updateBatchAction: noop,
  trendTime: "",
  saveTrendTimeAction: noop,
  isBatchLoading: false,
  getActiveItemsAction: noop,
  updateToDropFromSignalRAction: noop,
  toggleSidebasAction: noop,
  isSidebarOpen: false,
  updateQtyInBatchAction: noop,
  getSalesTrendAction: noop,
  signalRConnection: {},
  isSignalRConnected: false
};

Dashboard.propTypes = {
  openAddBatchModal: PropTypes.bool,
  openUpdateBatchModal: PropTypes.bool,
  updateNewBatchDataAction: PropTypes.func,
  saveTrendDataAction: PropTypes.func,
  removeBatchFromStoreAction: PropTypes.func,
  getWasteReasonAction: PropTypes.func,
  updateBatchAction: PropTypes.func,
  trendTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  saveTrendTimeAction: PropTypes.func,
  isBatchLoading: PropTypes.bool,
  getActiveItemsAction: PropTypes.func,
  updateToDropFromSignalRAction: PropTypes.func,
  toggleSidebasAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  updateQtyInBatchAction: PropTypes.func,
  getSalesTrendAction: PropTypes.func,
  signalRConnection: PropTypes.object,
  isSignalRConnected: PropTypes.bool
};

const mapStateToProps = (state) => ({
  openUpdateBatchModal: getOpenUpdateBatchModal(state),
  openAddBatchModal: getOpenAddBatchModalSelector(state),
  trendTime: getTrendTimeSelector(state),
  isBatchLoading: isBatchLoadingSelector(state),
  activeItems: getActiveItemsSelector(state),
  isSidebarOpen: isSidebarOpenSelector(state),
  signalRConnection: getSignalRConnectionSelector(state),
  isSignalRConnected: isSignalRConnectionSelector(state)
});

const mapDispatchToProps = {
  updateNewBatchDataAction: updateNewBatchDataAction,
  saveTrendDataAction: saveTrendDataAction,
  removeBatchFromStoreAction: removeBatchFromStoreAction,
  getWasteReasonAction: getWasteReasonAction,
  updateBatchAction: updateBatchAction,
  saveTrendTimeAction: saveTrendTimeAction,
  getActiveItemsAction: getActiveItemsAction,
  updateToDropFromSignalRAction: updateToDropFromSignalRAction,
  toggleSidebasAction: toggleSidebasAction,
  updateQtyInBatchAction: updateQtyInBatchAction,
  getSalesTrendAction: getSalesTrendAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
