import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import Button from "../Button";

import { deleteBatchAction } from "../../redux/actions/updateBatch";
import { isDeleteBatchInProgress } from "../../redux/selectors/deleteBatch";
import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";

import "./DeleteBatch.scss";

const DeleteBatch = ({
  selectedBatchItem,
  toggleUpdateBatchModal,
  deleteBatchAction,
  isDeleteBatchInProgress
}) => {
  const [deleteReason, setDeleteReason] = useState("");

  const onChangeDeleteReason = (value) => {
    setDeleteReason(value.label);
  };

  const deleteBatch = () => {
    deleteBatchAction({
      batchId: selectedBatchItem.batchNo,
      deleteReason: deleteReason
    });
  };

  return (
    <div className="delete-batch">
      <div className="delete-batch__inner-section">
        <div className="delete-batch__warning">
          Delete batch: only use this if the batch was created in error,
          otherwise correct quantity or waste items.
        </div>
        <div className="d-flex justify-content-end mt-3">
          <div className="delete-batch__action-section">
            <Select onChange={onChangeDeleteReason} />
            <div className="delete-batch__actions d-flex justify-content-between mt-2">
              <Button
                label="Confirm Delete"
                color="green"
                height="80px"
                onClick={deleteBatch}
                disabled={isDeleteBatchInProgress}
                isLoading={isDeleteBatchInProgress}
              />
              <Button
                label="Cancel"
                color="red"
                height="80px"
                onClick={() =>
                  toggleUpdateBatchModal({ open: false, batchItem: {} })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteBatch.defaultProps = {
  selectedBatchItem: {},
  toggleUpdateBatchModal: noop,
  deleteBatchAction: noop,
  isDeleteBatchInProgress: false
};

DeleteBatch.propTypes = {
  selectedBatchItem: PropTypes.object,
  toggleUpdateBatchModal: PropTypes.func,
  deleteBatchAction: PropTypes.func,
  isDeleteBatchInProgress: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isDeleteBatchInProgress: isDeleteBatchInProgress(state)
});

export default connect(mapStateToProps, {
  deleteBatchAction: deleteBatchAction,
  toggleUpdateBatchModal: toggleUpdateBatchModal
})(DeleteBatch);
