import {
  GET_MANAGER_FORECAST_ACCURACY_FAILED,
  GET_MANAGER_FORECAST_ACCURACY_REQUEST,
  GET_MANAGER_FORECAST_ACCURACY_SUCCESS
} from "../types/manager.forecastAccuracy";

const initialState = {
  managerForecastAccuracy: [],
  loadingManagerForecastAccuracy: false
};

export const managerForecastAccuracyReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_MANAGER_FORECAST_ACCURACY_REQUEST:
      return {
        ...state,
        managerForecastAccuracy: [],
        loadingManagerForecastAccuracy: true
      };
    case GET_MANAGER_FORECAST_ACCURACY_SUCCESS:
      return {
        ...state,
        managerForecastAccuracy: payload,
        loadingManagerForecastAccuracy: false
      };
    case GET_MANAGER_FORECAST_ACCURACY_FAILED:
      return {
        ...state,
        managerForecastAccuracy: [],
        loadingManagerForecastAccuracy: false
      };
    default:
      return state;
  }
};
