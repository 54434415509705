import React from "react";
import PropTypes from "prop-types";

import "./TileCount.scss";

const TileCount = ({ count, size }) => {
  return (
    <span
      className={`tile-count ${size === "large" ? "tile-count--large" : ""}`}
    >
      {count}
    </span>
  );
};

TileCount.defaultProps = {
  count: 0,
  size: "medium"
};

TileCount.propTypes = {
  count: PropTypes.number,
  size: PropTypes.string
};

export default TileCount;
