import {
  CONFIRM_COMPLETE_REQUEST,
  DELETE_BATCH_REQUEST,
  UPDATE_CONFIRMED_COOKED_TIME_REQUEST,
  UPDATE_EXPIRY_TIME_REQUEST,
  UPDATE_QUANTITY_REQUEST,
  UPDATE_WASTE_QUANTITY_REQUEST
} from "../types/updateBatch";

export const updateConfirmTimeCookedAction = (payload) => {
  return {
    type: UPDATE_CONFIRMED_COOKED_TIME_REQUEST,
    payload
  };
};

export const updateExpiryTimeAction = (payload) => {
  return {
    type: UPDATE_EXPIRY_TIME_REQUEST,
    payload
  };
};

export const updateQuantityAction = (payload) => {
  return {
    type: UPDATE_QUANTITY_REQUEST,
    payload
  };
};

export const updateWasteQuantityAction = (payload) => {
  return {
    type: UPDATE_WASTE_QUANTITY_REQUEST,
    payload
  };
};

export const deleteBatchAction = (payload) => {
  return {
    type: DELETE_BATCH_REQUEST,
    payload
  };
};

export const confirmCompleteAction = (payload) => {
  return {
    type: CONFIRM_COMPLETE_REQUEST,
    payload
  };
};
