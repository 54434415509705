import {
  GET_STORE_SUMMARY_FAILED,
  GET_STORE_SUMMARY_REQUEST,
  GET_STORE_SUMMARY_SUCCESS
} from "../types/storeSummary";

const initialState = {
  storeSummary: [],
  loadingStoreSummary: false
};

export const storeSummaryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_STORE_SUMMARY_REQUEST:
      return {
        ...state,
        storeSummary: [],
        loadingStoreSummary: true
      };
    case GET_STORE_SUMMARY_SUCCESS:
      return {
        ...state,
        storeSummary: payload,
        loadingStoreSummary: false
      };
    case GET_STORE_SUMMARY_FAILED:
      return {
        ...state,
        storeSummary: [],
        loadingStoreSummary: false
      };
    default:
      return state;
  }
};
