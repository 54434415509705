import { USER_LOGIN_REQUEST, USER_SIGNUP_REQUEST } from "../types/auth";

export const loginAction = (payload) => ({
  type: USER_LOGIN_REQUEST,
  payload
});

export const signupAction = (payload) => ({
  type: USER_SIGNUP_REQUEST,
  payload
});
