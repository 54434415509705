import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isNaN, noop } from "lodash";

import { getNumberInTwoDigit } from "../../utils/helpers";

const TableRowInput = ({
  origin,
  defaultValue,
  updateCurrentSale,
  createDate,
  salesType,
  update15MinSale,
  type,
  parentLabel,
  updateDayPeriodSales,
  inputDisabled,
  updateHourlySales
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChangeHandler = (value) => {
    if (!isNaN(value) && Number(value) > 0) {
      setValue(value);
    } else {
      setValue("");
    }
  };

  const updateSale = () => {
    if (value && !value.includes("Rs.")) {
      const createDateObj = new Date(createDate);
      const currentTime = new Date();
      const year = createDateObj.getFullYear();
      const month = getNumberInTwoDigit(createDateObj.getMonth() + 1);
      const date = getNumberInTwoDigit(createDateObj.getDate());
      const hours = getNumberInTwoDigit(currentTime.getHours());
      const minutes = getNumberInTwoDigit(currentTime.getMinutes());
      const seconds = getNumberInTwoDigit(currentTime.getSeconds());

      if (origin === "graph") {
        const DV = defaultValue.split(" ")[1];
        const request = {
          createDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`,
          salesType,
          amount: value ? Number(value) : 0,
          previousAmount: DV ? Number(DV) : 0
        };
        updateCurrentSale(request);
      } else {
        if (type === "parent") {
          const DV = defaultValue.split(" ")[1];
          const request = {
            changedAmount: value ? Number(value) : 0,
            dayPeriod: parentLabel === "Day Totals" ? "Total" : parentLabel,
            previousAmount: DV ? Number(DV) : 0
          };

          updateDayPeriodSales(request);
        } else if (type === "head") {
          const DV = defaultValue.split(" ")[1];
          const request = {
            changedAmount: value ? Number(value) : 0,
            previousAmount: DV ? Number(DV) : 0,
            time: createDate //`${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
          };
          updateHourlySales(request);
        } else {
          const request = {
            salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`,
            amount: value ? Number(value) : 0
          };
          update15MinSale(request);
        }
      }
    } else {
      setValue(defaultValue);
    }
  };

  return (
    <input
      disabled={inputDisabled}
      onBlur={() => updateSale()}
      value={value}
      onChange={(event) => onChangeHandler(event.target.value)}
      onFocus={() => setValue("")}
    />
  );
};

TableRowInput.defaultProps = {
  defaultValue: "",
  updateCurrentSale: noop,
  createDate: "",
  salesType: "",
  origin: "graph",
  update15MinSale: noop,
  type: "",
  parentLabel: "",
  updateDayPeriodSales: noop,
  inputDisabled: false,
  updateHourlySales: noop
};

TableRowInput.propTypes = {
  defaultValue: PropTypes.string,
  updateCurrentSale: PropTypes.func,
  createDate: PropTypes.string,
  salesType: PropTypes.string,
  origin: PropTypes.string,
  update15MinSale: PropTypes.func,
  type: PropTypes.string,
  parentLabel: PropTypes.string,
  updateDayPeriodSales: PropTypes.func,
  inputDisabled: PropTypes.bool,
  updateHourlySales: PropTypes.func
};

export default TableRowInput;
