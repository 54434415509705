import { createSelector } from "reselect";

const storeMrdItems = (state) => state.mrdItems;

export const mrdItemsSelector = createSelector(
  storeMrdItems,
  (mrd) => mrd.mrdItems
);

export const isMrdItemsLoadingSelector = createSelector(
  storeMrdItems,
  (mrd) => mrd.loadingMrdItems
);
