import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStoreLastTxAction } from "../../redux/actions/manager.dashboard";
import {
  getStoreLastTxSelector,
  isFetchingStoresLastTxSelector
} from "../../redux/selectors/dashboard.manager";
import { noop } from "lodash";
import PropTypes from "prop-types";
import useTablePagination from "../../customHooks/TablePagination";
import { Button } from "react-bootstrap";
import RecentStoresTxTableLoader from "../../components/SkeletonLoader/RecentSroreTxTableLoader";
import { isSubstringIgnoreCase } from "../../utils/helpers";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const cellStyle = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const TableForManagerDashboard = ({
  getStoreLastTx,
  isFetchingStoresLastTx,
  storeLastTxData
}) => {
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState(storeLastTxData);

  useEffect(() => {
    getStoreLastTx();
  }, []);

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(pageData, 10);

  const tableData = getCurrentPageData();
  useEffect(() => {
    let filteredData = storeLastTxData.filter((item) => {
      let searchIn = "";
      if (item.storeCode) {
        searchIn += item.storeCode;
      }
      if (item.address) {
        searchIn += item.address;
      }
      return isSubstringIgnoreCase(searchIn, search);
    });
    setPageData(filteredData);
  }, [search, storeLastTxData]);
  return (
    <div className="shadow p-4 mb-5">
      <div className="d-flex justify-content-between mb-3">
        <div style={{ width: "25%" }}>
          <input
            className="form-control"
            type="text"
            placeholder="Search by store code or name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <h4 className="text-center">Store Recent Transactions</h4>
        <div style={{ width: "25%" }} />
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={headerStyle}>Store Code</th>
            <th style={headerStyle}>Name</th>
            <th style={headerStyle}>Tx Time</th>
          </tr>
        </thead>
        {isFetchingStoresLastTx ? (
          <RecentStoresTxTableLoader />
        ) : (
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td style={cellStyle}>{item.storeCode}</td>
                <td style={cellStyle}>{item.address}</td>
                <td style={cellStyle}>{item.time}</td>
              </tr>
            ))}
          </tbody>
        )}
        {!isFetchingStoresLastTx && tableData.length === 0 ? (
          <tbody>
            <tr>
              <td style={cellStyle} colSpan={3} className="text-center p-3">
                No recent transactions found
              </td>
            </tr>
          </tbody>
        ) : null}
      </table>
      {tableData && tableData.length ? (
        <div className="mt-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
    </div>
  );
};

TableForManagerDashboard.defaultProps = {
  isFetchingStoresLastTx: false,
  storeLastTxData: [],
  getStoreLastTx: noop
};

TableForManagerDashboard.propTypes = {
  isFetchingStoresLastTx: PropTypes.bool,
  storeLastTxData: PropTypes.array,
  getStoreLastTx: PropTypes.func
};

const mapStateToProps = (state) => ({
  isFetchingStoresLastTx: isFetchingStoresLastTxSelector(state),
  storeLastTxData: getStoreLastTxSelector(state)
});

const mapDispatchToProps = {
  getStoreLastTx: getStoreLastTxAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableForManagerDashboard);
