export const UPDATE_CONFIRMED_COOKED_TIME = "UPDATE_CONFIRMED_COOKED_TIME";
export const UPDATE_CONFIRMED_COOKED_TIME_REQUEST =
  "UPDATE_CONFIRMED_COOKED_TIME_REQUEST";
export const UPDATE_CONFIRMED_COOKED_TIME_SUCCESS =
  "UPDATE_CONFIRMED_COOKED_TIME_SUCCESS";
export const UPDATE_CONFIRMED_COOKED_TIME_FAILURE =
  "UPDATE_CONFIRMED_COOKED_TIME_FAILURE";

export const UPDATE_EXPIRY_TIME = "UPDATE_EXPIRY_TIME";
export const UPDATE_EXPIRY_TIME_REQUEST = "UPDATE_EXPIRY_TIME_REQUEST";
export const UPDATE_EXPIRY_TIME_SUCCESS = "UPDATE_EXPIRY_TIME_SUCCESS";
export const UPDATE_EXPIRY_TIME_FAILURE = "UPDATE_EXPIRY_TIME_FAILURE";

export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_QUANTITY_REQUEST = "UPDATE_QUANTITY_REQUEST";
export const UPDATE_QUANTITY_SUCCESS = "UPDATE_QUANTITY_SUCCESS";
export const UPDATE_QUANTITY_FAILURE = "UPDATE_QUANTITY_FAILURE";

export const UDPATE_WASTE_QUANTITY = "UDPATE_WASTE_QUANTITY";
export const UPDATE_WASTE_QUANTITY_REQUEST = "UPDATE_WASTE_QUANTITY_REQUEST";
export const UPDATE_WASTE_QUANTITY_SUCCESS = "UPDATE_WASTE_QUANTITY_SUCCESS";
export const UPDATE_WASTE_QUANTITY_FAILURE = "UPDATE_WASTE_QUANTITY_FAILURE";

export const DELETE_BATCH = "DELETE_BATCH";
export const DELETE_BATCH_REQUEST = "DELETE_BATCH_REQUEST";
export const DELETE_BATCH_SUCCESS = "DELETE_BATCH_SUCCESS";
export const DELETE_BATCH_FAILURE = "DELETE_BATCH_FAILURE";

export const CONFIRM_COMPLETE = "CONFIRM_COMPLETE";
export const CONFIRM_COMPLETE_REQUEST = "CONFIRM_COMPLETE_REQUEST";
export const CONFIRM_COMPLETE_SUCCESS = "CONFIRM_COMPLETE_SUCCESS";
export const CONFIRM_COMPLETE_FAILURE = "CONFIRM_COMPLETE_FAILURE";
