import axios from "axios";
import axiosInstance from "../configs/axios";
import { getNumberInTwoDigit, getTime } from "../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const GET_ACTIVE_INACTIVE_STORES_ENDPOINT = "/api/manager/ActiveInActiveStore";
const GET_STORE_HISTORY_ENDPOINT = "/api/manager/StoreSalesHistory";
const GET_STORES_LAST_TX_ENDPOINT = "/api/manager/StoresLastTx";
const GET_STORES_CURRENT_SALES_ENDPOINT = "/api/manager/StoresCurrentSales";
const PRINT_API_BASE_URL = "https://mpnc.local:5001";
const PRINT_BATCH_ITEM_ENDPOINT = "/api/print/print?batchId=";
const GET_AVAILABLE_STORE_CODES = "/api/admin/auth/store";
const GET_TOTAL_STORE_SALE_ENDPOINT = "/api/admin/sales/StoreSales";

function ActiveInactiveStore(storeSummary) {
  this.activeStoresCount = storeSummary.active;
  this.inactiveStoresCount = storeSummary.inActive;
  this.totalStore = storeSummary.totalStore;
  this.activeStores = storeSummary.activeStores;
  this.inactiveStores = storeSummary.inActiveStore;
}

export const getActiveInactiveStoresApi = (payload) => {
  const date = payload ? payload.date : new Date();
  const year = date.getFullYear();
  const month = getNumberInTwoDigit(date.getMonth() + 1);
  const d = getNumberInTwoDigit(date.getDate());
  const hours = getNumberInTwoDigit(date.getHours());
  const minutes = getNumberInTwoDigit(date.getMinutes());
  const seconds = getNumberInTwoDigit(date.getSeconds());
  return axiosInstance
    .get(
      GET_ACTIVE_INACTIVE_STORES_ENDPOINT +
      `?currentDate=${year}-${month}-${d} ${hours}:${minutes}:${seconds}`
    )
    .then(({ data }) => new ActiveInactiveStore(data));
};

function StoreSalesHistory(history) {
  this.storeCode = history.storeCode;
  this.actualSales = history.actualSales;
  this.managerSales = history.managerSales;
}

export const getStoreSalesHistoryApi = () => {
  return axiosInstance
    .get(GET_STORE_HISTORY_ENDPOINT)
    .then(({ data }) => data.map((obj) => new StoreSalesHistory(obj)));
};

function storeLastTx(txObj) {
  this.id = uuidv4();
  this.storeCode = txObj.storeCode;
  this.time = getTime(txObj.time);
  this.address = txObj.address;
}

export const getStoreLastTxApi = () => {
  return axiosInstance
    .get(GET_STORES_LAST_TX_ENDPOINT)
    .then(({ data }) => data.map((obj) => new storeLastTx(obj)));
};

function storeCurrentSale(saleObj) {
  this.storeCode = saleObj.storeCode;
  this.actualSales = saleObj.actualSales;
  this.forecastSales = saleObj.forcastSales;
  this.managerSales = saleObj.managerSales;
  this.managerAccuracy = saleObj.managerAccuracy;
  this.systemAccuracy = saleObj.systemAccuracy;
  this.systemVariance = saleObj.systemVariance;
  this.managerVariance = saleObj.managerVariance;
}

export const getStoresCurrentSalesApi = (payload) => {
  if (payload && payload.date) {
    const { date } = payload;
    const year = date.getFullYear();
    const month = getNumberInTwoDigit(date.getMonth() + 1);
    const d = getNumberInTwoDigit(date.getDate());
    const hours = getNumberInTwoDigit(date.getHours());
    const minutes = getNumberInTwoDigit(date.getMinutes());
    const seconds = getNumberInTwoDigit(date.getSeconds());

    return axiosInstance
      .get(
        GET_STORES_CURRENT_SALES_ENDPOINT +
        `?currentDate=${year}-${month}-${d}T${hours}:${minutes}:${seconds}Z`
      )
      .then(({ data }) => data.map((obj) => new storeCurrentSale(obj)));
  }

  return axiosInstance
    .get(GET_STORES_CURRENT_SALES_ENDPOINT)
    .then(({ data }) => data.map((obj) => new storeCurrentSale(obj)));
};

export const getTotalStoreSalesApi = (payload) => {
  const date = payload ? payload.date : new Date();
  const year = date.getFullYear();
  const month = getNumberInTwoDigit(date.getMonth() + 1);
  const d = getNumberInTwoDigit(date.getDate());
  const hours = getNumberInTwoDigit(date.getHours());
  const minutes = getNumberInTwoDigit(date.getMinutes());
  const seconds = getNumberInTwoDigit(date.getSeconds());

  return axiosInstance
    .get(
      GET_TOTAL_STORE_SALE_ENDPOINT +
      `?currenDate=${year}-${month}-${d} ${hours}:${minutes}:${seconds}`
    )
    .then(({ data }) => data);
};

export const printBatchItemApi = (payload) => {
  const { batchId, autoPrint } = payload;
  return axios.get(
    `${PRINT_API_BASE_URL}${PRINT_BATCH_ITEM_ENDPOINT}${batchId}&autoPrint=${!!autoPrint}`
  );
};

export const getAvailableStoreCodesApi = () => {
  return axiosInstance.get(GET_AVAILABLE_STORE_CODES).then(({ data }) => data);
};
