import React from "react";
import PropTypes from "prop-types";

import { flatten, noop } from "lodash";

import CollapsableRows from "../CollapsableRows/CollapsableRows";
import PercentTableRowInput from "../PercentTableRowInput";
import TableRowInput from "../TableRowInput";

const CollapsableRowParent = ({
  data,
  selectedMode,
  updateCurrentSale,
  updateDayPeriodSales,
  inputDisabled,
  updateHourlySales
}) => {
  const flattenData = flatten(data);

  return (
    <>
      <tr
        style={{ paddingLeft: "16px", color: "#FFFFFF"  }}
        className={`graph-table-view__${flattenData[0].dayPeriod}`}
      >
        <td>{flattenData[0].dayPeriod}</td>
        <td style={{textAlign:"right"}}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.lastYearSale;
          }, 0)}
        </td>
        <td style={{textAlign:"right"}}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.forcastedSale;
          }, 0)}
        </td>
        <td style={{textAlign:"right"}}>
          {selectedMode === "%" ? (
            <PercentTableRowInput
              defaultValue={flattenData.reduce((a, b) => {
                return a + b.managerEdit;
              }, 0)}
              update15MinSale={updateCurrentSale}
              createDate={flattenData[0].createDate}
              salesType={flattenData[0].salesType}
              disabled={
                (selectedMode === "%" &&
                  !flattenData.reduce((a, b) => {
                    return a + b.managerEdit;
                  }, 0)) ||
                inputDisabled
              }
              origin="table"
              parentLabel={flattenData[0].dayPeriod}
              type={"parent"}
              updateDayPeriodSales={updateDayPeriodSales}
            />
          ) : (
            <TableRowInput
              defaultValue={`Rs. ${flattenData.reduce((a, b) => {
                return a + b.managerEdit;
              }, 0)}`}
              update15MinSale={updateCurrentSale}
              createDate={flattenData[0].createDate}
              salesType={flattenData[0].salesType}
              origin="table"
              parentLabel={flattenData[0].dayPeriod}
              type={"parent"}
              updateDayPeriodSales={updateDayPeriodSales}
              inputDisabled={inputDisabled}
            />
          )}
        </td>
      </tr>
      {data.map((obj, index) => (
        <CollapsableRows
          key={index}
          data={obj}
          selectedMode={selectedMode}
          updateCurrentSale={updateCurrentSale}
          updateDayPeriodSales={updateDayPeriodSales}
          updateHourlySales={updateHourlySales}
          inputDisabled={inputDisabled}
        />
      ))}
    </>
  );
};

CollapsableRowParent.defaultProps = {
  data: [],
  selectedMode: "rs",
  updateCurrentSale: noop,
  updateDayPeriodSales: noop,
  inputDisabled: false,
  updateHourlySales: noop
};

CollapsableRowParent.propTypes = {
  data: PropTypes.array,
  selectedMode: PropTypes.string,
  updateCurrentSale: PropTypes.func,
  updateDayPeriodSales: PropTypes.func,
  inputDisabled: PropTypes.bool,
  updateHourlySales: PropTypes.func
};

export default CollapsableRowParent;
