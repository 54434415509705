import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import { getForecastAccuracyAPI } from "../../api/forecastAccuracy";
import {
  GET_FORECAST_ACCURACY_FAILED,
  GET_FORECAST_ACCURACY_REQUEST,
  GET_FORECAST_ACCURACY_SUCCESS
} from "../types/forecastAccuracy";

function* watchForecastAccuracySaga() {
  /**
   * GET FORECAST ACCURACY
   */
  yield takeLatest(
    GET_FORECAST_ACCURACY_REQUEST,
    function* forecastAccuracySaga({ payload }) {
      try {
        const data = yield call(getForecastAccuracyAPI, payload);
        if (data) {
          yield put({ type: GET_FORECAST_ACCURACY_SUCCESS, payload: data });
        }
      } catch (e) {
        yield put({ type: GET_FORECAST_ACCURACY_FAILED });
        toast.error("Somthing went wrong while adding batch");
      }
    }
  );
}

export default function* forecastAccuracySaga() {
  yield call(watchForecastAccuracySaga);
}
