import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import { getManagerForecastAccuracyAPI } from "../../api/manager.forecastAccuracy";
import {
  GET_MANAGER_FORECAST_ACCURACY_FAILED,
  GET_MANAGER_FORECAST_ACCURACY_REQUEST,
  GET_MANAGER_FORECAST_ACCURACY_SUCCESS
} from "../types/manager.forecastAccuracy";

function* watchManagerForecastAccuracySaga() {
  /**
   * GET FORECAST ACCURACY
   */
  yield takeLatest(
    GET_MANAGER_FORECAST_ACCURACY_REQUEST,
    function* mForecastAccuracySaga({ payload }) {
      try {
        const data = yield call(getManagerForecastAccuracyAPI, payload);
        if (data) {
          yield put({
            type: GET_MANAGER_FORECAST_ACCURACY_SUCCESS,
            payload: data
          });
        }
      } catch (e) {
        console.error(e);
        yield put({ type: GET_MANAGER_FORECAST_ACCURACY_FAILED });
        toast.error(
          "Something went wrong while fetching manager forecast accuracy"
        );
      }
    }
  );
}

export default function* managerForecastAccuracySaga() {
  yield call(watchManagerForecastAccuracySaga);
}
