import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

import ConfirmedCooked from "../../components/ConfirmedCooked";
import WasteItem from "../../components/WasteItem";
import CorrectQty from "../../components/CorrectQty";
import CorrectTime from "../../components/CorrectTime";
import ConfirmComplete from "../../components/ConfirmComplete";
import DeleteBatch from "../../components/DeleteBatch/DeleteBatch";

import "./UpdateBatchModal.scss";
import { connect } from "react-redux";
import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";
import { noop } from "lodash";
import {
  getSelectedBatchItemColorSelector,
  getSelectedBatchItemSelector
} from "../../redux/selectors/dashboard";
import PrintBatch from "../../components/PrintBatch";
import { parseJwt } from "../../utils/helpers";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    width: "680px"
  }
};

const UpdateBatchModal = ({
  openUpdateBatchModal,
  toggleUpdateBatchModal,
  selectedBatchItem,
  batchItemColor
}) => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [isPrintAllowed, setIsPrintAllowed] = useState(false);
  const { batchNo, itemId, description } = selectedBatchItem;

  useEffect(() => {
    if (batchItemColor !== "blue") {
      setSelectedMenu(1);
    }
    const user = parseJwt(localStorage.getItem("user"));
    if (user?.PrintAllow?.toLowerCase() === "true") {
      setIsPrintAllowed(true);
    }
  }, []);

  const renderMenu = () => {
    switch (selectedMenu) {
      case 0:
        return <ConfirmedCooked selectedBatchItem={selectedBatchItem} />;
      case 1:
        return <WasteItem selectedBatchItem={selectedBatchItem} />;
      case 2:
        return <CorrectQty selectedBatchItem={selectedBatchItem} />;
      case 3:
        return <CorrectTime selectedBatchItem={selectedBatchItem} />;
      case 4:
        return <ConfirmComplete selectedBatchItem={selectedBatchItem} />;
      case 5:
        return <DeleteBatch selectedBatchItem={selectedBatchItem} />;
      case 6:
        return <PrintBatch selectedBatchItem={selectedBatchItem} />;
      default:
        return <ConfirmedCooked selectedBatchItem={selectedBatchItem} />;
    }
  };

  const isConfirmedCookedDisabled = () => {
    if (batchItemColor !== "blue") {
      return true;
    }
    return false;
  };

  const isCorrectTimeDisabled = () => {
    if (batchItemColor === "darkred" || batchItemColor === "blue") {
      return true;
    }
    return false;
  };

  return (
    <Modal
      isOpen={openUpdateBatchModal}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={() =>
        toggleUpdateBatchModal({ open: false, batchItem: {} })
      }
    >
      <header className="update-batch-modal__header">
        <span>
          UPDATE BATCH - {batchNo} - {itemId} - {description}
        </span>
      </header>
      <main className="container-fluid update-batch-modal__section">
        <div className="row">
          <div className="col-md-4">
            <div className="update-batch-modal__menu">
              <span
                onClick={() =>
                  isConfirmedCookedDisabled() ? null : setSelectedMenu(0)
                }
                className={`${
                  selectedMenu === 0 ? "update-batch-modal__selected-menu" : ""
                } ${
                  isConfirmedCookedDisabled()
                    ? "update-batch-modal__disabled"
                    : ""
                }`}
              >
                Confirmed Cooked
              </span>
              <span
                onClick={() => setSelectedMenu(1)}
                className={
                  selectedMenu === 1 ? "update-batch-modal__selected-menu" : ""
                }
              >
                Waste Item
              </span>
              <span
                onClick={() => setSelectedMenu(2)}
                className={
                  selectedMenu === 2 ? "update-batch-modal__selected-menu" : ""
                }
              >
                Correct Qty
              </span>
              <span
                onClick={() =>
                  isCorrectTimeDisabled() ? null : setSelectedMenu(3)
                }
                className={`${
                  selectedMenu === 3 ? "update-batch-modal__selected-menu" : ""
                } ${
                  isCorrectTimeDisabled() ? "update-batch-modal__disabled" : ""
                }`}
              >
                Correct Time
              </span>
              <span
                onClick={() =>
                  batchItemColor === "blue" ? null : setSelectedMenu(4)
                }
                className={`${
                  selectedMenu === 4 ? "update-batch-modal__selected-menu" : ""
                } ${
                  batchItemColor === "blue"
                    ? "update-batch-modal__disabled"
                    : ""
                }`}
              >
                {" "}
                Confirm Complete
              </span>
              <span
                onClick={() => setSelectedMenu(5)}
                className={
                  selectedMenu === 5 ? "update-batch-modal__selected-menu" : ""
                }
              >
                Delete Batch
              </span>
              {isPrintAllowed && (
                <span
                  onClick={() =>
                    batchItemColor === "blue" ? null : setSelectedMenu(6)
                  }
                  className={`${
                    selectedMenu === 6
                      ? "update-batch-modal__selected-menu"
                      : ""
                  } ${
                    batchItemColor === "blue"
                      ? "update-batch-modal__disabled"
                      : ""
                  }`}
                >
                  Print
                </span>
              )}
            </div>
          </div>
          <div className="col-md-8">{renderMenu()}</div>
        </div>
      </main>
    </Modal>
  );
};

UpdateBatchModal.defaultProps = {
  openUpdateBatchModal: false,
  toggleUpdateBatchModal: noop,
  selectedBatchItem: {},
  batchItemColor: ""
};

UpdateBatchModal.propTypes = {
  openUpdateBatchModal: PropTypes.bool,
  toggleUpdateBatchModal: PropTypes.func,
  selectedBatchItem: PropTypes.object,
  batchItemColor: PropTypes.string
};

const mapStateToProps = (state) => ({
  selectedBatchItem: getSelectedBatchItemSelector(state),
  batchItemColor: getSelectedBatchItemColorSelector(state)
});

export default connect(mapStateToProps, {
  toggleUpdateBatchModal: toggleUpdateBatchModal
})(UpdateBatchModal);
