import { HubConnectionBuilder } from "@microsoft/signalr";
import { SIGNAL_R_BASE_URL } from "../configs/constants/constants";
import { getAccessTokenFromLocalStorage } from "../utils/helpers";
import { useDispatch } from "react-redux";
import {
  saveSignalRConnectionAction,
  isSignalRConnectedAction
} from "../redux/actions/dahsboard";

export const useSignalRCustomHook = () => {
  const dispatch = useDispatch();

  const initializeSignalR = (connectionFromRedux) => {
    const userToken = getAccessTokenFromLocalStorage();

    if (!connectionFromRedux) {
      const connection = new HubConnectionBuilder()
        .withUrl(`${SIGNAL_R_BASE_URL}/Notification`, {
          accessTokenFactory: () => userToken
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => {
            return 2000;
          }
        })
        .build();

      if (connection?.connectionState === "Disconnected") {
        connection
          .start()
          .then(() => {
            dispatch(saveSignalRConnectionAction(connection));
            dispatch(isSignalRConnectedAction(true));
          })
          .catch((err) => console.error(err));
      }

      connection.onclose(() => {
        dispatch(isSignalRConnectedAction(false));
      });

      connection.onreconnecting(() => {
        dispatch(isSignalRConnectedAction(false));
      });

      connection.onreconnected(() => {
        dispatch(isSignalRConnectedAction(true));
      });
    }
  };

  const stopSignalR = (connection) => {
    if (connection?.connectionState === "Connected") {
      connection.stop();
      dispatch(saveSignalRConnectionAction(null));
    }
  };

  return { initializeSignalR, stopSignalR };
};
