import { createSelector } from "reselect";

const storeSummaryState = (state) => state.storeSummary;

/**
 * STORE SUMMARY DATA : GET
 */
export const storeSummarySelector = createSelector(
  storeSummaryState,
  (SS) => SS.storeSummary
);

/**
 * IS STORE SUMMARY LOADING
 */
export const isStoreSummaryLoadingSelector = createSelector(
  storeSummaryState,
  (SS) => SS.loadingStoreSummary
);
