export const GET_GRAPH_DATA = "GET_GRAPH_DATA";
export const GET_GRAPH_DATA_REQUEST = "GET_GRAPH_DATA_REQUEST";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const GET_GRAPH_DATA_FAILURE = "GET_GRAPH_DATA_FAILURE";

export const GET_SALES_TABLE_DATA = "GET_SALES_TABLE_DATA";
export const GET_SALES_TABLE_DATA_REQUEST = "GET_SALES_TABLE_DATA_REQUEST";
export const GET_SALES_TABLE_DATA_SUCCESS = "GET_SALES_TABLE_DATA_SUCCESS";
export const GET_SALES_TABLE_DATA_FAILURE = "GET_SALES_TABLE_DATA_FAILURE";

export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA";
export const GET_HISTORICAL_DATA_REQUEST = "GET_HISTORICAL_DATA_REQUEST";
export const GET_HISTORICAL_DATA_SUCCESS = "GET_HISTORICAL_DATA_SUCCESS";
export const GET_HISTORICAL_DATA_FAILURE = "GET_HISTORICAL_DATA_FAILURE";

export const UPDATE_CURRENT_SALE = "UPDATE_CURRENT_SALE";
export const UPDATE_CURRENT_SALE_REQUEST = "UPDATE_CURRENT_SALE_REQUEST";
export const UPDATE_CURRENT_SALE_SUCCESS = "UPDATE_CURRENT_SALE_SUCCESS";
export const UPDATE_CURRENT_SALE_FAILURE = "UPDATE_CURRENT_SALE_FAILURE";

export const UPDATE_15_MIN_SALES = "UPDATE_15_MIN_SALES";
export const UPDATE_15_MIN_SALES_REQUEST = "UPDATE_15_MIN_SALES_REQUEST";
export const UDPATE_15_MIN_SALES_SUCCESS = "UDPATE_15_MIN_SALES_SUCCESS";

export const TOGGLE_HISTORICAL_MODAL = "TOGGLE_HISTORICAL_MODAL";

export const UPDATE_FORECAST_DATE = "UPDATE_FORECAST_DATE";

export const UPDATE_DAY_PERIOD_SALES = "UPDATE_DAY_PERIOD_SALES";
export const UPDATE_DAY_PERIOD_SALES_REQUEST =
  "UPDATE_DAY_PERIOD_SALES_REQUEST";
export const UPDATE_DAY_PERIOD_SALES_SUCCESS =
  "UPDATE_DAY_PERIOD_SALES_SUCCESS";
export const UPDATE_DAY_PERIOD_SALES_FAILURE =
  "UPDATE_DAY_PERIOD_SALES_FAILURE";

export const UPDATE_HOURLY_SALES = "UPDATE_HOURLY_SALES";
export const UPDATE_HOURLY_SALES_REQUEST = "UPDATE_HOURLY_SALES_REQUEST";
export const UPDATE_HOURLY_SALES_SUCCESS = "UPDATE_HOURLY_SALES_SUCCESS";
export const UPDATE_HOURLY_SALES_FAILURE = "UPDATE_HOURLY_SALES_FAILURE";

export const RESET_15_MIN_SALES_DATA = "RESET_15_MIN_SALES_DATA";
export const RESET_15_MIN_SALES_DATA_REQUEST =
  "RESET_15_MIN_SALES_DATA_REQUEST";
export const REQUEST_15_MIN_SALES_DATA_SUCCESS =
  "REQUEST_15_MIN_SALES_DATA_SUCCESS";
export const REQUEST_15_MIN_SALES_DATA_FAILURE =
  "REQUEST_15_MIN_SALES_DATA_FAILURE";
