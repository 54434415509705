import React from "react";
import { BiSearch } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

import "./SearchBox.scss";

const SearchBox = () => {
  return (
    <div className="search-box d-flex mt-3 align-items-center">
      <input type="text" className="form-control" />
      <span className="search-box__search-icon-container pointer">
        <BiSearch />
      </span>
      <span className="search-box__clear-icon-container pointer">
        <IoClose />
      </span>
    </div>
  );
};

export default SearchBox;
