import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";
import Sidebar from "react-sidebar";

import Navbar from "../../components/Navbar";
import PageLoader from "../../components/PageLoader/PageLoader";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import {
  reset15MinSalesAction,
  toggleHistoricalModalAction
} from "../../redux/actions/forecast";
import {
  getIsHistoricalModalOpen,
  isGraphDataLoadingSelector,
  isHistoricalDataLoadingSelector,
  isTableDataLoadingSelectr
} from "../../redux/selectors/forecast";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";

import "./ManagerForecastEvaluator.scss";
import ManagerForecastHeader from "../../components/ManagerForecastHeader/ManagerForecastHeader";
import {
  getManagerForecastAction,
  updateManagerForecastDateAction,
  updateManagerForecastStoreAction
} from "../../redux/actions/manager.forecast";
import ManagerForecast from "../../containers/ManagerForecast/ManagerForecast";
import {
  getManagerForecastDateSelector,
  getManagerForecastSelectedStoreSelector
} from "../../redux/selectors/manager.forecast";
import { getAvailableStoreCodesAction } from "../../redux/actions/manager.dashboard";
import { getAvailableStoreCodesSelector } from "../../redux/selectors/dashboard.manager";

const ForeCastEvaluator = ({
  isGraphDataLoading,
  isTableDataLoading,
  isHistoricalDataLoading,
  setSelectedDate,
  selectedDate,
  isSidebarOpen,
  toggleSidebasAction,
  managerForecastSelectedStore,
  updateManagerForecastStoreAction,
  getAvailableStoreCodes,
  availableStoreCodes
}) => {
  const isPageLoading =
    isGraphDataLoading || isTableDataLoading || isHistoricalDataLoading;

  const storesFromLS = availableStoreCodes;

  useEffect(() => {
    getAvailableStoreCodes();
  }, []);

  if (!managerForecastSelectedStore && storesFromLS.length) {
    updateManagerForecastStoreAction({
      value: storesFromLS[0],
      label: storesFromLS[0]
    });
  }

  return (
    <div className="manager-forecast-evaluator">
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <ManagerForecastHeader
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        stores={storesFromLS}
        selectedStore={managerForecastSelectedStore}
        setSelectedStore={updateManagerForecastStoreAction}
      />
      <ManagerForecast
        selectedDate={selectedDate}
        managerForecastSelectedStore={managerForecastSelectedStore}
        isGraphDataLoading={isGraphDataLoading}
      />
      {isPageLoading && <PageLoader />}
    </div>
  );
};

ForeCastEvaluator.defaultProps = {
  isGraphDataLoading: false,
  isTableDataLoading: false,
  isHistoricalDataLoading: false,
  setSelectedDate: noop,
  selectedDate: {},
  isSidebarOpen: false,
  toggleSidebasAction: noop,
  managerForecastSelectedStore: object,
  updateManagerForecastStoreAction: noop
};

ForeCastEvaluator.propTypes = {
  isGraphDataLoading: PropTypes.bool,
  isTableDataLoading: PropTypes.bool,
  isHistoricalDataLoading: PropTypes.bool,
  setSelectedDate: PropTypes.func,
  selectedDate: PropTypes.object,
  isSidebarOpen: PropTypes.bool,
  toggleSidebasAction: PropTypes.func,
  managerForecastSelectedStore: PropTypes.object,
  updateManagerForecastStoreAction: PropTypes.func,
  getAvailableStoreCodes: PropTypes.func,
  availableStoreCodes: PropTypes.array
};

const mapStateToProps = (state) => ({
  isHistoricalModalOpen: getIsHistoricalModalOpen(state),
  isGraphDataLoading: isGraphDataLoadingSelector(state),
  isTableDataLoading: isTableDataLoadingSelectr(state),
  isHistoricalDataLoading: isHistoricalDataLoadingSelector(state),
  selectedDate: getManagerForecastDateSelector(state),
  isSidebarOpen: isSidebarOpenSelector(state),
  managerForecastSelectedStore: getManagerForecastSelectedStoreSelector(state),
  availableStoreCodes: getAvailableStoreCodesSelector(state)
});

const mapDispatchToProps = {
  getManagerForecast: getManagerForecastAction,
  toggleHistoricalModal: toggleHistoricalModalAction,
  setSelectedDate: updateManagerForecastDateAction,
  reset15MinSales: reset15MinSalesAction,
  toggleSidebasAction: toggleSidebasAction,
  updateManagerForecastStoreAction: updateManagerForecastStoreAction,
  getAvailableStoreCodes: getAvailableStoreCodesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ForeCastEvaluator);
