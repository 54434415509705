import axios from "axios";
import axiosInstance from "../configs/axios";

const MRD_ITEMS_API_ENDPOINT = "/api/Item/GetMrdItems";
const PRINT_API_BASE_URL = "https://mpnc.local:5001";
const PRINT_MRD_ITEM_ENDPOINT = "/api/Print/print-mrd?id=";

export const getMrdItemsAPI = () => {
  return axiosInstance
    .get(MRD_ITEMS_API_ENDPOINT)
    .then(({ data }) => data.map((item) => item));
};

export const printMrdItemApi = (payload) => {
  const { mrdId } = payload;
  return axios.get(`${PRINT_API_BASE_URL}${PRINT_MRD_ITEM_ENDPOINT}${mrdId}`);
};
