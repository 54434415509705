import { toast } from "react-toastify";
import { call, takeLatest, put } from "redux-saga/effects";
import {
  GET_MANAGER_STORE_SUMMARY_FAILED,
  GET_MANAGER_STORE_SUMMARY_REQUEST,
  GET_MANAGER_STORE_SUMMARY_SUCCESS
} from "../types/manager.storeSummary";
import { getManagerStoreSummaryAPI } from "../../api/manager.storeSummary";

function* watchManagerStoreSummarySaga() {
  /**
   * GET STORE SUMMARY
   */
  yield takeLatest(
    GET_MANAGER_STORE_SUMMARY_REQUEST,
    function* storeSummarySaga({ payload }) {
      try {
        const data = yield call(getManagerStoreSummaryAPI, payload);
        if (data) {
          yield put({ type: GET_MANAGER_STORE_SUMMARY_SUCCESS, payload: data });
        }
      } catch (e) {
        console.warn("e ===>", e);
        yield put({ type: GET_MANAGER_STORE_SUMMARY_FAILED });
        toast.error(
          "Something went wrong while fetching manager store summary"
        );
      }
    }
  );
}

export default function* managerStoreSummarySaga() {
  yield call(watchManagerStoreSummarySaga);
}
