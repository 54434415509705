export const API_BASE_URL = "https://mpnc.ditl.in";
export const SIGNAL_R_BASE_URL = "https://mpnc.ditl.in";

// export const API_BASE_URL = "http://localhost:7000";
// export const SIGNAL_R_BASE_URL = "http://localhost:7000";

export const UPDATE_BATCH_MODALS = {
  CONFIRM_COOKED: {
    TITLE: "Confirm the batch has finished cooking?",
    CONFIRM_TIME_COMPLETED_HEADER: "Confirm time completed?"
  }
};

export const USER_ROLE = [
  {
    type: "user",
    role: "StoreUser",
    initialPage: "/"
  },
  {
    type: "manager",
    role: "Store",
    initialPage: "/manager-dashboard"
  }
];
