import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { noop } from "lodash";

import "./OverviewCard.scss";

const OverviewCard = ({
  title,
  count,
  sideBorderColor,
  setActiveCard,
  isLoading
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-item-center overview-card shadow pointer ${sideBorderColor}`}
      onClick={() => {
        setActiveCard(count ? title : null);
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        {isLoading ? (
          <h2 className="overview-card__loader">
            <Skeleton />
          </h2>
        ) : (
          <div className="fs-2">{count}</div>
        )}
        <h5 className="text-center text-nowrap">{title}</h5>
      </div>
    </div>
  );
};

OverviewCard.defaultProps = {
  title: "",
  count: 0,
  sideBorderColor: "overview-card_side-border-blue",
  setActiveCard: noop,
  isLoading: false
};

OverviewCard.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  sideBorderColor: PropTypes.string,
  setActiveCard: PropTypes.func,
  isLoading: PropTypes.bool
};

export default OverviewCard;
