import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { noop } from "lodash";
import { BsTable, BsGraphUp } from "react-icons/bs";
import {
  AiOutlinePoweroff,
  AiOutlineAim,
  AiOutlineShoppingCart,
  AiOutlineDashboard
} from "react-icons/ai";
import { MdStore } from "react-icons/md";
import { getUserRole } from "../../utils/helpers";
import DEVYANI_BRAND_LOGO from "../../assets/images/devyani_logo.svg";
import { FaListCheck } from "react-icons/fa6";

import "./SidebarContent.scss";

const SidebarContent = ({ toggleSidebasAction }) => {
  const router = useHistory();

  const isManager = getUserRole() === "Store";

  if (isManager) {
    return (
      <div className="sidebar-content">
        <header>
          <img
            src={DEVYANI_BRAND_LOGO}
            className="sidebar-content__logo"
            height="48px"
          />
        </header>
        <main>
          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              router.push("/manager-dashboard");
            }}
          >
            <AiOutlineDashboard size={18} style={{ marginRight: "8px" }} />{" "}
            Dashboard
          </div>
          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              router.push("/manager-forecast-evaluator");
            }}
          >
            <BsTable size={18} style={{ marginRight: "8px" }} /> Day Forecast
          </div>
          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              router.push("/manager-store-summary");
            }}
          >
            <MdStore size={18} style={{ marginRight: "8px" }} /> Store Summary
          </div>
          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              router.push("/manager-forecast-accuracy");
            }}
          >
            <AiOutlineAim size={18} style={{ marginRight: "8px" }} /> Forecas t
            Accuracy
          </div>

          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              router.push("/manager-current-sales");
            }}
          >
            <AiOutlineShoppingCart size={18} style={{ marginRight: "8px" }} />{" "}
            Stores Current Sales
          </div>
          <div
            className="sidebar-content__item"
            onClick={() => {
              toggleSidebasAction();
              localStorage.clear();
              router.push("/");
            }}
          >
            <AiOutlinePoweroff size={20} style={{ marginRight: "8px" }} />{" "}
            Logout
          </div>
        </main>
        <footer>
          <button
            onClick={toggleSidebasAction}
            className="sidebar-content__footer-button"
          >
            CLOSE
          </button>
        </footer>
      </div>
    );
  }

  return (
    <div className="sidebar-content">
      <header>
        <img
          src={DEVYANI_BRAND_LOGO}
          className="sidebar-content__logo"
          height="48px"
        />
      </header>
      <main>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/");
          }}
        >
          <AiOutlineDashboard size={18} style={{ marginRight: "8px" }} />{" "}
          Dashboard
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/forecast-evaluator?type=graph");
          }}
        >
          <BsGraphUp size={18} style={{ marginRight: "8px" }} /> Sales Forecast
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/forecast-evaluator?type=table");
          }}
        >
          <BsTable size={18} style={{ marginRight: "8px" }} /> Day Forecast
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/store-summary");
          }}
        >
          <MdStore size={18} style={{ marginRight: "8px" }} /> Store Summary
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/forecast-accuracy");
          }}
        >
          <AiOutlineAim size={18} style={{ marginRight: "8px" }} /> Forecast
          Accuracy
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            router.push("/mdr-items");
          }}
        >
          <FaListCheck size={18} style={{ marginRight: "8px" }} /> MRD Items
        </div>
        <div
          className="sidebar-content__item"
          onClick={() => {
            toggleSidebasAction();
            localStorage.clear();
            router.push("/");
          }}
        >
          <AiOutlinePoweroff size={20} style={{ marginRight: "8px" }} /> Logout
        </div>
      </main>
      <footer>
        <button
          onClick={toggleSidebasAction}
          className="sidebar-content__footer-button"
        >
          CLOSE
        </button>
      </footer>
    </div>
  );
};

SidebarContent.defaultProps = {
  toggleSidebasAction: noop
};

SidebarContent.propTypes = {
  toggleSidebasAction: PropTypes.func
};

export default SidebarContent;
