import React from "react";
import PropTypes from "prop-types";
import { chunk } from "lodash";
import { connect } from "react-redux";

import ItemsColumn from "../ItemsColumn";

import {
  getActiveItemsSelector,
  getBatchSelector
} from "../../redux/selectors/dashboard";

import "./ItemsHorizontalCollapse.scss";

const ItemsHorizontalCollapse = ({ connection, activeItems }) => {
  const activeItemsChunks = chunk(Object.values(activeItems), 4);

  return (
    <div className="items-horizontal-collapse mt-3">
      {activeItemsChunks.map((batchArr, index) => (
        <ItemsColumn
          index={index}
          key={index}
          batchArr={batchArr}
          connection={connection}
          activeItemsChunks={activeItemsChunks}
        />
      ))}
    </div>
  );
};

ItemsHorizontalCollapse.defaultProps = {
  batch: {},
  connection: {},
  activeItems: []
};

ItemsHorizontalCollapse.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  batch: PropTypes.object, // { TODO :: Need to verify eslint error, why this is not in use}
  connection: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  activeItems: PropTypes.array
};

const mapStateToProps = (state) => ({
  batch: getBatchSelector(state),
  activeItems: getActiveItemsSelector(state)
});

export default connect(mapStateToProps)(ItemsHorizontalCollapse);
