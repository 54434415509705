import { all, call } from "redux-saga/effects";

import dashboardSaga from "./dashboard.saga";
import updateBatchSaga from "./updateBatch.saga";
import addBatchSaga from "./addBatch.saga";
import forecastSaga from "./forecast.saga";
import authSaga from "./auth.saga";
import storeSummarySaga from "./storeSummary.saga";
import forecastAccuracySaga from "./forecastAccuracy.saga";
import managerDashbaordSaga from "./manager.dashboard.saga";
import managerForecastSaga from "./manager.forecast.saga";
import managerStoreSummarySaga from "./manager.storeSummary";
import managerForecastAccuracySaga from "./manager.forecastAccuracy.saga";
import mrdItemsSaga from "./mrdItems.saga";

export default function* rootSaga() {
  const sagas = [
    call(dashboardSaga),
    call(updateBatchSaga),
    call(addBatchSaga),
    call(forecastSaga),
    call(authSaga),
    call(storeSummarySaga),
    call(mrdItemsSaga),
    call(forecastAccuracySaga),
    call(managerDashbaordSaga),
    call(managerForecastSaga),
    call(managerStoreSummarySaga),
    call(managerForecastAccuracySaga)
  ];

  yield all(sagas);
}
