import {
  GET_GRAPH_DATA_REQUEST,
  GET_GRAPH_DATA_SUCCESS,
  GET_SALES_TABLE_DATA_REQUEST,
  GET_SALES_TABLE_DATA_SUCCESS,
  GET_HISTORICAL_DATA_REQUEST,
  GET_HISTORICAL_DATA_SUCCESS,
  TOGGLE_HISTORICAL_MODAL,
  UPDATE_FORECAST_DATE,
  GET_GRAPH_DATA_FAILURE,
  GET_SALES_TABLE_DATA_FAILURE,
  GET_HISTORICAL_DATA_FAILURE,
  UPDATE_CURRENT_SALE_REQUEST
} from "../types/forecast";

const initialState = {
  isLoadingGraphData: false,
  isLoadingTableData: false,
  isLoadingHistoricalData: false,
  openHistoricalModal: false,
  graphData: [],
  tableData: [],
  historicalData: [],
  forecastDate: new Date()
};

export const forecastReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_GRAPH_DATA_REQUEST:
      return { ...state, isLoadingGraphData: true };
    case GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        graphData: payload,
        isLoadingGraphData: false
      };
    case GET_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isLoadingGraphData: false
      };
    case UPDATE_CURRENT_SALE_REQUEST:
    case GET_SALES_TABLE_DATA_REQUEST:
      return { ...state, isLoadingTableData: true };
    case GET_SALES_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: payload,
        isLoadingTableData: false
      };
    case GET_SALES_TABLE_DATA_FAILURE:
      return {
        ...state,
        isLoadingTableData: false
      };
    case GET_HISTORICAL_DATA_REQUEST:
      return { ...state, isLoadingHistoricalData: true };
    case GET_HISTORICAL_DATA_SUCCESS:
      return {
        ...state,
        historicalData: payload,
        isLoadingHistoricalData: false
      };
    case GET_HISTORICAL_DATA_FAILURE:
      return {
        ...state,
        isLoadingHistoricalData: false
      };
    case TOGGLE_HISTORICAL_MODAL:
      return { ...state, openHistoricalModal: payload };
    case UPDATE_FORECAST_DATE:
      return { ...state, forecastDate: payload };
    default:
      return state;
  }
};
