import React, { useState, useEffect } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Select from "react-select";

import { connect } from "react-redux";
import { noop } from "lodash";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";
import { getManagerForecastAccuracyAction } from "../../redux/actions/manager.forecastAccuracy";

import "./ManagerForecastAccuracy.scss";
import {
  isManagerForecastAccuracyLoadingSelector,
  managerForecastAccuracySelector
} from "../../redux/selectors/manager.forecastAccuracy";
import ManagerForecastAccuracyTableLoader from "../../components/SkeletonLoader/ManagerForecastAccuracyTableLoader";
import { exportToExcel } from "../../utils/helpers";
import { getAvailableStoreCodesAction } from "../../redux/actions/manager.dashboard";
import { getAvailableStoreCodesSelector } from "../../redux/selectors/dashboard.manager";

const ManagerForecastAccuracy = ({
  isSidebarOpen,
  toggleSidebasAction,
  getForecastAccuracy,
  forecastAccuracy,
  isForecastAccuracyLoading,
  getAvailableStoreCodes,
  availableStoreCodes
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStore, setSelectedStore] = useState("");

  useEffect(() => {
    getForecastAccuracy({ date: selectedDate, store: selectedStore });
    getAvailableStoreCodes();
  }, [selectedDate, selectedStore]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  const storesFromLS = availableStoreCodes;
  const storesOptions = storesFromLS.map((store) => {
    return { value: store, label: store };
  });

  if (storesFromLS && storesFromLS.length && !selectedStore) {
    setSelectedStore({ value: storesFromLS[0], label: storesFromLS[0] });
  }

  return (
    <div>
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      <div className="d-flex align-items-center justify-content-between mt-3 container">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center manager-forecast-accuracy__date-picker">
            <span
              className="manager-forecast-accuracy__date-picker-arrows"
              onClick={goToPrevDate}
            >
              <BsCaretLeftFill />
            </span>
            <DatePicker
              className="form-control forecast-header__date-picker"
              selected={selectedDate}
              onChange={(update) => {
                setSelectedDate(update);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              maxDate={new Date().addDays(10)}
            />
            <span
              className="manager-forecast-accuracy__date-picker-arrows"
              onClick={goToNextDate}
            >
              <BsCaretRightFill />
            </span>
          </div>

          <div>
            <Select
              value={selectedStore}
              options={storesOptions}
              className="manager-forecast-header__dropdown"
              style={{ zIndex: 9999 }}
              onChange={(value) => setSelectedStore(value)}
            />
          </div>
        </div>

        <button
          disabled={forecastAccuracy.length === 0}
          className="btn btn-dark"
          onClick={() => {
            // CUSTOM HEADER
            const header = {
              salesType: "Sales Type",
              systemForcast: "System Forecast",
              managerForcast: "Manager Forecast",
              actualSales: "Actual Sales",
              systemVariance: "System Variance",
              managerVariance: "Manager Variance",
              systemAccuracy: "System Accuracy %",
              managerAccuracy: "Manager Accuracy %"
            };

            const data = forecastAccuracy.map((obj) => {
              const newObj = {};
              Object.keys(header).forEach((item) => {
                newObj[header[item]] = obj[item];
              });
              return newObj;
            });

            exportToExcel(data, "manager_forecast_Accuracy.xlsx");
          }}
        >
          Export to Excel
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <table
          className="table table-striped table-bordered manager-forecast-accuracy__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead>
            <tr>
              <th>Sales Type</th>

              <th>System Forecast</th>
              <th>Manager Forecast</th>
              <th>Actual Sales</th>
              <th>System Variance</th>
              <th>Manager Variance</th>
              <th>System Accuracy %</th>
              <th>Manager Accuracy %</th>
            </tr>
          </thead>
          {isForecastAccuracyLoading ? (
            <ManagerForecastAccuracyTableLoader />
          ) : (
            <tbody>
              {forecastAccuracy.length ? (
                forecastAccuracy.map((item, index) => (
                  <tr key={index}>
                    <td>{item.salesType}</td>

                    <td style={{ textAlign: "right" }}>{item.systemForcast}</td>
                    <td style={{ textAlign: "right" }}>
                      {item.managerForcast}
                    </td>
                    <td style={{ textAlign: "right" }}>{item.actualSales}</td>
                    <td style={{ textAlign: "right" }}>
                      {item.systemVariance}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.managerVariance}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.systemAccuracy} %
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {item.managerAccuracy} %
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} align="center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

ManagerForecastAccuracy.defaultProps = {
  toggleSidebasAction: noop,
  isSidebarOpen: false,
  getForecastAccuracy: noop,
  forecastAccuracy: [],
  isForecastAccuracyLoading: false
};

ManagerForecastAccuracy.propTypes = {
  toggleSidebasAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getForecastAccuracy: PropTypes.func,
  forecastAccuracy: PropTypes.array,
  isForecastAccuracyLoading: PropTypes.bool,
  getAvailableStoreCodes: PropTypes.func,
  availableStoreCodes: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isForecastAccuracyLoading: isManagerForecastAccuracyLoadingSelector(state),
  forecastAccuracy: managerForecastAccuracySelector(state),
  availableStoreCodes: getAvailableStoreCodesSelector(state)
});

const mapDispatchToProps = {
  toggleSidebasAction: toggleSidebasAction,
  getForecastAccuracy: getManagerForecastAccuracyAction,
  getAvailableStoreCodes: getAvailableStoreCodesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerForecastAccuracy);
