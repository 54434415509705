import React from "react";
import PropTypes from "prop-types";

const CollapsableRowForManagerForecast = ({ data }) => {
  return (
    <>
      <tr
        className={`graph-table-view__${data[0].dayPeriod}--child`}
        style={{ color: "#FFFFFF" }}
      >
        <td className="pointer">
          <span style={{ marginLeft: "8px" }}>{data[0].salesTime}</span>
        </td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {data.reduce((a, b) => {
            return a + b.lastYearSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {data.reduce((a, b) => {
            return a + b.forcastedSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          {`Rs. ${data.reduce((a, b) => {
            return a + b.managerEdit;
          }, 0)}`}
        </td>
      </tr>
    </>
  );
};

CollapsableRowForManagerForecast.defaultProps = {
  data: []
};

CollapsableRowForManagerForecast.propTypes = {
  data: PropTypes.array
};

export default CollapsableRowForManagerForecast;
