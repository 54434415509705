import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { noop } from "lodash";

import BatchTile from "../../components/BatchTile";
import BatchTileWithImage from "../../components/BatchTileWithImage";

import {
  toggleAddBatchModalAction,
  toggleUpdateBatchModal
} from "../../redux/actions/dahsboard";
import { getTrendByBatchIdSelector } from "../../redux/selectors/dashboard";

import "./Item.scss";

const Item = ({
  toggleUpdateBatchModal,
  batch,
  connection,
  trendData,
  toggleAddBatchModalAction
}) => {
  // SORT BATCH DATA BY EXPIRED DATES
  batch.data.sort((a, b) => {
    return new Date(b.expiredTime) - new Date(a.expiredTime);
  });

  // remove completed batch items
  const batchNotCompleted = batch.data
    .filter((item) => !item.completed)
    .filter((batchItem) => {
      const { cookedTime } = batchItem;
      const currentDate = new Date().getTime();
      const batchCookedTime = new Date(cookedTime).getTime();
      if (currentDate > batchCookedTime) {
        return batchItem;
      }
    })
    .reduce((a, b) => {
      return a + b.batchQty;
    }, 0);

  return (
    <div className="item">
      <header className="item__header">
        <span>
          {" "}
          {batch.description} ({batch.itemId}){" "}
        </span>
        <span>
          Sales Trend:{" "}
          <span
            style={{
              color:
                trendData.trend && trendData.trend.includes("-")
                  ? "red"
                  : "green"
            }}
          >
            {trendData.salesTrend ? trendData.salesTrend : 0} (
            {trendData.trend ? trendData.trend : "+0.00"}%)
          </span>
        </span>
      </header>
      <section className="item__section">
        <BatchTileWithImage
          itemDetails={{ description: batch.description, itemId: batch.itemId }}
          toggleAddBatchModalAction={toggleAddBatchModalAction}
          ImageUrl={batch.imageName}
          holdCount={batchNotCompleted}
          batchSize={batch.batchSize}
          toDrop={batch.toDrop}
        />
        {batch.data.map((batchItem) => {
          if (batchItem.batchQty > 0) {
            return (
              <BatchTile
                key={batchItem.batchNo}
                batchItem={batchItem}
                bgColor="blue"
                toggleUpdateBatchModal={toggleUpdateBatchModal}
                connection={connection}
              />
            );
          }
        })}
      </section>
    </div>
  );
};

Item.defaultProps = {
  toggleUpdateBatchModal: noop,
  batch: [],
  connection: {},
  trendData: {},
  toggleAddBatchModalAction: noop
};

Item.propTypes = {
  toggleUpdateBatchModal: PropTypes.func,
  batch: PropTypes.arrayOf(PropTypes.object),
  connection: PropTypes.object,
  trendData: PropTypes.object,
  toggleAddBatchModalAction: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  let batchId;
  if (ownProps.batch) {
    batchId = ownProps.batch.itemId;
  }
  return {
    trendData: getTrendByBatchIdSelector(state)(batchId)
  };
};

export default connect(mapStateToProps, {
  toggleUpdateBatchModal,
  toggleAddBatchModalAction: toggleAddBatchModalAction
})(Item);
