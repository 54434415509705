import {
  GET_MANAGER_STORE_SUMMARY_REQUEST,
} from "../types/manager.storeSummary";

export const getManagerStoreSummaryAction = (payload) => {
  return {
    type: GET_MANAGER_STORE_SUMMARY_REQUEST,
    payload
  };
};
