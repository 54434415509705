import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  getManagerForecastSelector,
  getManagerForecastTableTotalRowSelector,
  isLoadingManagerForecastSelector
} from "../../redux/selectors/manager.forecast";

import { getManagerForecastAction } from "../../redux/actions/manager.forecast";

import "./ManagerForecast.scss";
import CollapsableRowParentForManagerForecast from "../../components/CollapsableRowParentForManagerForecast/CollapsableRowParentForManagerForecast";
import ManagerForecastTableLoader from "../../components/SkeletonLoader/ManagerForecastTableLoader";
import { noop } from "lodash";

const managerForecastTable = ({
  selectedDate,
  getManagerForecast,
  totalRow,
  collapseData,
  managerForecastSelectedStore,
  isLoadingManagerForecast
}) => {
  useEffect(() => {
    if (getManagerForecast && selectedDate && managerForecastSelectedStore) {
      getManagerForecast({
        date: selectedDate,
        storeObj: managerForecastSelectedStore
      });
    }
  }, [selectedDate, managerForecastSelectedStore]);

  return (
    <div className="px-5">
      <table className="table table-bordered" style={{ fontSize: "14px" }}>
        <thead>
          <tr style={{ background: "#350822", color: "#fff" }}>
            <th style={{ width: "25%" }}>TIME</th>
            <th style={{ width: "25%" }}>LAST WEEK</th>
            <th style={{ width: "25%" }}>SYSTEM FORECAST</th>
            <th className="d-flex justify-content-between">
              <div>MANAGER FORECAST </div>
            </th>
          </tr>
        </thead>
        {isLoadingManagerForecast ? (
          <ManagerForecastTableLoader />
        ) : (
          <tbody>
            <tr style={{ background: "#000000", color: "#FFFFFF" }}>
              <td>{totalRow.parentLabel}</td>
              <td style={{ textAlign: "right" }}>
                Rs. {totalRow.lastYearSale}
              </td>
              <td style={{ textAlign: "right" }}>
                Rs. {totalRow.forcastedSale}
              </td>
              <td style={{ textAlign: "right" }}>Rs. {totalRow.managerEdit}</td>
            </tr>
            {collapseData.map((data, index) => (
              <CollapsableRowParentForManagerForecast key={index} data={data} />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

managerForecastTable.defaultProps = {
  getManagerForecast: noop,
  selectedDate: new Date(),
  collapseData: [],
  totalRow: {},
  managerForecastSelectedStore: {},
  isLoadingManagerForecast: false
};

managerForecastTable.propTypes = {
  getManagerForecast: PropTypes.func,
  selectedDate: PropTypes.object.isRequired,
  collapseData: PropTypes.array,
  totalRow: PropTypes.object,
  managerForecastSelectedStore: PropTypes.object,
  isLoadingManagerForecast: PropTypes.bool
};

const mapStateToProps = (state) => ({
  collapseData: getManagerForecastSelector(state),
  totalRow: getManagerForecastTableTotalRowSelector(state),
  isLoadingManagerForecast: isLoadingManagerForecastSelector(state)
});

export default connect(mapStateToProps, {
  getManagerForecast: getManagerForecastAction
})(managerForecastTable);
