import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import { noop } from "lodash";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";

import "./ManagerCurrentSale.scss";
import { getStoreCurrentSalesAction } from "../../redux/actions/manager.dashboard";
import {
  getStoresCurrentSalesSelector,
  isFetchingStoreCurrentSalesSelector
} from "../../redux/selectors/dashboard.manager";
import CurrentSalesTableLoader from "../../components/SkeletonLoader/CurrentSalesTableLoader";
import {
  exportToExcel,
  isSubstringIgnoreCase,
  subtractDaysFromDateObj
} from "../../utils/helpers";

const headerStyle = {
  backgroundColor: "#0A4091",
  color: "#fff",
  padding: "10px",
  textAlign: "left"
};

const cellStyleRight = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "right"
};

const cellStyleLeft = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left"
};

const ManagerCurrentSales = ({
  isSidebarOpen,
  toggleSidebar,
  getStoreCurrentSales,
  isFetchingStoresCurrentSales,
  storeCurrentSales
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [search, setSearch] = useState("");

  useEffect(() => {
    getStoreCurrentSales({ date: selectedDate });
  }, [selectedDate]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  const filterBySearch = (item) =>
    isSubstringIgnoreCase(item.storeCode, search);

  return (
    <div>
      {/* {isStoreSummaryLoading && <PageLoader />} */}
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebar} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebar} />
      <div className="d-flex align-items-center mt-3 container justify-content-between">
        <div className="d-flex col-8">
          <div className="manager-current-sales__search">
            <input
              className="form-control"
              type="text"
              placeholder="Search by store code"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="manager-current-sales__date-picker">
            <span
              className="manager-current-sales__date-picker-arrows"
              onClick={goToPrevDate}
            >
              <BsCaretLeftFill />
            </span>
            <DatePicker
              className="form-control forecast-header__date-picker"
              selected={selectedDate}
              onChange={(update) => {
                setSelectedDate(update);
              }}
              onChangeRaw={(e) => e.preventDefault()}
              maxDate={new Date()}
              minDate={subtractDaysFromDateObj(new Date(), 40)}
            />
            <span
              className="manager-current-sales__date-picker-arrows"
              onClick={goToNextDate}
            >
              <BsCaretRightFill />
            </span>
          </div>
        </div>

        <button
          disabled={storeCurrentSales.length === 0}
          className="btn btn-dark"
          onClick={() => {
            // CUSTOM HEADER
            const header = {
              storeCode: "Store Code",
              actualSales: "Actual Sales",
              forecastSales: "Forecast Sales",
              managerSales: "Manager Sales",
              systemVariance: "System Variance",
              managerVariance: "Manager Variance",
              systemAccuracy: "System Accuracy",
              managerAccuracy: "Manager Accuracy"
            };

            const data = storeCurrentSales.map((obj) => {
              const newObj = {};
              Object.keys(header).forEach((item) => {
                newObj[header[item]] = obj[item];
              });
              return newObj;
            });

            exportToExcel(data, "manager_current_sales.xlsx");
          }}
        >
          Export to Excel
        </button>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3 container">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={headerStyle}>Store Code</th>
              <th style={headerStyle}>Actual Sales</th>
              <th style={headerStyle}>Forecast Sales</th>
              <th style={headerStyle}>Manager Sales</th>
              <th style={headerStyle}>System Variance</th>
              <th style={headerStyle}>Manager Variance</th>
              <th style={headerStyle}>System Accuracy</th>
              <th style={headerStyle}>Manager Accuracy</th>
            </tr>
          </thead>
          {isFetchingStoresCurrentSales ? (
            <CurrentSalesTableLoader />
          ) : (
            <tbody>
              {storeCurrentSales.filter(filterBySearch).map((item) => (
                <tr key={item.storeCode}>
                  <td style={cellStyleLeft}>{item.storeCode}</td>
                  <td style={cellStyleRight}>{item.actualSales}</td>
                  <td style={cellStyleRight}>{item.forecastSales}</td>
                  <td style={cellStyleRight}>{item.managerSales}</td>
                  <td style={cellStyleRight}>{item.systemVariance}</td>
                  <td style={cellStyleRight}>{item.managerVariance}</td>
                  <td style={cellStyleRight}>{item.systemAccuracy}</td>
                  <td style={cellStyleRight}>{item.managerAccuracy}</td>
                </tr>
              ))}
            </tbody>
          )}
          {!isFetchingStoresCurrentSales && storeCurrentSales.length === 0 ? (
            <tbody>
              <tr>
                <td
                  style={cellStyleRight}
                  colSpan={8}
                  className="text-center p-3"
                >
                  No store current sales found
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </div>
    </div>
  );
};

ManagerCurrentSales.defaultProps = {
  toggleSidebar: noop,
  isSidebarOpen: false,
  getStoreCurrentSales: noop,
  isFetchingStoresCurrentSales: false,
  storeCurrentSales: []
};

ManagerCurrentSales.propTypes = {
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getStoreCurrentSales: PropTypes.func,
  isFetchingStoresCurrentSales: PropTypes.bool,
  storeCurrentSales: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isFetchingStoresCurrentSales: isFetchingStoreCurrentSalesSelector(state),
  storeCurrentSales: getStoresCurrentSalesSelector(state)
});

const mapDispatchToProps = {
  toggleSidebar: toggleSidebasAction,
  getStoreCurrentSales: getStoreCurrentSalesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerCurrentSales);
