import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import DatePicker from "react-datepicker";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
// import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai";

import "react-datepicker/dist/react-datepicker.css";
import "./ForecastHeader.scss";
import { getNumberInTwoDigit } from "../../utils/helpers";

const ForecastHeader = ({
  toggleHistoricalModal,
  selectedDate,
  setSelectedDate,
  selectedToggleMenu,
  // setSelectedToggleMenu,
  reset15MinSales
}) => {
  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  // const setMenu = () => {
  //   setSelectedToggleMenu(selectedToggleMenu === "graph" ? "table" : "graph");
  // };

  const reset = () => {
    const currentTime = new Date();

    const year = selectedDate.getFullYear();
    const month = getNumberInTwoDigit(selectedDate.getMonth() + 1);
    const date = getNumberInTwoDigit(selectedDate.getDate());
    const hours = getNumberInTwoDigit(currentTime.getHours());
    const minutes = getNumberInTwoDigit(currentTime.getMinutes());
    const seconds = getNumberInTwoDigit(currentTime.getSeconds());
    const request = {
      salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    };
    reset15MinSales(request);
  };

  return (
    <div className="forecast-header">
      <span className="d-flex align-items-center">
        <span
          className="forecast-header__date-picker-arrows"
          onClick={goToPrevDate}
        >
          <BsCaretLeftFill />
        </span>
        <DatePicker
          className="form-control forecast-header__date-picker"
          selected={selectedDate}
          onChange={(update) => {
            setSelectedDate(update);
          }}
          onChangeRaw={(e) => e.preventDefault()}
          maxDate={new Date().addDays(10)}
        />
        <span
          className="forecast-header__date-picker-arrows"
          onClick={goToNextDate}
        >
          <BsCaretRightFill />
        </span>
      </span>
      <div>
        {selectedToggleMenu === "table" && (
          <span
            className="forecast-header__history-block "
            onClick={() => reset()}
          >
            Reset
          </span>
        )}

        {/* <span className="forecast-header__toggle-block m-2" onClick={setMenu}>
          {selectedToggleMenu === "graph" ? (
            <AiOutlineTable />
          ) : (
            <AiOutlineLineChart />
          )}
        </span> */}
        <span
          className="forecast-header__history-block"
          onClick={() => toggleHistoricalModal(true)}
        >
          History
        </span>
      </div>
    </div>
  );
};

ForecastHeader.defaultProps = {
  toggleHistoricalModal: noop,
  selectedDate: new Date(),
  setSelectedDate: noop,
  selectedToggleMenu: "graph",
  // setSelectedToggleMenu: noop,
  reset15MinSales: noop
};

ForecastHeader.propTypes = {
  toggleHistoricalModal: PropTypes.func,
  selectedDate: PropTypes.object,
  setSelectedDate: PropTypes.func,
  selectedToggleMenu: PropTypes.string,
  // setSelectedToggleMenu: PropTypes.func,
  reset15MinSales: PropTypes.func
};

export default ForecastHeader;
