import {
  GET_ACTIVE_INACTIVE_STORES_FAILURE,
  GET_ACTIVE_INACTIVE_STORES_REQUEST,
  GET_ACTIVE_INACTIVE_STORES_SUCCESS,
  GET_AVAILABLE_STORE_CODES_FAILURE,
  GET_AVAILABLE_STORE_CODES_REQUEST,
  GET_AVAILABLE_STORE_CODES_SUCCESS,
  GET_STORES_CURRENT_SALES_FAILURE,
  GET_STORES_CURRENT_SALES_REQUEST,
  GET_STORES_CURRENT_SALES_SUCCESS,
  GET_STORES_LAST_TX_FAILURE,
  GET_STORES_LAST_TX_REQUEST,
  GET_STORES_LAST_TX_SUCCESS,
  GET_STORE_SALES_HISTORY_FAILURE,
  GET_STORE_SALES_HISTORY_REQUEST,
  GET_STORE_SALES_HISTORY_SUCCESS,
  GET_TOTAL_STORE_SALES_FAILURE,
  GET_TOTAL_STORE_SALES_REQUEST,
  GET_TOTAL_STORE_SALES_SUCCESS
} from "../types/manager.dashboard";

const initialState = {
  loadingActiveInactive: false,
  activeInactiveStores: {},
  loadingStoreSalesHistory: false,
  storeSalesHistory: [],
  loadingStoresLastTx: false,
  storesLastTx: [],
  loadingStoresCurrentSales: false,
  storesCurrentSales: [],
  availableStoreCodes: [],
  totalStoreSales: {},
  loadingTotalStoreSales: false
};

export const managerDashboardReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_ACTIVE_INACTIVE_STORES_REQUEST:
      return {
        ...state,
        loadingActiveInactive: true,
        activeInactiveStores: {}
      };
    case GET_ACTIVE_INACTIVE_STORES_SUCCESS:
      return {
        ...state,
        loadingActiveInactive: false,
        activeInactiveStores: payload
      };
    case GET_ACTIVE_INACTIVE_STORES_FAILURE:
      return {
        ...state,
        loadingActiveInactive: false,
        activeInactiveStores: {}
      };
    case GET_TOTAL_STORE_SALES_REQUEST:
      return {
        ...state,
        loadingTotalStoreSales: true,
        totalStoreSales: {}
      };
    case GET_TOTAL_STORE_SALES_SUCCESS:
      return {
        ...state,
        loadingTotalStoreSales: false,
        totalStoreSales: payload
      };
    case GET_TOTAL_STORE_SALES_FAILURE:
      return {
        ...state,
        loadingTotalStoreSales: false,
        totalStoreSales: {}
      };
    case GET_STORE_SALES_HISTORY_REQUEST:
      return {
        ...state,
        loadingStoreSalesHistory: true,
        storeSalesHistory: []
      };
    case GET_STORE_SALES_HISTORY_SUCCESS:
      return {
        ...state,
        loadingStoreSalesHistory: false,
        storeSalesHistory: payload
      };
    case GET_STORE_SALES_HISTORY_FAILURE:
      return {
        ...state,
        loadingStoreSalesHistory: true,
        storeSalesHistory: []
      };
    case GET_STORES_LAST_TX_REQUEST:
      return {
        ...state,
        loadingStoresLastTx: true,
        storesLastTx: []
      };
    case GET_STORES_LAST_TX_SUCCESS:
      return {
        ...state,
        loadingStoresLastTx: false,
        storesLastTx: payload
      };
    case GET_STORES_LAST_TX_FAILURE:
      return {
        ...state,
        loadingStoresLastTx: false,
        storesLastTx: []
      };
    case GET_STORES_CURRENT_SALES_REQUEST:
      return {
        ...state,
        loadingStoresCurrentSales: true,
        storesCurrentSales: []
      };
    case GET_STORES_CURRENT_SALES_SUCCESS:
      return {
        ...state,
        loadingStoresCurrentSales: false,
        storesCurrentSales: payload
      };
    case GET_STORES_CURRENT_SALES_FAILURE:
      return {
        ...state,
        loadingStoresCurrentSales: false,
        storesCurrentSales: []
      };
    case GET_AVAILABLE_STORE_CODES_REQUEST:
      return {
        ...state,
        availableStoreCodes: []
      };
    case GET_AVAILABLE_STORE_CODES_SUCCESS:
      return {
        ...state,
        availableStoreCodes: payload
      };
    case GET_AVAILABLE_STORE_CODES_FAILURE:
      return {
        ...state,
        availableStoreCodes: []
      };
    default:
      return state;
  }
};
