import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { noop } from "lodash";

import PercentToggle from "../../components/PercentToggle";
import CollapsableRowParent from "../../components/CollapsableRowParent/CollapsableRowParent";

import {
  getGraphDataForTableCollapseSelector,
  getGraphTableViewTotalRowData
} from "../../redux/selectors/forecast";

import {
  getGraphDataAction,
  update15MinSalesData,
  updateDayPeriodSalesAction,
  updateHourlySalesAction
} from "../../redux/actions/forecast";

import "./GraphTableView.scss";
import TableRowInput from "../../components/TableRowInput";
import PercentTableRowInput from "../../components/PercentTableRowInput";

const GraphTableView = ({
  selectedDate,
  getGraphData,
  updateCurrentSale,
  updateDayPeriodSales,
  updateHourlySales,
  isGraphDataLoading,
  graphDataCollapse,
  graphDataTotalRow
}) => {
  const [selectedMode, setSelectedMode] = useState("rs");
  const [inputDisabled, setInputDisabled] = useState(false);

  useEffect(() => {
    if (getGraphData) {
      getGraphData(selectedDate);
    }

    const currentDate = new Date().setHours(0);
    if (selectedDate.getTime() < currentDate) {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
  }, [selectedDate]);

  // TODO:: Check for solution (not reliable)
  useEffect(() => {
    if (selectedMode === "%" && !isGraphDataLoading) {
      setSelectedMode("rs");
      setTimeout(() => {
        setSelectedMode("%");
      }, 200);
    }
  }, [isGraphDataLoading]);

  return (
    <div className="px-5">
      <table className="table table-bordered" style={{ fontSize: "14px" }}>
        <thead>
          <tr style={{ background: "#350822", color: "#fff" }}>
            <th style={{ width: "25%" }}>TIME</th>
            <th style={{ width: "25%" }}>LAST WEEK</th>
            <th style={{ width: "25%" }}>SYSTEM FORECAST</th>
            <th className="d-flex justify-content-between">
              <div>MANAGER FORECAST </div>
              <div>
                <PercentToggle
                  selectedMode={selectedMode}
                  setSelectedMode={setSelectedMode}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ background: "#000000", color: "#FFFFFF" }}>
            <td>{graphDataTotalRow.parentLabel}</td>
            <td style={{textAlign: "right"}}>Rs. {graphDataTotalRow.lastYearSale}</td>
            <td style={{textAlign: "right"}}>Rs. {graphDataTotalRow.forcastedSale}</td>
            <td style={{textAlign: "right"}}>
              {selectedMode === "%" ? (
                <PercentTableRowInput
                  defaultValue={graphDataTotalRow.managerEdit}
                  update15MinSale={updateCurrentSale}
                  createDate={graphDataTotalRow.createDate}
                  salesType={graphDataTotalRow.salesType}
                  disabled={
                    (selectedMode === "%" && !graphDataTotalRow.managerEdit) ||
                    inputDisabled
                  }
                  origin="table"
                  parentLabel={graphDataTotalRow.dayPeriod}
                  type={"parent"}
                  updateDayPeriodSales={updateDayPeriodSales}
                />
              ) : (
                <TableRowInput
                  defaultValue={`Rs. ${graphDataTotalRow.managerEdit}`}
                  update15MinSale={updateCurrentSale}
                  createDate={graphDataTotalRow.createDate}
                  salesType={graphDataTotalRow.salesType}
                  origin="table"
                  parentLabel={graphDataTotalRow.dayPeriod}
                  type={"parent"}
                  updateDayPeriodSales={updateDayPeriodSales}
                  inputDisabled={inputDisabled}
                />
              )}
            </td>
          </tr>
          {graphDataCollapse.map((data, index) => (
            <CollapsableRowParent
              key={index}
              data={data}
              selectedMode={selectedMode}
              updateCurrentSale={updateCurrentSale}
              updateDayPeriodSales={updateDayPeriodSales}
              updateHourlySales={updateHourlySales}
              inputDisabled={inputDisabled}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

GraphTableView.defaultProps = {
  graphData: [],
  getGraphData: noop,
  updateCurrentSale: noop,
  updateDayPeriodSales: noop,
  updateHourlySales: noop,
  isGraphDataLoading: false,
  graphDataCollapse: [],
  graphDataTotalRow: {}
};

GraphTableView.propTypes = {
  getGraphData: PropTypes.func,
  selectedDate: PropTypes.object.isRequired,
  updateCurrentSale: PropTypes.func,
  updateDayPeriodSales: PropTypes.func,
  updateHourlySales: PropTypes.func,
  isGraphDataLoading: PropTypes.bool,
  graphDataCollapse: PropTypes.array,
  graphDataTotalRow: PropTypes.object
};

const mapStateToProps = (state) => ({
  graphDataCollapse: getGraphDataForTableCollapseSelector(state),
  graphDataTotalRow: getGraphTableViewTotalRowData(state)
});

export default connect(mapStateToProps, {
  getGraphData: getGraphDataAction,
  updateCurrentSale: update15MinSalesData,
  updateDayPeriodSales: updateDayPeriodSalesAction,
  updateHourlySales: updateHourlySalesAction
})(GraphTableView);
