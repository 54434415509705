import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import Button from "../Button";

import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";
import { updateWasteQuantityAction } from "../../redux/actions/updateBatch";
import {
  getWasteReasonSelector,
  isUpdateWasteQuantityInProgress
} from "../../redux/selectors/updateWasteQuantity";

import "./WasteItem.scss";

const WasteItem = ({
  selectedBatchItem,
  toggleUpdateBatchModal,
  isUpdateWasteQuantityInProgress,
  updateWasteQuantityAction,
  wasteReasons
}) => {
  const [quantity, setQantity] = useState("");
  const [wasteReason, setWasteReason] = useState("");

  const updateWasteItem = () => {
    updateWasteQuantityAction({
      batchId: selectedBatchItem.batchNo,
      data: Number(quantity),
      wasteReason: wasteReason
    });
  };

  const updateQuantity = (event) => {
    const { value } = event.target;
    if (Number(value) > selectedBatchItem.batchQty) {
      setQantity(selectedBatchItem.batchQty);
    } else {
      setQantity(value);
    }
  };

  const onSelectChange = (value) => {
    setWasteReason(value.label);
  };

  return (
    <div className="waste-item">
      <div className="waste-item__inner-section">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 d-flex flex-column justify-content-around p-0">
              <header>Enter Waste Qty:</header>
              <input
                type="number"
                className="waste-item__input"
                value={quantity}
                onChange={updateQuantity}
                placeholder="0"
              />
              <span>Wasted in batch: {selectedBatchItem.batchQty}</span>
            </div>
            <div className="col-md-6 waste-item__right-section p-0">
              <div className="waste-item__right-section--first-child mt-5">
                <Select
                  options={wasteReasons}
                  placeholder="Select Reason"
                  maxMenuHeight="140px"
                  onChange={onSelectChange}
                />
                <div className="d-flex w-100 mt-3 justify-content-between">
                  <Button
                    label="Confirm Waste"
                    height="80px"
                    onClick={updateWasteItem}
                    disabled={!wasteReason || isUpdateWasteQuantityInProgress}
                    isLoading={isUpdateWasteQuantityInProgress}
                  />
                  <Button
                    label="Cancel"
                    color="red"
                    height="80px"
                    onClick={() =>
                      toggleUpdateBatchModal({ open: false, batchItem: {} })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WasteItem.defaultProps = {
  selectedBatchItem: {},
  toggleUpdateBatchModal: noop,
  isUpdateWasteQuantityInProgress: false,
  updateWasteQuantityAction: noop,
  wasteReasons: []
};

WasteItem.propTypes = {
  selectedBatchItem: PropTypes.object,
  toggleUpdateBatchModal: PropTypes.func,
  isUpdateWasteQuantityInProgress: PropTypes.bool,
  updateWasteQuantityAction: PropTypes.func,
  wasteReasons: PropTypes.array
};

const mapStateToProps = (state) => ({
  isUpdateWasteQuantityInProgress: isUpdateWasteQuantityInProgress(state),
  wasteReasons: getWasteReasonSelector(state)
});

export default connect(mapStateToProps, {
  toggleUpdateBatchModal: toggleUpdateBatchModal,
  updateWasteQuantityAction: updateWasteQuantityAction
})(WasteItem);
