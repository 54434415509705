import axiosInstance from "../configs/axios";

const UPDATE_CONFIRMED_COOKED_TIME_API_ENDPOINT =
  "/api/Batch/ConfirmCookedTime?batchId=";
const UPDATE_EXPIRY_TIME_API_ENDPOINT = "/api/Batch/UpdateExpireTime?batchId=";
const UPDATE_QUANTITY_API_ENDPOINT = "/api/Batch/UpdateQuantity?batchId=";
const UPDATE_WASTE_QUANTITY_API_ENDPOINT =
  "/api/Batch/UpdateWasteQuantity?batchId=";
const DELETE_BATCH_API_ENDPOINT = "/api/Batch/DeleteBatch?batchId=";
const CONFIRM_COMPLETE_BATCH_API_ENDPOINT = "/api/Batch/ConfirmComplete";

export const updateConfirmedCookedTimeAPI = (payload) => {
  const { batchId, data } = payload;
  return axiosInstance
    .put(`${UPDATE_CONFIRMED_COOKED_TIME_API_ENDPOINT}${batchId}`, {
      time: data
    })
    .then(({ data }) => data);
};

export const updateExpiryTimeAPI = (payload) => {
  const { batchId, data } = payload;
  return axiosInstance
    .put(`${UPDATE_EXPIRY_TIME_API_ENDPOINT}${batchId}`, {
      expireDate: data
    })
    .then(({ data }) => data);
};

export const updateQuantityAPI = (payload) => {
  const { batchId, data } = payload;
  return axiosInstance
    .put(`${UPDATE_QUANTITY_API_ENDPOINT}${batchId}`, {
      quantity: data
    })
    .then(({ data }) => data);
};

export const updateWasteQuantityAPI = (payload) => {
  const { batchId, data, wasteReason } = payload;
  return axiosInstance
    .put(
      `${UPDATE_WASTE_QUANTITY_API_ENDPOINT}${batchId}&reason=${wasteReason}`,
      {
        quantity: data
      }
    )
    .then(({ data }) => data);
};

export const deleteBatchAPI = (payload) => {
  const { batchId, deleteReason } = payload;
  return axiosInstance
    .delete(`${DELETE_BATCH_API_ENDPOINT}${batchId}&reason=${deleteReason}`)
    .then(({ data }) => data);
};

export const confirmCompleteAPI = (payload) => {
  const { batchId } = payload;
  return axiosInstance
    .put(`${CONFIRM_COMPLETE_BATCH_API_ENDPOINT}`, {
      batchId
    })
    .then(({ data }) => data);
};
