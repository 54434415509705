import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import Dashboard from "./pages/Dashboard/Dashboard";
import ForeCastEvaluator from "./pages/ForecastEvaluator_without15min/ForeCastEvaluator";
import SummaryReport from "./pages/SummaryReport/SummaryReport";
import Login from "./pages/Login";
import store from "./redux/store";
import ForecastAccuracy from "./pages/ForecastAccuracy/ForecastAccuracy";
import StoreSummary from "./pages/StoreSummary";
import ManagerDashboard from "./pages/ManagerDashboard/ManagerDashboard";
import ManagerForecastEvaluator from "./pages/ManagerForecastEvaluator/ManagerForecastEvaluator";
import ManagerStoreSummary from "./pages/ManagerStoreSummary/ManagerStoreSummary";
import ManagerForecastAccuracy from "./pages/ManagerForecastAccuracy/ManagerForecastAccuracy";

import ProtectedRoute from "./components/ProtectedRoute";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
import "./App.scss";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import NotFound from "./pages/NotFound/NotFound";
import ManagerCurrentSales from "./pages/ManagerCurrentSales";
import MrdItems from "./pages/MrdItems";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <ProtectedRoute exact path="/" component={Dashboard} />
          {/* <Route exact path="/signup" component={Signup} /> */}
          {/* <Route exact path="/forgot" component={Forgot} /> */}
          <ProtectedRoute
            exact
            path="/forecast-evaluator"
            component={ForeCastEvaluator}
          />
          <ProtectedRoute
            exact
            path="/summary-report"
            component={SummaryReport}
          />
          <ProtectedRoute
            exact
            path="/store-summary"
            component={StoreSummary}
          />
          <ProtectedRoute exact path="/mdr-items" component={MrdItems} />
          <ProtectedRoute
            exact
            path="/forecast-accuracy"
            component={ForecastAccuracy}
          />
          <ProtectedRoute
            exact
            path="/manager-dashboard"
            component={ManagerDashboard}
          />
          <ProtectedRoute
            exact
            path="/manager-forecast-evaluator"
            component={ManagerForecastEvaluator}
          />
          <ProtectedRoute
            exact
            path="/manager-store-summary"
            component={ManagerStoreSummary}
          />
          <ProtectedRoute
            exact
            path="/manager-forecast-accuracy"
            component={ManagerForecastAccuracy}
          />
          <ProtectedRoute
            exact
            path="/manager-current-sales"
            component={ManagerCurrentSales}
          />
          <Route exact path="/access-denied" component={AccessDenied} />
          <Route path="" component={NotFound} />
        </Switch>
      </Router>
      <ToastContainer position="top-right" />
    </Provider>
  );
}

export default App;
