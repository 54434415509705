import {
  GET_GRAPH_DATA_REQUEST,
  GET_HISTORICAL_DATA_REQUEST,
  GET_SALES_TABLE_DATA_REQUEST,
  RESET_15_MIN_SALES_DATA_REQUEST,
  TOGGLE_HISTORICAL_MODAL,
  UPDATE_15_MIN_SALES_REQUEST,
  UPDATE_CURRENT_SALE_REQUEST,
  UPDATE_DAY_PERIOD_SALES_REQUEST,
  UPDATE_FORECAST_DATE,
  UPDATE_HOURLY_SALES_REQUEST
} from "../types/forecast";

export const getGraphDataAction = (payload) => ({
  type: GET_GRAPH_DATA_REQUEST,
  payload
});

export const getSalesTableDataAction = (payload) => ({
  type: GET_SALES_TABLE_DATA_REQUEST,
  payload
});

export const getHistoricalDataAction = (payload) => ({
  type: GET_HISTORICAL_DATA_REQUEST,
  payload
});

export const updateCurrentSaleAction = (payload) => ({
  type: UPDATE_CURRENT_SALE_REQUEST,
  payload
});

export const update15MinSalesData = (payload) => ({
  type: UPDATE_15_MIN_SALES_REQUEST,
  payload
});

export const toggleHistoricalModalAction = (payload) => ({
  type: TOGGLE_HISTORICAL_MODAL,
  payload
});

export const updateSelectedForecastDateAction = (payload) => ({
  type: UPDATE_FORECAST_DATE,
  payload
});

export const updateDayPeriodSalesAction = (payload) => ({
  type: UPDATE_DAY_PERIOD_SALES_REQUEST,
  payload
});

export const updateHourlySalesAction = (payload) => ({
  type: UPDATE_HOURLY_SALES_REQUEST,
  payload
});

export const reset15MinSalesAction = (payload) => ({
  type: RESET_15_MIN_SALES_DATA_REQUEST,
  payload
});
