import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import { Button } from "react-bootstrap";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";
import PageLoader from "../../components/PageLoader/PageLoader";

import "./styles.scss";
import {
  getMrdItemsAction,
  sendPrintMrdItemRequestAction
} from "../../redux/actions/mrdItems";
import {
  isMrdItemsLoadingSelector,
  mrdItemsSelector
} from "../../redux/selectors/mrdItemsSelector";
import useTablePagination from "../../customHooks/TablePagination";
import { isSubstringIgnoreCase } from "../../utils/helpers";

const StoreSummary = ({
  isSidebarOpen,
  toggleSidebasAction,
  getMrdItems,
  isMrdItemsLoading,
  mdrItems,
  sendPrintMrdItemRequest
}) => {
  const [search, setSearch] = useState("");
  const [pageData, setPageData] = useState(mdrItems);

  const {
    currentPage,
    totalPages,
    getCurrentPageData,
    nextPage,
    prevPage,
    isFirstPage,
    isLastPage
  } = useTablePagination(pageData, 10);

  const tableData = getCurrentPageData();

  useEffect(() => {
    getMrdItems();
  }, []);

  useEffect(() => {
    let filteredData = mdrItems.filter((item) => {
      let searchIn = "";
      if (item.id) {
        searchIn += item.id;
      }
      if (item.itemGroup) {
        searchIn += item.itemGroup;
      }
      if (item.productName) {
        searchIn += item.productName;
      }
      if (item.discardDimension) {
        searchIn += item.discardDimension;
      }
      return isSubstringIgnoreCase(searchIn, search);
    });
    setPageData(filteredData);
  }, [search, mdrItems]);

  const handlePrintItem = (itemId) => {
    sendPrintMrdItemRequest({ mrdId: itemId });
  };

  return (
    <div>
      {isMrdItemsLoading && <PageLoader />}
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      <div className="d-flex align-items-center mt-3 mrd-items__container">
        <div className="d-flex justify-content-between mrd-items__container__header">
          <div style={{ width: "25%" }}>
            <input
              className="form-control"
              type="text"
              placeholder="Search Item..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <h4 className="text-center">MRD Items</h4>
          <div style={{ width: "25%" }}></div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <table
          className="table table-striped table-bordered mrd-items__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead>
            <tr>
              <th>Item Id</th>
              <th>Item Group</th>
              <th>Item Type</th>
              <th>Item Name</th>
              <th>Ready</th>
              <th>Ready Dimension</th>
              <th>Discard</th>
              <th>Discard Dimension</th>
              <th className="mrd-items__table-container__align_center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.length ? (
              tableData.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.itemGroup}</td>
                  <td>{item.itemType}</td>
                  <td>{item.productName}</td>
                  <td>{item.ready}</td>
                  <td>{item.readyDimension}</td>
                  <td>{item.discard}</td>
                  <td>{item.discardDimension}</td>
                  <td className="mrd-items__table-container__align_center">
                    <button
                      className="btn btn-dark btn-sm mrd-items__mdr_print_btn"
                      onClick={() => {
                        handlePrintItem(item.id);
                      }}
                    >
                      Print
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={15} align="center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {tableData && tableData.length ? (
        <div className="mt-3 mb-3 d-flex justify-content-center align-items-center">
          <Button variant="dark" disabled={isFirstPage} onClick={prevPage}>
            Previous Page
          </Button>
          <span className="mx-2">
            {currentPage}/{totalPages}
          </span>
          <Button variant="dark" disabled={isLastPage} onClick={nextPage}>
            Next Page
          </Button>
        </div>
      ) : null}
    </div>
  );
};

StoreSummary.defaultProps = {
  toggleSidebasAction: noop,
  isSidebarOpen: false,
  isMrdItemsLoading: false,
  mdrItems: []
};

StoreSummary.propTypes = {
  toggleSidebasAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  isMrdItemsLoading: PropTypes.bool,
  mdrItems: PropTypes.array
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isMrdItemsLoading: isMrdItemsLoadingSelector(state),
  mdrItems: mrdItemsSelector(state)
});

const mapDispatchToProps = {
  toggleSidebasAction: toggleSidebasAction,
  getMrdItems: getMrdItemsAction,
  sendPrintMrdItemRequest: sendPrintMrdItemRequestAction
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreSummary);
