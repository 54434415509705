import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
// import { AiOutlineCaretDown, AiOutlineCaretRight } from "react-icons/ai";

import PercentTableRowInput from "../../components/PercentTableRowInput";
import TableRowInput from "../../components/TableRowInput";

const CollapsableRows = ({
  data,
  selectedMode,
  updateCurrentSale,
  inputDisabled,
  updateDayPeriodSales,
  updateHourlySales
}) => {
  // const [collapsed, setCollapsed] = useState(true);

  return (
    <>
      <tr
        className={`graph-table-view__${data[0].dayPeriod}--child`}
        style={{ color: "#FFFFFF" }}
      >
        {/* <td onClick={() => setCollapsed(!collapsed)} className="pointer"> */}
        <td className="pointer">
          {/* {!collapsed ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />} */}
          <span style={{ marginLeft: "8px" }}>{data[0].salesTime}</span>
        </td>
        <td style={{textAlign:"right"}}>
          Rs.{" "}
          {data.reduce((a, b) => {
            return a + b.lastYearSale;
          }, 0)}
        </td>
        <td style={{textAlign:"right"}}>
          Rs.{" "}
          {data.reduce((a, b) => {
            return a + b.forcastedSale;
          }, 0)}
        </td>
        <td style={{textAlign:"right"}}>
          {selectedMode === "%" ? (
            <PercentTableRowInput
              defaultValue={data.reduce((a, b) => {
                return a + b.managerEdit;
              }, 0)}
              update15MinSale={updateCurrentSale}
              createDate={data[0].createDate}
              salesType={data[0].salesType}
              disabled={
                (selectedMode === "%" &&
                  !data.reduce((a, b) => {
                    return a + b.managerEdit;
                  }, 0)) ||
                inputDisabled
              }
              origin="table"
              parentLabel={data[0].dayPeriod}
              type={"head"}
              updateDayPeriodSales={updateDayPeriodSales}
              updateHourlySales={updateHourlySales}
            />
          ) : (
            <TableRowInput
              defaultValue={`Rs. ${data.reduce((a, b) => {
                return a + b.managerEdit;
              }, 0)}`}
              update15MinSale={updateCurrentSale}
              createDate={data[0].createDate}
              salesType={data[0].salesType}
              origin="table"
              parentLabel={data[0].dayPeriod}
              type={"head"}
              updateDayPeriodSales={updateDayPeriodSales}
              inputDisabled={inputDisabled}
              updateHourlySales={updateHourlySales}
            />
          )}
        </td>
      </tr>
      {/* {!collapsed &&
        data.map(
          ({
            salesTime,
            lastYearSale,
            forcastedSale,
            managerEdit,
            createDate,
            salesType,
            parentLabel,
            type,
            dayPeriod
          }) => (
            <tr
              key={createDate}
              className={`graph-table-view__${dayPeriod}--collapsed`}
              style={{ color: "#FFFFFF" }}
            >
              <td style={{ paddingLeft: "32px" }}>{salesTime}</td>
              <td>Rs. {lastYearSale}</td>
              <td>Rs. {forcastedSale}</td>
              <td>
                {selectedMode === "%" ? (
                  <PercentTableRowInput
                    defaultValue={managerEdit}
                    update15MinSale={updateCurrentSale}
                    createDate={createDate}
                    salesType={salesType}
                    disabled={
                      (selectedMode === "%" && !managerEdit) || inputDisabled
                    }
                    origin="table"
                    parentLabel={parentLabel}
                    type={type}
                    updateDayPeriodSales={updateDayPeriodSales}
                  />
                ) : (
                  <TableRowInput
                    defaultValue={`Rs. ${managerEdit}`}
                    update15MinSale={updateCurrentSale}
                    createDate={createDate}
                    salesType={salesType}
                    origin="table"
                    parentLabel={parentLabel}
                    type={type}
                    updateDayPeriodSales={updateDayPeriodSales}
                    inputDisabled={inputDisabled}
                  />
                )}
              </td>
            </tr>
          )
        )} */}
    </>
  );
};

CollapsableRows.defaultProps = {
  data: [],
  selectedMode: "rs",
  updateCurrentSale: noop,
  inputDisabled: false,
  updateDayPeriodSales: noop,
  updateHourlySales: noop
};

CollapsableRows.propTypes = {
  data: PropTypes.array,
  selectedMode: PropTypes.string,
  updateCurrentSale: PropTypes.func,
  inputDisabled: PropTypes.bool,
  updateDayPeriodSales: PropTypes.func,
  updateHourlySales: PropTypes.func
};

export default CollapsableRows;
