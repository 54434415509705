import React, { useEffect } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import { MdClose } from "react-icons/md";

import Button from "../../components/Button";

import { getHistoricalDataSelector } from "../../redux/selectors/forecast";
import { getHistoricalDataAction } from "../../redux/actions/forecast";

import { getDate } from "../../utils/helpers";

import "./HistoryModal.scss";

const HistoryModal = ({
  isHistoricalModalOpen,
  getHistoricalData,
  toggleHistoricalModal,
  historicalData,
  selectedDate
}) => {
  useEffect(() => {
    getHistoricalData(selectedDate);
  }, []);

  historicalData.sort((a, b) => {
    return new Date(a.dateofSale) - new Date(b.dateofSale);
  });

  return (
    <Modal isOpen={isHistoricalModalOpen}>
      <div className="history-modal">
        <header className="history-modal__header">
          <h5>Historical Data ({getDate(new Date(selectedDate))})</h5>
          <span>
            <MdClose
              className="history-modal__close-icon"
              size="1.5em"
              color="red"
              onClick={() => toggleHistoricalModal(false)}
            />
          </span>
        </header>
        <main className="history-modal__content">
          <table
            className="table table-striped table-bordered mt-5"
            style={{ fontSize: "14px" }}
          >
            <thead>
              <tr>
                {historicalData.map((item) => (
                  <th key={item.dateofSale}>{getDate(item.dateofSale)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {historicalData.map((item) => (
                  <td key={item.dateofSale}>Rs. {item.salesAmount}</td>
                ))}
              </tr>
            </tbody>
          </table>
        </main>
        <footer className="history-modal__footer">
          <Button
            label="Close"
            color="red"
            height="40px"
            onClick={() => toggleHistoricalModal(false)}
          />
        </footer>
      </div>
    </Modal>
  );
};

HistoryModal.defaultProps = {
  isHistoricalModalOpen: false,
  getHistoricalData: noop,
  toggleHistoricalModal: noop,
  historicalData: [],
  selectedDate: new Date()
};

HistoryModal.propTypes = {
  isHistoricalModalOpen: PropTypes.bool,
  getHistoricalData: PropTypes.func,
  toggleHistoricalModal: PropTypes.func,
  historicalData: PropTypes.array,
  selectedDate: PropTypes.object
};

const mapStateToProps = (state) => ({
  historicalData: getHistoricalDataSelector(state)
});

export default connect(mapStateToProps, {
  getHistoricalData: getHistoricalDataAction
})(HistoryModal);
