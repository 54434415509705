import axiosInstance from "../configs/axios";

const STORE_SUMMARY_API_ENDPOINT =
  "/api/manager/StoreForcastSummary";

export function StoreSummary(item) {
  this.itemId = item.itemId;
  this.productionUnit = item.productionUnit;
  this.forcast = item.forcast;
  this.cooked = item.cooked;
  this.wasted = item.wasted;
  this.perWasted = item.perWasted;
  this.sold = item.sold;
  this.underOverProduce = item.under_Over_Produce;
  this.perUnderOverProduce = item.perUnder_Over_Produce;
  this.perSold = item.perSold;
  this.soldFresh = item.soldFresh;
  this.perSoldFresh = item.perSoldFresh;
  this.perProduced = item.perProduced;
  this.itemName = item.itemName;
  this.managerForcast = item.managerForcast;
}

export const getManagerStoreSummaryAPI = (payload) => {
  const dateObj = new Date(payload.date);
  const dateStr = `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
  return axiosInstance
    .get(
      `${STORE_SUMMARY_API_ENDPOINT}?storeCode=${payload.store.value}&currenDate=${dateStr}`
    )
    .then(({ data }) => data.map((item) => new StoreSummary(item)));
};
