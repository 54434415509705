import { createSelector } from "reselect";

const updateBatchState = (state) => state.updateBatch;

/**
 * LOADER
 * Loading state of API: Delete batch
 */
export const isDeleteBatchInProgress = createSelector(
  updateBatchState,
  (UB) => UB.deleteingBatch
);
