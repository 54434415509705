import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";

import Button from "../Button";

import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";

import "./ConfirmComplete.scss";
import { confirmCompleteAction } from "../../redux/actions/updateBatch";
import { isConfirmCompleteInProgress } from "../../redux/selectors/confirmComplete";

const ConfirmComplete = ({
  toggleUpdateBatchModal,
  confirmCompleteAction,
  selectedBatchItem,
  isConfirmCompleteInProgress
}) => {
  const confirmComplete = () => {
    const request = { batchId: selectedBatchItem.batchNo };
    confirmCompleteAction(request);
  };

  return (
    <div className="confirm-complete h-100">
      <div className="confirm-complete__inner-section h-100">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <span className="confirm-complete__warning">
                Confirm no remaining items in this batch: this is usually when
                sales have not yet come through. It will hide the batch from the
                display
              </span>
            </div>
            <div className="col-md-6 p-0 confirm-complete__right-section">
              <div className="confirm-complete__right-section--first-child d-flex align-items-center h-100">
                <div className="d-flex justify-content-between w-100">
                  <Button
                    label="Confirm Complete"
                    color="green"
                    height="80px"
                    onClick={confirmComplete}
                    disabled={isConfirmCompleteInProgress}
                  />
                  <Button
                    label="Cancel"
                    color="red"
                    height="80px"
                    onClick={() =>
                      toggleUpdateBatchModal({ open: false, batchItem: {} })
                    }
                    disabled={isConfirmCompleteInProgress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmComplete.defaultProps = {
  toggleUpdateBatchModal: noop,
  confirmCompleteAction: noop,
  selectedBatchItem: {},
  isConfirmCompleteInProgress: false
};

ConfirmComplete.propTypes = {
  toggleUpdateBatchModal: PropTypes.func,
  confirmCompleteAction: PropTypes.func,
  selectedBatchItem: PropTypes.object,
  isConfirmCompleteInProgress: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isConfirmCompleteInProgress: isConfirmCompleteInProgress(state)
});

export default connect(mapStateToProps, {
  toggleUpdateBatchModal: toggleUpdateBatchModal,
  confirmCompleteAction: confirmCompleteAction
})(ConfirmComplete);
