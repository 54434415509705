import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Sidebar from "react-sidebar";

import Navbar from "../../components/Navbar";
import ForecastHeader from "../../components/ForecastHeader/ForecastHeader";
import PageLoader from "../../components/PageLoader/PageLoader";
import SidebarContent from "../../components/SidebarContent/SidebarContent";

import HistoryModal from "../../containers/HistoryModal";
import GraphTable from "../../containers/GraphTable";
import Graph from "../../containers/Graph";
import GraphTableView from "../../containers/GraphTableView";

import {
  reset15MinSalesAction,
  toggleHistoricalModalAction,
  updateSelectedForecastDateAction
} from "../../redux/actions/forecast";
import {
  getForecastDateSelector,
  getIsHistoricalModalOpen,
  isGraphDataLoadingSelector,
  isHistoricalDataLoadingSelector,
  isTableDataLoadingSelectr
} from "../../redux/selectors/forecast";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";

import "./ForeCastEvaluator.scss";

const ForeCastEvaluator = ({
  isHistoricalModalOpen,
  toggleHistoricalModal,
  isGraphDataLoading,
  isTableDataLoading,
  isHistoricalDataLoading,
  setSelectedDate,
  selectedDate,
  reset15MinSales,
  isSidebarOpen,
  toggleSidebasAction
}) => {
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedToggleMenu, setSelectedToggleMenu] = useState("graph");

  const qParam = useLocation().search;
  const qType = new URLSearchParams(qParam).get("type");

  useEffect(() => {
    setSelectedToggleMenu(qType);
  }, [qType]);

  const isPageLoading =
    isGraphDataLoading || isTableDataLoading || isHistoricalDataLoading;
  return (
    <div className="forecast-evaluator">
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <ForecastHeader
        toggleHistoricalModal={toggleHistoricalModal}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedToggleMenu={selectedToggleMenu}
        setSelectedToggleMenu={setSelectedToggleMenu}
        reset15MinSales={reset15MinSales}
      />
      {selectedToggleMenu === "graph" ? (
        <>
          <Graph selectedDate={selectedDate} />
          <GraphTable selectedDate={selectedDate} />{" "}
        </>
      ) : (
        <GraphTableView
          selectedDate={selectedDate}
          isGraphDataLoading={isGraphDataLoading}
        />
      )}

      {isHistoricalModalOpen && (
        <HistoryModal
          selectedDate={selectedDate}
          isHistoricalModalOpen={isHistoricalModalOpen}
          toggleHistoricalModal={toggleHistoricalModal}
        />
      )}
      {isPageLoading && <PageLoader />}
    </div>
  );
};

ForeCastEvaluator.defaultProps = {
  isHistoricalModalOpen: false,
  toggleHistoricalModal: noop,
  isGraphDataLoading: false,
  isTableDataLoading: false,
  isHistoricalDataLoading: false,
  setSelectedDate: noop,
  selectedDate: {},
  reset15MinSales: noop,
  isSidebarOpen: false,
  toggleSidebasAction: noop
};

ForeCastEvaluator.propTypes = {
  isHistoricalModalOpen: PropTypes.bool,
  toggleHistoricalModal: PropTypes.func,
  isGraphDataLoading: PropTypes.bool,
  isTableDataLoading: PropTypes.bool,
  isHistoricalDataLoading: PropTypes.bool,
  setSelectedDate: PropTypes.func,
  selectedDate: PropTypes.object,
  reset15MinSales: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  toggleSidebasAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  isHistoricalModalOpen: getIsHistoricalModalOpen(state),
  isGraphDataLoading: isGraphDataLoadingSelector(state),
  isTableDataLoading: isTableDataLoadingSelectr(state),
  isHistoricalDataLoading: isHistoricalDataLoadingSelector(state),
  selectedDate: getForecastDateSelector(state),
  isSidebarOpen: isSidebarOpenSelector(state)
});

export default connect(mapStateToProps, {
  toggleHistoricalModal: toggleHistoricalModalAction,
  setSelectedDate: updateSelectedForecastDateAction,
  reset15MinSales: reset15MinSalesAction,
  toggleSidebasAction: toggleSidebasAction
})(ForeCastEvaluator);
