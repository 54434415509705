import { createSelector } from "reselect";

const forecastAccuracyState = (state) => state.forecaseAccuracy;

/**
 * FORECAST ACCURACY DATA : GET
 */
export const forecastAccuracySelector = createSelector(
  forecastAccuracyState,
  (FA) => FA.forecastAccuracy
);

/**
 * IS FORECAST ACCURACY LOADING
 */
export const isForecastAccuracyLoadingSelector = createSelector(
  forecastAccuracyState,
  (FA) => FA.loadingForecastAccuracy
);
