import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DevyaniLogo from "../../assets/images/devyani_logo.png";

import { loginAction } from "../../redux/actions/auth";

import "./Login.scss";
import { noop } from "lodash";

const Login = ({ history, loginAction }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const isBtnDisabled = () => {
    if (!username || !password) {
      return true;
    }
    return false;
  };

  const loginHandler = () => {
    loginAction({ userName: username, password: password, history });
  };

  return (
    <div className="login">
      <div className="login__login-box shadow-sm rounded p-5">
        <div className="d-flex justify-content-center mb-4">
          <img src={DevyaniLogo} className="login__logo" />
        </div>
        <div className="form-group mb-3">
          <label>Username</label>
          <input
            className="form-control"
            placeholder="User Name"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </div>
        <div className="form-group mb-5">
          <label>Password</label>
          <input
            className="form-control"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className="d-flex justify-content-center mb-3">
          <button
            className="btn btn-light login__btn"
            disabled={isBtnDisabled()}
            onClick={loginHandler}
          >
            Login
          </button>
        </div>
        <div className="login__footer">
          {/* <span
            onClick={() => history.push("signup")}
            className="login__footer__link"
          >
            Create Account
          </span> */}
          {/* &nbsp; / &nbsp;
          <span
            onClick={() => history.push("forgot")}
            className="login__footer__link"
          >
            Forgot Password
          </span> */}
        </div>
      </div>
    </div>
  );
};

Login.defaultProps = {
  history: {},
  loginAction: noop
};

Login.propTypes = {
  history: PropTypes.object,
  loginAction: PropTypes.func
};

export default connect(null, { loginAction: loginAction })(Login);
