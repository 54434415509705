import React, { useState } from "react";
import PropTypes from "prop-types";

import PageLoader from "../../components/PageLoader/PageLoader";
import Navbar from "../../components/Navbar";
import Sidebar from "react-sidebar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { connect } from "react-redux";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";
import DashboardOverview from "../../containers/DashboardOverview";

import "./ManagerDashboard.scss";
// import BarChartForManagerDashboard from "../../containers/BarChartForManagerDashboard/BarChartForManagerDashboard";
import TableForManagerDashboard from "../../containers/TableForManagerDashboard/TableForManagerDashboard";
import StoreCurrentSalesTableForManagerDashboard from "../../containers/StoreCurrentSalesTableForManagerDashboard/StoreCurrentSalesTableForManagerDashboard";

const ManagerDashboard = ({ isSidebarOpen, toggleSidebasAction }) => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  return (
    <div className="manager-dashboard">
      {isPageLoading && <PageLoader />}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <main>
        <div className="mt-5 container">
          <DashboardOverview />
        </div>
        {/* <div className="manager-dashboard__barchart-container container mt-5">
            <BarChartForManagerDashboard />
        </div> */}

        <div className="container mt-5">
          <StoreCurrentSalesTableForManagerDashboard />
        </div>
        <div className="container mt-5">
          <TableForManagerDashboard />
        </div>
      </main>
    </div>
  );
};

ManagerDashboard.defaultProps = {
  isSidebarOpen: false,
  toggleSidebasAction: () => {}
};

ManagerDashboard.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleSidebasAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state)
});

const mapDispatchToProps = {
  toggleSidebasAction: toggleSidebasAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard);
