import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { getUserRole, parseJwt } from "../../utils/helpers";

const storeRoutes = [
  "/",
  "/forecast-evaluator",
  "/forecast-accuracy",
  "/store-summary",
  "/mdr-items"
];

const managerRoutes = [
  "/manager-dashboard",
  "/manager-forecast-evaluator",
  "/manager-forecast-accuracy",
  "/manager-store-summary",
  "/manager-current-sales"
];

function ProtectedRoute({ component: Component, ...restOfProps }) {
  let isAuthenticated = localStorage.getItem("user");

  const location = useLocation();

  // CHECK IF TOKEN EXPIRED
  if (isAuthenticated) {
    const parsedToken = parseJwt(isAuthenticated);

    if (parsedToken.exp * 1000 < new Date().getTime()) {
      localStorage.clear();
      isAuthenticated = null;
    }
  }

  const userRole = getUserRole();
  const path = location.pathname;

  if (userRole === "StoreUser") {
    if (!storeRoutes.includes(path)) {
      return (
        <Route
          {...restOfProps}
          render={() => <Redirect to="/access-denied" />}
        />
      );
    }
  }

  if (userRole === "Store") {
    if (!managerRoutes.includes(path)) {
      return (
        <Route
          {...restOfProps}
          render={() => <Redirect to="/access-denied" />}
        />
      );
    }
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

ProtectedRoute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any
};

export default ProtectedRoute;
