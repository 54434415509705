import {
  GET_MANAGER_STORE_SUMMARY_FAILED,
  GET_MANAGER_STORE_SUMMARY_REQUEST,
  GET_MANAGER_STORE_SUMMARY_SUCCESS
} from "../types/manager.storeSummary";

const initialState = {
  managerStoreSummary: [],
  loadingManagerStoreSummary: false,
  selectedStore: null
};

export const managerStoreSummaryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_MANAGER_STORE_SUMMARY_REQUEST:
      return {
        ...state,
        managerStoreSummary: [],
        loadingManagerStoreSummary: true
      };
    case GET_MANAGER_STORE_SUMMARY_SUCCESS:
      return {
        ...state,
        managerStoreSummary: payload,
        loadingManagerStoreSummary: false
      };
    case GET_MANAGER_STORE_SUMMARY_FAILED:
      return {
        ...state,
        managerStoreSummary: [],
        loadingManagerStoreSummary: false
      };
    default:
      return state;
  }
};
