import React from "react";
import PropTypes from "prop-types";

import { flatten } from "lodash";

import CollapsableRowForManagerForecast from "../CollapsableRowForManagerForecast/CollapsableRowForManagerForecast";

const CollapsableRowParentForManagerForecast = ({
  data
}) => {
  const flattenData = flatten(data);

  return (
    <>
      <tr
        style={{ paddingLeft: "16px", color: "#FFFFFF" }}
        className={`graph-table-view__${flattenData[0].dayPeriod}`}
      >
        <td>{flattenData[0].dayPeriod}</td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.lastYearSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          Rs.{" "}
          {flattenData.reduce((a, b) => {
            return a + b.forcastedSale;
          }, 0)}
        </td>
        <td style={{ textAlign: "right" }}>
          {`Rs. ${flattenData.reduce((a, b) => {
            return a + b.managerEdit;
          }, 0)}`}
        </td>
      </tr>
      {data.map((obj, index) => (
        <CollapsableRowForManagerForecast
          key={index}
          data={obj}
        />
      ))}
    </>
  );
};

CollapsableRowParentForManagerForecast.defaultProps = {
  data: []
};

CollapsableRowParentForManagerForecast.propTypes = {
  data: PropTypes.array
};

export default CollapsableRowParentForManagerForecast;
