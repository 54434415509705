export const OPEN_ADD_BATCH_MODAL = "OPEN_ADD_BATCH_MODAL";
export const OPEN_UPDATE_BATCH_MODAL = "OPEN_UPDATE_BATCH_MODAL";
export const GET_BATCH = "GET_BATCH";
export const GET_BATCH_REQUEST = "GET_BATCH_REQUEST";
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const GET_BATCH_FAILURE = "GET_BATCH_FAILURE";

export const UPDATE_NEW_BATCH_DATA = "UPDATE_NEW_BATCH_DATA";

export const SAVE_TREND_DATA = "SAVE_TREND_DATA";

export const REMOVE_BATCH_FROM_STORE = "REMOVE_BATCH_FROM_STORE";

export const GET_WASTE_REASON = "GET_WASTE_REASON";
export const GET_WASTE_REASON_REQUEST = "GET_WASTE_REASON_REQUEST";
export const GET_WASTE_REASON_SUCCESS = "GET_WASTE_REASON_SUCCESS";
export const GET_WASTE_REASON_FAILURE = "GET_WASTE_REASON_FAILURE";

export const UPDATE_BATCH = "UPDATE_BATCH";

export const UPDATE_ITEM_COLLAPSE_MENU = "UPDATE_ITEM_COLLAPSE_MENU";

export const SAVE_TREND_TIME = "SAVE_TREND_TIME";

export const GET_ACTIVE_ITEMS_REQUEST = "GET_ACTIVE_ITEMS_REQUEST";
export const GET_ACTIVE_ITEMS_SUCCESS = "GET_ACTIVE_ITEMS_SUCCESS";
export const GET_ACTIVE_ITEMS_FAILURE = "GET_ACTIVE_ITEMS_FAILURE";

export const UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS =
  "UPDATE_TO_DROP_DATA_IN_ACTIVE_ITEMS";

export const SAVE_SIGNALR_CONNECTION = "SAVE_SIGNALR_CONNECTION";
export const IS_SIGNALR_CONNECTED = "IS_SIGNALR_CONNECTED";

export const CONNECT_SIGNALR = "CONNECT_SIGNALR";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const UPDATE_QTY_IN_BATCH = "UPDATE_QTY_IN_BATCH";

export const GET_SALES_TREND_REQUEST = "GET_SALES_TREND_REQUEST";
