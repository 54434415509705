import {
  GET_FORECAST_ACCURACY_FAILED,
  GET_FORECAST_ACCURACY_REQUEST,
  GET_FORECAST_ACCURACY_SUCCESS
} from "../types/forecastAccuracy";

const initialState = {
  forecastAccuracy: [],
  loadingForecastAccuracy: false
};

export const forecastAccuracyReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case GET_FORECAST_ACCURACY_REQUEST:
      return {
        ...state,
        forecastAccuracy: [],
        loadingForecastAccuracy: true
      };
    case GET_FORECAST_ACCURACY_SUCCESS:
      return {
        ...state,
        forecastAccuracy: payload,
        loadingForecastAccuracy: false
      };
    case GET_FORECAST_ACCURACY_FAILED:
      return {
        ...state,
        forecastAccuracy: [],
        loadingForecastAccuracy: false
      };
    default:
      return state;
  }
};
