import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";

import Button from "../Button";

import { updateConfirmTimeCookedAction } from "../../redux/actions/updateBatch";
import { toggleUpdateBatchModal } from "../../redux/actions/dahsboard";
import { isUpdateConfirmedCookedInProgress } from "../../redux/selectors/confirmedCooked";

import { UPDATE_BATCH_MODALS } from "../../configs/constants/constants";
import { BUTTON_LABELS } from "../../configs/constants/labels";
import { getNumberInTwoDigit, validateTime } from "../../utils/helpers";

import "./ConfirmedCooked.scss";

const ConfirmedCooked = ({
  updateConfirmTimeCookedAction,
  selectedBatchItem,
  isUpdateConfirmedCookedInProgress,
  toggleUpdateBatchModal
}) => {
  const [minute, setMinute] = useState("00");
  const [hour, setHour] = useState("00");
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const currentDateTime = new Date();
    setMinute(currentDateTime.getMinutes());
    setHour(currentDateTime.getHours());
  }, []);

  const changeHour = (event) => {
    let { value } = event.target;
    if (value === "") {
      setHour("");
    } else {
      setHour(validateTime(value, 24));
    }
  };

  const changeMinute = (event) => {
    let { value } = event.target;
    if (value === "") {
      setMinute("");
    } else {
      setMinute(validateTime(value, 60));
    }
  };

  const saveConfimedCooked = () => {
    const confirmedTime = new Date();
    confirmedTime.setHours(hour);
    confirmedTime.setMinutes(minute);
    confirmedTime.setSeconds(0);

    // Time Validation
    setErrorMessage("");
    const cookedTimeObject = new Date(selectedBatchItem.cookedTime).setSeconds(
      0
    );

    if (
      confirmedTime.getTime() < new Date(selectedBatchItem.startTime).getTime()
    ) {
      // check if this time less than start time
      setErrorMessage("Cooked time must be after start time");
      return;
    } else if (
      // check if this time should not exceed cooked time
      confirmedTime.getTime() > new Date(cookedTimeObject).getTime()
    ) {
      setErrorMessage("Cooked time must be on or before now");
      return;
    }

    const year = confirmedTime.getFullYear();
    const month = getNumberInTwoDigit(confirmedTime.getMonth() + 1);
    const date = getNumberInTwoDigit(confirmedTime.getDate());
    const hours = getNumberInTwoDigit(confirmedTime.getHours());
    const minutes = getNumberInTwoDigit(confirmedTime.getMinutes());
    const seconds = getNumberInTwoDigit(confirmedTime.getSeconds());

    updateConfirmTimeCookedAction({
      batchId: selectedBatchItem.batchNo,
      data: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
    });
  };

  const { TITLE, CONFIRM_TIME_COMPLETED_HEADER } =
    UPDATE_BATCH_MODALS.CONFIRM_COOKED;

  const { CONFIRM_COOKED, CANCEL } = BUTTON_LABELS;

  return (
    <div className="confirmed-cooked">
      <div className="confirmed-cooked__inner-section">
        <h6>
          <input
            id="isChecked"
            type="checkbox"
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          />{" "}
          <label className="pointer" htmlFor="isChecked">
            {TITLE}
          </label>
        </h6>
        <div className="d-flex justify-content-between mt-4">
          <div className="confirmed-cooked__time-section">
            <header>{CONFIRM_TIME_COMPLETED_HEADER}</header>
            <div className="d-flex justify-content-center mt-2 mb-2">
              <input
                className="time-input mr-1"
                type="number"
                value={hour}
                onChange={changeHour}
                onFocus={() => setHour("")}
              />
              <span className="confirmed-cooked__divider">:</span>
              <input
                className="time-input ml-1"
                type="number"
                value={minute}
                onChange={changeMinute}
                onFocus={() => setMinute("")}
              />
            </div>
          </div>
          <Button
            label={CONFIRM_COOKED}
            color="green"
            height="80px"
            onClick={saveConfimedCooked}
            disabled={!isChecked || isUpdateConfirmedCookedInProgress}
            isLoading={isUpdateConfirmedCookedInProgress}
          />
          <Button
            label={CANCEL}
            color="red"
            height="80px"
            disabled={isUpdateConfirmedCookedInProgress}
            onClick={() =>
              toggleUpdateBatchModal({ open: false, batchItem: {} })
            }
          />
        </div>
        <div className="confirmed-cooked__error-container">{errorMessage}</div>
      </div>
    </div>
  );
};

ConfirmedCooked.defaultProps = {
  updateConfirmTimeCookedAction: noop,
  selectedBatchItem: {},
  isUpdateConfirmedCookedInProgress: false,
  batchItemtoggleUpdateBatchModal: noop
};

ConfirmedCooked.propTypes = {
  updateConfirmTimeCookedAction: PropTypes.func,
  selectedBatchItem: PropTypes.object,
  isUpdateConfirmedCookedInProgress: PropTypes.bool,
  toggleUpdateBatchModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  isUpdateConfirmedCookedInProgress: isUpdateConfirmedCookedInProgress(state)
});

export default connect(mapStateToProps, {
  updateConfirmTimeCookedAction: updateConfirmTimeCookedAction,
  toggleUpdateBatchModal: toggleUpdateBatchModal
})(ConfirmedCooked);
