import {
  GET_MRD_ITEMS_REQUEST,
  SEND_MRD_ITEM_PRINT_REQUEST
} from "../types/mrdItems";

export const getMrdItemsAction = (payload) => {
  return {
    type: GET_MRD_ITEMS_REQUEST,
    payload
  };
};

export const sendPrintMrdItemRequestAction = (payload) => ({
  type: SEND_MRD_ITEM_PRINT_REQUEST,
  payload
});
