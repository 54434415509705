import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import { getNumberInTwoDigit } from "../../utils/helpers";

import "./PercentTableRowInput.scss";

const PercentTableRowInput = ({
  disabled,
  defaultValue,
  createDate,
  salesType,
  updateCurrentSale,
  origin,
  update15MinSale,
  type,
  parentLabel,
  updateDayPeriodSales,
  updateHourlySales
}) => {
  const [value, setValue] = useState(defaultValue);
  const [updatedValue, setUpdatedValue] = useState(defaultValue);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (value && value !== defaultValue) {
      const percentageValue = (Number(defaultValue) * value) / 100;
      setUpdatedValue(Number(defaultValue) + percentageValue);
    }
  }, [value]);

  useEffect(() => {
    setUpdatedValue(defaultValue);
  }, [defaultValue]);

  const onChangeHandler = (value) => {
    if (!isNaN(value) && Number(value) > 0) {
      setValue(value);
    } else {
      setValue("");
    }
  };

  const updateSale = () => {
    if (value) {
      const createDateObj = new Date(createDate);
      const currentTime = new Date();
      const year = createDateObj.getFullYear();
      const month = getNumberInTwoDigit(createDateObj.getMonth() + 1);
      const date = getNumberInTwoDigit(createDateObj.getDate());
      const hours = getNumberInTwoDigit(currentTime.getHours());
      const minutes = getNumberInTwoDigit(currentTime.getMinutes());
      const seconds = getNumberInTwoDigit(currentTime.getSeconds());
      if (origin === "graph") {
        const request = {
          createDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`,
          salesType,
          amount: updatedValue ? Number(updatedValue) : 0,
          previousAmount: defaultValue ? Number(defaultValue) : 0
        };
        updateCurrentSale(request);
      } else {
        if (type === "parent") {
          const request = {
            changedAmount: updatedValue ? Number(updatedValue) : 0,
            dayPeriod: parentLabel === "Day Totals" ? "Total" : parentLabel,
            previousAmount: defaultValue ? Number(defaultValue) : 0
          };
          updateDayPeriodSales(request);
        } else if (type === "head") {
          const request = {
            changedAmount: updatedValue ? Number(updatedValue) : 0,
            previousAmount: defaultValue ? Number(defaultValue) : 0,
            time: createDate //`${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`
          };
          updateHourlySales(request);
        } else {
          const request = {
            salesDate: `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`,
            amount: updatedValue ? Number(updatedValue) : 0
          };
          update15MinSale(request);
        }
      }
    } else {
      setValue(defaultValue);
    }
  };

  return (
    <div>
      <input
        disabled={disabled}
        value={isFocus ? value : `Rs. ${Math.round(updatedValue)}`}
        onChange={(event) => onChangeHandler(event.target.value)}
        onFocus={() => {
          setIsFocus(true), setValue("");
        }}
        onBlur={() => {
          setIsFocus(false);
          updateSale();
        }}
      />{" "}
      {isFocus && (
        <span style={{ fontSize: "1em" }}>Rs. {updatedValue.toFixed(2)}</span>
      )}
    </div>
  );
};

PercentTableRowInput.defaultProps = {
  disabled: false,
  defaultValue: 0,
  createDate: {},
  salesType: "",
  updateCurrentSale: noop,
  origin: "graph",
  update15MinSale: noop,
  type: "",
  parentLabel: "",
  updateDayPeriodSales: noop,
  updateHourlySales: noop
};

PercentTableRowInput.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  createDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  salesType: PropTypes.string,
  updateCurrentSale: PropTypes.func,
  origin: PropTypes.string,
  update15MinSale: PropTypes.func,
  type: PropTypes.string,
  parentLabel: PropTypes.string,
  updateDayPeriodSales: PropTypes.func,
  updateHourlySales: PropTypes.func
};

export default PercentTableRowInput;
