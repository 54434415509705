import React from "react";
import DatePicker from "react-datepicker";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import PropTypes from "prop-types";
import "./CustomDatePicker.scss";

const CustomDatePicker = ({ onChange, value }) => {
  const goToPrevDate = () => {
    onChange(new Date(value.setDate(value.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = value.getDate();
    const D2 = new Date().getDate();
    if (D1 === D2) {
      return;
    }
    onChange(new Date(value.setDate(value.getDate() + 1)));
  };

  return (
    <div className="date-picker-wrapper__date-picker-wrapper">
      <span
        className="date-picker-wrapper__date-picker-arrows"
        onClick={goToPrevDate}
      >
        <BsCaretLeftFill />
      </span>
      <DatePicker
        className="form-control date-picker-wrapper__date-picker"
        selected={value}
        onChange={(update) => {
          onChange(update);
        }}
        onChangeRaw={(e) => e.preventDefault()}
        maxDate={new Date()}
      />
      <span
        className="date-picker-wrapper__date-picker-arrows"
        onClick={goToNextDate}
      >
        <BsCaretRightFill />
      </span>
    </div>
  );
};

export default CustomDatePicker;

CustomDatePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};
