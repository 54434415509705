import axiosInstance from "../configs/axios";

const MANAGER_FORECAST_ACCURACY_API_ENDPOINT = "/api/manager/StoreAccuracy";

export function ForecastAccuracy(item) {
  this.salesType = item.salesType;
  this.actualSales = item.actualSales;
  this.managerAccuracy = item.managerAccuracy;
  this.managerForcast = item.managerForcast;
  this.managerVariance = item.managerVariance;
  this.systemAccuracy = item.systemAccuracy;
  this.systemForcast = item.systemForcast;
  this.systemVariance = item.systemVariance;
}

export const getManagerForecastAccuracyAPI = (payload) => {
  const dateObj = new Date(payload.date);
  const dateStr = `${dateObj.getFullYear()}-${
    dateObj.getMonth() + 1
  }-${dateObj.getDate()}`;
  return axiosInstance
    .get(
      `${MANAGER_FORECAST_ACCURACY_API_ENDPOINT}?storeCode=${payload.store.value}&currenDate=${dateStr}`
    )
    .then(({ data }) => data.map((item) => new ForecastAccuracy(item)));
};
