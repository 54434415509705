import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { connect } from "react-redux";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import {
  getGraphDataAction,
  getSalesTableDataAction
} from "../../redux/actions/forecast";
import { getGraphDataSelector } from "../../redux/selectors/forecast";

import "./Graph.scss";

const Graph = ({
  graphData,
  getGraphData,
  getSalesTableData,
  selectedDate
}) => {
  useEffect(() => {
    getGraphData(selectedDate);
    getSalesTableData();
  }, [selectedDate]);

  return (
    <div className="d-flex justify-content-center mt-3">
      <LineChart
        width={(screen.width * 92) / 100}
        height={400}
        data={graphData}
        padding={{ left: 10, right: 10 }}
        margin={{
          left: -20,
          right: 10,
          bottom: 10
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="salesTime" />
        <YAxis />
        <Tooltip />
        <Legend wrapperStyle={{ top: -25, left: 25 }} />
        <Line
          type="monotone"
          dataKey="lastYearSale"
          name="Last Week Sale"
          stroke="#61813B"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="forcastedSale"
          name="Forecasted Sale"
          stroke="#9E2027"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="managerEdit"
          name="Manager Edit"
          stroke="#000000"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
};

Graph.defaultProps = {
  getGraphData: noop,
  graphData: [],
  getSalesTableData: noop,
  selectedDate: new Date()
};

Graph.propTypes = {
  getGraphData: PropTypes.func,
  graphData: PropTypes.array,
  getSalesTableData: PropTypes.func,
  selectedDate: PropTypes.object
};

const mapStateToProps = (state) => ({
  graphData: getGraphDataSelector(state)
});

export default connect(mapStateToProps, {
  getGraphData: getGraphDataAction,
  getSalesTableData: getSalesTableDataAction
})(Graph);
