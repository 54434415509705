import React, { useState, useEffect } from "react";
import Sidebar from "react-sidebar";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import { noop } from "lodash";

import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import Navbar from "../../components/Navbar";
import SidebarContent from "../../components/SidebarContent/SidebarContent";
import { isSidebarOpenSelector } from "../../redux/selectors/dashboard";
import { toggleSidebasAction } from "../../redux/actions/dahsboard";
import { getForecastAccuracyAction } from "../../redux/actions/forecastAccuracy";
import {
  forecastAccuracySelector,
  isForecastAccuracyLoadingSelector
} from "../../redux/selectors/forecastAccuracy";
import PageLoader from "../../components/PageLoader/PageLoader";

import "./ForecastAccuracy.scss";

const ForecastAccuracy = ({
  isSidebarOpen,
  toggleSidebasAction,
  getForecastAccuracy,
  forecastAccuracy,
  isForecastAccuracyLoading
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    getForecastAccuracy(selectedDate);
  }, [selectedDate]);

  Date.prototype.addDays = function add(days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const goToPrevDate = () => {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  };

  const goToNextDate = () => {
    const D1 = selectedDate.getDate();
    const D2 = new Date().addDays(10).getDate();
    if (D1 === D2) {
      return;
    }
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  };

  return (
    <div>
      {isForecastAccuracyLoading && <PageLoader />}
      {isSidebarOpen && (
        <Sidebar
          sidebar={<SidebarContent toggleSidebasAction={toggleSidebasAction} />}
          open={isSidebarOpen}
          pullRight
          styles={{ sidebar: { background: "white", width: "20%" } }}
          shadow={false}
        />
      )}
      <Navbar toggleSidebasAction={toggleSidebasAction} />
      <div className="d-flex align-items-center mt-3 forecast-accuracy__date-picker">
        <span
          className="forecast-accuracy__date-picker-arrows"
          onClick={goToPrevDate}
        >
          <BsCaretLeftFill />
        </span>
        <DatePicker
          className="form-control forecast-header__date-picker"
          selected={selectedDate}
          onChange={(update) => {
            setSelectedDate(update);
          }}
          onChangeRaw={(e) => e.preventDefault()}
          maxDate={new Date().addDays(10)}
        />
        <span
          className="forecast-accuracy__date-picker-arrows"
          onClick={goToNextDate}
        >
          <BsCaretRightFill />
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <table
          className="table table-striped table-bordered forecast-accuracy__table-container"
          style={{ fontSize: "14px" }}
        >
          <thead>
            <tr>
              <th>Sales Type</th>

              <th>System Forcast</th>
              <th>Manager Forcast</th>
              <th>Actual Sales</th>
              <th>System Variance</th>
              <th>Manager Variance</th>
              <th>System Accuracy %</th>
              <th>Manager Accuracy %</th>
            </tr>
          </thead>
          <tbody>
            {forecastAccuracy.length ? (
              forecastAccuracy.map((item, index) => (
                <tr key={index}>
                  <td>{item.salesType}</td>

                  <td style={{ textAlign: "right"}}>{item.systemForcast}</td>
                  <td style={{ textAlign: "right"}}>{item.managerForcast}</td>
                  <td style={{ textAlign: "right"}}>{item.actualSales}</td>
                  <td style={{ textAlign: "right"}}>{item.systemVariance}</td>
                  <td style={{ textAlign: "right"}}>{item.managerVariance}</td>
                  <td style={{ textAlign: "right"}}>{item.systemAccuracy} %</td>
                  <td style={{ textAlign: "right"}}>{item.managerAccuracy} %</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} align="center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

ForecastAccuracy.defaultProps = {
  toggleSidebasAction: noop,
  isSidebarOpen: false,
  getForecastAccuracy: noop,
  forecastAccuracy: [],
  isForecastAccuracyLoading: false
};

ForecastAccuracy.propTypes = {
  toggleSidebasAction: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  getForecastAccuracy: PropTypes.func,
  forecastAccuracy: PropTypes.array,
  isForecastAccuracyLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isSidebarOpen: isSidebarOpenSelector(state),
  isForecastAccuracyLoading: isForecastAccuracyLoadingSelector(state),
  forecastAccuracy: forecastAccuracySelector(state)
});

const mapDispatchToProps = {
  toggleSidebasAction: toggleSidebasAction,
  getForecastAccuracy: getForecastAccuracyAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastAccuracy);
