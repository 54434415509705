import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";

import "./PercentToggle.scss";

const PercentToggle = ({ selectedMode, setSelectedMode }) => {
  return (
    <div className="percent-toggle__percent-toggle">
      <span
        className={`${
          selectedMode === "rs" ? "percent-toggle__percent-toggle--active" : ""
        }`}
        onClick={() => setSelectedMode("rs")}
      >
        Rs
      </span>
      <span
        className={`${
          selectedMode === "%" ? "percent-toggle__percent-toggle--active" : ""
        }`}
        onClick={() => setSelectedMode("%")}
      >
        %
      </span>
    </div>
  );
};

PercentToggle.defaultProps = {
  selectedMode: "rs",
  setSelectedMode: noop
};

PercentToggle.propTypes = {
  selectedMode: PropTypes.string,
  setSelectedMode: PropTypes.func
};

export default PercentToggle;
