import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import { noop } from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import TileCount from "../../components/TileCount";

import { removeBatchFromStoreAction } from "../../redux/actions/dahsboard";

import { addSeconds, parseJwt } from "../../utils/helpers";

import "./BatchTile.scss";
import { sendPrintBatchRequestAction } from "../../redux/actions/manager.dashboard";

const BatchTile = ({
  batchItem,
  toggleUpdateBatchModal,
  connection,
  removeBatchFromStoreAction,
  sendPrintBatchRequestAction
}) => {
  const [tileColor, setTileColor] = useState("blue");
  const [countdownTimeReference, setCountdownTimeReference] = useState(
    batchItem.cookedTime
  );
  const [mounted, setMounted] = useState(false);
  const [isPrintAllowed, setIsPrintAllowed] = useState(false);

  const counterRef = useRef();

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("user"));
    if (user?.PrintAllow?.toLowerCase() === "true") {
      setIsPrintAllowed(true);
    }
  }, []);

  useEffect(() => {
    updateTileColor();

    const timer = setInterval(() => {
      checkIfTileExpired();
    }, 30000);

    return () => {
      clearInterval(timer);
    };
  }, [batchItem]);

  useEffect(() => {
    if (counterRef.current) {
      counterRef.current.api.start();
    }
  }, [countdownTimeReference]);

  const checkIfTileExpired = () => {
    const currentTime = new Date();
    const { holdEndTime } = batchItem;
    if (
      currentTime.getTime() > addSeconds(new Date(holdEndTime), 30).getTime() // Remove card after 30 seconds of hold End Toime
    ) {
      removeBatchFromStoreAction({
        batchNo: batchItem.batchNo,
        itemId: batchItem.itemId
      });
      localStorage.removeItem(batchItem.batchNo);
    }
  };

  const invokeSignalR = (batchNo, statusId) => {
    try {
      const object = {
        BatchId: batchNo,
        StatusId: statusId
      };
      if (connection?.connectionState === "Connected") {
        connection.invoke("UpdateBatchStatus", object); // TODO: CHECK WITH VIVEK AND ENABLE AGAIN
      }
    } catch (e) {
      toast.error("Signal R invoke problem at UpdateBatchStatus");
    }
  };

  const updateTileColor = () => {
    const updateColor = () => {
      const { batchNo, startTime, cookedTime, holdEndTime, expiredTime } =
        batchItem;
      const currentDate = new Date().getTime();
      const batchStartTime = new Date(startTime).getTime();
      const batchCookedTime = new Date(cookedTime).getTime();
      const batchHoldEndTime =
        new Date(holdEndTime).getTime() -
        (new Date(expiredTime).getTime() - new Date(holdEndTime).getTime());
      const batchExpiredTime = new Date(holdEndTime).getTime();

      if (currentDate >= batchStartTime && currentDate <= batchCookedTime) {
        setCountdownTimeReference(batchItem.cookedTime);
      } else if (
        currentDate > batchCookedTime &&
        currentDate <= batchHoldEndTime
      ) {
        invokeSignalR(batchNo, 2);
        if (!localStorage.getItem(batchNo) && isPrintAllowed) {
          sendPrintBatchRequestAction({ batchId: batchNo, autoPrint: true });
          localStorage.setItem(batchNo, "printed");
        }
        // localStorage.removeItem(batchNo)
        setTileColor("orange");
        setCountdownTimeReference(batchItem.holdEndTime);
      } else if (
        currentDate > batchHoldEndTime &&
        currentDate <= batchExpiredTime
      ) {
        invokeSignalR(batchNo, 4);

        setTileColor("red");
        setCountdownTimeReference(batchItem.holdEndTime);
      } else if (currentDate > batchExpiredTime) {
        invokeSignalR(batchNo, 3);
        setTileColor("darkred");
      }
    };

    if (mounted) {
      setTimeout(() => {
        updateColor();
      }, 500);
    } else {
      updateColor();
      setMounted(true);
    }
  };

  // Custom time reender component
  const timeRenderer = ({ hours, minutes, seconds }) => {
    const { expiredTime } = batchItem;
    const currentDate = new Date().getTime();
    const batchExpiredTime = new Date(expiredTime).getTime();

    if (currentDate > batchExpiredTime) {
      return <span>&nbsp;</span>;
    }
    return (
      <span>
        {("0" + hours).slice(-2)}:{("0" + minutes).slice(-2)}:
        {("0" + seconds).slice(-2)}
      </span>
    );
  };

  return (
    <div
      className={`batch-tile batch-tile--${tileColor} pointer ${
        batchItem.completed ? "batch-tile--disabled" : ""
      }`}
      onClick={() => {
        if (!batchItem.completed) {
          toggleUpdateBatchModal({
            open: true,
            batchItem: { ...batchItem, tileColor }
          });
        }
      }}
    >
      <header className="d-flex justify-content-center">
        Batch No: {batchItem.batchNo}
      </header>
      <section className="d-flex align-items-center justify-content-center">
        <TileCount count={batchItem.batchQty} size="large" />
      </section>
      <footer className="d-flex justify-content-center">
        {!(new Date(batchItem.holdEndTime).getTime() < new Date().getTime()) ? (
          <Countdown
            ref={counterRef}
            date={new Date(countdownTimeReference)}
            renderer={timeRenderer}
            onComplete={updateTileColor}
            onTick={() => {
              const expiredTime = batchItem.expiredTime;
              const holdEndTime = batchItem.holdEndTime;
              const newHoldEndTime =
                new Date(holdEndTime).getTime() -
                (new Date(expiredTime).getTime() -
                  new Date(holdEndTime).getTime());
              const nowTime = new Date().getTime();
              if (
                nowTime > newHoldEndTime &&
                nowTime - newHoldEndTime <= 2000
              ) {
                setTileColor("red");
              }
            }}
          />
        ) : (
          <span>&nbsp;</span>
        )}
      </footer>
    </div>
  );
};

BatchTile.defaultProps = {
  batchNo: 0,
  toggleUpdateBatchModal: noop,
  batchItem: {},
  connection: {},
  removeBatchFromStoreAction: noop,
  sendPrintBatchRequestAction: noop
};

BatchTile.propTypes = {
  batchNo: PropTypes.number,
  toggleUpdateBatchModal: PropTypes.func,
  batchItem: PropTypes.object,
  connection: PropTypes.object,
  removeBatchFromStoreAction: PropTypes.func,
  sendPrintBatchRequestAction: PropTypes.func
};

const mapDispatchToProps = {
  removeBatchFromStoreAction: removeBatchFromStoreAction,
  sendPrintBatchRequestAction: sendPrintBatchRequestAction
};

export default connect(null, mapDispatchToProps)(BatchTile);
