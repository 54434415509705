import { createSelector } from "reselect";

const updateBatchState = (state) => state.updateBatch;

/**
 * LOADER
 * Loading state of API: Update Cooked Time
 */
export const isUpdateConfirmedCookedInProgress = createSelector(
  updateBatchState,
  (UB) => UB.updatingCookedTime
);
