import {
  GET_MANAGER_FORECAST_REQUEST,
  UPDATE_MANAGER_FORECAST_DATE,
  UPDATE_MANAGER_FORECAST_SELECTED_STORE
} from "../types/manager.forecast";

export const getManagerForecastAction = (payload) => {
  return {
    type: GET_MANAGER_FORECAST_REQUEST,
    payload
  };
};

export const updateManagerForecastDateAction = (payload) => ({
  type: UPDATE_MANAGER_FORECAST_DATE,
  payload
});

export const updateManagerForecastStoreAction = (payload) => ({
  type: UPDATE_MANAGER_FORECAST_SELECTED_STORE,
  payload
});
