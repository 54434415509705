import { createSelector } from "reselect";

const updateBatchState = (state) => state.updateBatch;

/**
 * LOADER
 * Loading state of API: Update expiry time
 */
export const isUpdateExpiryTimeInProgress = createSelector(
  updateBatchState,
  (UB) => UB.updatingExpiryTime
);
