import axios from "axios";
import { getAccessTokenFromLocalStorage } from "../../utils/helpers";

import { API_BASE_URL } from "../constants/constants";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

const requestInterceptor = (config) => {
  if (localStorage.getItem("user")) {
    const accessToken = getAccessTokenFromLocalStorage();
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
};

axiosInstance.interceptors.request.use(requestInterceptor);

export default axiosInstance;
